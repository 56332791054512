import React, { Component } from "react";
import * as classnames from "classnames";
import moment from "moment";
import { imageUrlFor, renderBlockText } from "../../../../util/util";
import styles from "./BlogCardItem.module.scss";
import withRouter from "../../../../util/hooks/withRouter";

class BlogCardItem extends Component {
    onPress = () => {
        let link = "";

        if (!!this.props.blogPost.slug) {
            link = this.props.blogPost.slug.current.includes("/") ? this.props.blogPost.slug.current : `/${this.props.blogPost.slug.current}`;
        }
        this.props.navigate(`/blog/content${link}`);
    };

    render() {
        if (!this.props.blogPost) {
            return null;
        }

        const blogPost = this.props.blogPost;

        return (
            <div className={classnames(styles.blog_card_container, this.props.className)} onClick={this.onPress}>
                <div className={styles.blog_post_image_container}
                     style={{ backgroundImage: !!blogPost.imageLinkAlt && `url(${imageUrlFor(blogPost.imageLinkAlt.image).url()})` }} />
                <div className={styles.blog_card_content}>
                    {!!blogPost.meta && (
                        <h6 className={styles.small_meta}>
                            {`On ${moment(blogPost.date).format("MMM DD YYYY")} / by ${blogPost.meta}`}
                        </h6>
                    )}
                    {!!blogPost.heading && <h4>{blogPost.heading}</h4>}
                    <div> {!!blogPost.blurb && renderBlockText(blogPost.blurb)}</div>
                </div>
            </div>
        );
    }
}

export default withRouter(BlogCardItem);
