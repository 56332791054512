import React, { Fragment } from "react";
import PropTypes from "prop-types";

const FormikCheckbox = (props) => {
    const {
        field: { name, value, onChange, onBlur },
        // form: { errors, touched, setFieldValue },
        id,
        label,
        className
    } = props;

    return (
        <Fragment>
            <input
                name={name}
                id={id}
                type="checkbox"
                value={value}
                checked={value}
                onChange={onChange}
                onBlur={onBlur}
                className={className}
            />
            {label && <label htmlFor={id}>{label}</label>}
        </Fragment>
    );
};

FormikCheckbox.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string
};

FormikCheckbox.defaultProps = {
    id: "",
    className: "",
    label: ""
};

export default FormikCheckbox;
