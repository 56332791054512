import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactLoading from "react-loading";
import classNames from "classnames";
import styles from "./ComponentLoader.module.scss";

export default class ComponentLoader extends Component {
    static propTypes = {
        className: PropTypes.string,
        type: PropTypes.string,
        color: PropTypes.string,
        height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    };

    static defaultProps = {
        className: styles.loader_component,
        type: "balls",
        color: "#5D7683",
    };

    render() {
        let props = { ...this.props };

        delete props.className;

        return (
            <div className={classNames(styles.loader, this.props.className)}>
                <ReactLoading {...props} />
            </div>
        );
    }
}
