import instance from "./instance";

export default function post(url, data = {}, params = {}, captcha = "") {
    return instance()
        .post(url, data, {
            params: params,
            headers: {
                "f": captcha
            }
        })
        .then(data => data.data);
}
