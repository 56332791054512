import React, { Component } from "react";
import styles from "./AssetItem.module.scss";
import PropTypes from "prop-types";

class AssetItem extends Component {
    static propTypes = {
        className: PropTypes.string,
        heading: PropTypes.string,
        content: PropTypes.object,
        image: PropTypes.any,
    };
    static defaultProps = {
        className: null,
        heading: null,
        content: null,
        image: null,
    };

    render() {
        return (
            <div className={styles.quad_block_content}>
                <img src={this.props.image} alt={""} />
                <p><span>{this.props.heading}</span></p>
                <a href={this.props.fileUpload} target={"_blank"} rel={"noopener noreferrer"}>{this.props.link}</a>
            </div>
        );
    }
}

export default AssetItem;
