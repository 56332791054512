import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet/es/Helmet";
import styles from "./Home.module.scss";
import IntroBlock from "../../common/blocks/introBlock/IntroBlock";
import FeaturedCharitiesBlock from "../../common/blocks/featuredCharitiesBlock/FeaturedCharitiesBlock";
import PreFooter from "../../common/blocks/preFooter/PreFooter";
import { pageReceived } from "../../../redux/actions/pages";
import { getCmsButtons, imageUrlFor, renderBlockText } from "../../../util/util";
import FullPageLoader from "../../common/widgets/loaders/fullPageLoader/FullPageLoader";
import ButtonBlock from "../../common/blocks/buttonBlock/ButtonBlock";
import { SeoPages } from "../../../util/seoConstants";
import TestimonialsBlock from "../../common/blocks/testimonialsBlock/TestimonialsBlock";
import withRouter from "../../../util/hooks/withRouter";
import { VideoBlock } from "../../common/blocks/videoBlock/VideoBlock";
import { OurStoryBlock } from "../../common/blocks/ourStoryBlock/OurStoryBlock";
import { CharityImpactBlock } from "../../common/blocks/chairtyImpactBlock/CharityImpactBlock";
import FaqBlock from "../../common/blocks/faqBlock/FaqBlock";
import { PressLogoBlock } from "../../common/blocks/pressLogoBlock/PressLogoBlock";
import scrollingOneTop from "../../../resources/images/png/scrolling-one-top.png";
import scrollingOneBottom from "../../../resources/images/png/scrolling-one-bottom.png";
import scrollingTwoTop from "../../../resources/images/png/scrolling-two-top.png";
import scrollingTwoBottom from "../../../resources/images/png/scrolling-two-bototm.png"
import { ScrollingImages } from "../../common/widgets/scrollingImages/ScrollingImages";
import HomeFormBlock from "../../common/blocks/homeFormBlock/HomeFormBlock";
import AliceCarousel from "react-alice-carousel";
import { CardBlock } from "../../common/blocks/cardBlock/CardBlock";

const query = `*[_type == "home"]{
        _id,
        title,
        homeHero,
        cardsBlock,
        video,
        coreProposition,
        intro,
        ourStory,
        press,
        testimonial{
            ...,
            "testimonials": testimonials[]->
        },
        charityImpacts{
            ...,
            "impacts": impacts[]->
        },

        featuredBlog{
            ...,
            "blogPost": blogPost[]->
        },
        benefits{
            ...,
            heading,
            "benefits": benefits[]->
        },
         
        charities[]->,
         "faqBlock":faqBlock{...,faqs[]->},
        differences{
            ...,
            heading,
            "differences": differences[]->
        },
        preFooter,
        banner,
        }[0]`;

const Home = (props) => {
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {

        if (!props.page) {
            window.sanity.fetch(query).then((page) => {
                props.dispatch(pageReceived({ "home": page }));
                setLoading(false);
            });
        }

    });

    if (loading || !props.page) {
        return <FullPageLoader />;
    }
    const {
        page: {
            // benefits,
            charities,
            banner,
            cardsBlock
        }
    } = props;

    const ldJson = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Share My Super",
        "legalName": "Share My Super Charitable Trust",
        "url": "https://sharemysuper.org.nz/",
        "logo": "https://sharemysuper.org.nz/static/media/spendMySuperLogo.95e84358.svg",
        "foundingDate": "2019",
        "founders": [
            {
                "@type": "Person",
                "name": "Liz Greive"
            } ],
        "contactPoint": {
            "@type": "ContactPoint",
            "contactType": "general enquiries",
            "email": "contact@sharemysuper.org.nz"
        },
        "sameAs": [
            "https://www.facebook.com/sharemysuper/",
            "https://www.instagram.com/sharemysuper/",
            "https://www.linkedin.com/company/share-my-super/"
        ]
    };

    return (
        <>
            <Helmet>
                <meta property={ "title" } content={ SeoPages.home.title } />
                <meta property={ "description" } content={ SeoPages.home.description } />
                <meta property={ "og:title" } content={ SeoPages.home.ogTitle } />
                <meta property={ "og:description" } content={ SeoPages.home.ogDescription } />
                <meta property={ "og:image" } content={ SeoPages.home.ogImage } />
                <meta property={ "og:url" } content={ `${process.env.REACT_APP_WEB_URL}/` } />
                <title>{ SeoPages.home.title }</title>
                <script type={ "application/ld+json" }>
                    { JSON.stringify(ldJson) }
                </script>
            </Helmet>
            <div className={ styles.home_container }>
                <div className={ styles.page_header }>
                    <div className={ styles.mobile_image }>
                        <AliceCarousel
                            infinite
                            disableButtonsControls
                            disableSlideInfo
                            autoPlayInterval={ 2500 }
                            autoHeight
                            disableDotsControls
                            autoPlay
                            items={ banner.mobileImages.map(i => <div key={ `banner-mobile-${i}` } className={ styles.mobile_image } style={ { backgroundImage: `url(${imageUrlFor(i)})` } } />) }
                        />
                    </div>


                    <div className={ styles.text_area }>
                        <h1 className={ styles.title }>
                            { renderBlockText(banner.heading) }
                        </h1>
                        <p className={ styles.blurb }>
                            { banner.subheading }
                        </p>
                        <div className={ styles.buttons }>
                            <ButtonBlock className={ styles.button_block } buttons={ getCmsButtons(banner.buttons) } />
                        </div>
                    </div>
                    <div className={ styles.image_area }
                    >
                        <AliceCarousel
                            infinite
                            autoPlay
                            autoPlayInterval={ 2500 }
                            disableButtonsControls
                            autoHeight
                            disableSlideInfo
                            disableDotsControls
                            items={ banner.images.map(i => <div key={ `banner-desktop-${i}` } className={ styles.image } style={ { backgroundImage: `url(${imageUrlFor(i)})` } } />) }
                        />
                    </div>
                </div>
                <CardBlock heading={ cardsBlock.heading } subheading={ cardsBlock.subheading } cards={ cardsBlock.cards } />
                {/* <PressLogoBlock press={props.page.press}/> */ }
                <VideoBlock videoBlock={ props.page.video } />
                <div className={ styles.main_inner }>
                    {/*<IntroBlock*/ }
                    {/*    id={"intro"}*/ }
                    {/*    introBlock={props.page.intro}/>*/ }
                    <FeaturedCharitiesBlock
                        heading={ "Choose from proven charities" }
                        nominatedCharities={ charities }
                    />
                    <div className={ styles.bottom }>
                        <div className={ styles.wrapper } style={ { top: -160 } }>
                            <HomeFormBlock />
                        </div>

                    </div>
                </div>
                <div className={ styles.scrolling }>
                    <ScrollingImages topImage={ scrollingOneTop } bottomImage={ scrollingOneBottom } />
                </div>
                <TestimonialsBlock
                    navigate={ props.navigate }
                    testimonialBlock={ !!props.page.testimonial ? props.page.testimonial : {} }
                />
                <OurStoryBlock ourStory={ props.page.ourStory } />
                <CharityImpactBlock charityImpacts={ props.page.charityImpacts } navigate={ props.navigate }
                />
                <div className={ styles.main_inner_last }>
                    <FaqBlock faqBlock={ props.page.faqBlock } className={ styles.faq } />
                    <div className={ styles.bottom }>
                        <div className={ styles.wrapper }>
                            <PreFooter bottom />
                        </div>

                    </div>
                </div>
                <div className={ styles.scrolling_last }>
                    <ScrollingImages topImage={ scrollingTwoTop } bottomImage={ scrollingTwoBottom } />
                </div>
            </div>
        </>
    );
};

export default connect((state) => {
    return {
        page: state.pages.home,
        nominatedCharities: state.nominatedCharities
    };
})(withRouter(Home));
