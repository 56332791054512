import React, { useEffect } from "react";
import { connect } from "react-redux";
import styles from "./Charity.module.scss";
import Button from "../../common/widgets/button/Button";
import { imageUrlFor, isScrolledIntoView, renderBlockText } from "../../../util/util";
import { setActiveCharity } from "../../../redux/actions/charitySelectionNavigation";
import withRouter from "../../../util/hooks/withRouter";

function Charity(props) {
    const { charity } = props;

    useEffect(() => {
        document.addEventListener("scroll", () => {
            const el = document.getElementById(charity.id);
            if (el && isScrolledIntoView(el)) {
                props.dispatch(setActiveCharity(charity.id));
            }
        });

        if (props.scrollIntoView) {
            const el = document.getElementById(charity.id);
            if (el) {
                el.scrollIntoView();
            }
        }
    }, []);

    const onDonateClickPress = () => {
        props.navigate(`/donate?charity=${charity.id}`);
    };

    return (
        <div className={styles.charity_container} id={charity.id}>
            <h1>
                <span>{charity.name}</span> {charity.heading}
            </h1>
            <div className={styles.intro}>
                {renderBlockText(charity.intro)}
            </div>
            <Button className={styles.donate_button_mobile} onPress={onDonateClickPress}>Donate
                to {charity.name}</Button>

            <div className={styles.charity_hero}
                 style={{ backgroundImage: `url(${imageUrlFor(charity.heroImage)})` }} />
            <div className={styles.charity_statistic}>
                {charity.statIntro}
                <h3>{renderBlockText(charity.statistic)}</h3>
            </div>
            <div className={styles.key_points}>
                <div className={styles.logo}>
                    <img src={imageUrlFor(charity.logo)} alt={charity.name} />
                </div>
                <div className={styles.key_points_content}>
                    <div className={styles.point}>
                        {(charity.keyPoint || []).map((point) => {
                            return (
                                <div key={point._key}>
                                    {renderBlockText(point.point)}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className={styles.charity_content}>
                {renderBlockText(charity.content)}
            </div>
            <Button className={styles.donate_button} onPress={onDonateClickPress}>Donate to {charity.name}</Button>

        </div>
    );
}


export default connect()(withRouter(Charity));
