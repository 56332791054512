import React, { Component } from "react";
import { connect } from "react-redux";
import CharityCardItem from "../../widgets/charityCardItem/CharityCardItem";
import ButtonBlock from "../buttonBlock/ButtonBlock";
import styles from "./FeaturedCharitiesBlock.module.scss";
import ComponentLoader from "../../widgets/loaders/componentLoader/ComponentLoader";
import Routes from "../../../../util/routes";
import { imageUrlFor } from "../../../../util/util";
import withRouter from "../../../../util/hooks/withRouter";
import arrowWhite from "../../../../resources/images/png/arrow-white.png"

class FeaturedCharitiesBlock extends Component {

    onViewMorePress = () => {
        this.props.navigate(Routes.charities.root);
    };

    renderNominatedCharities = () => {
        const charities = [];
        const length = this.props.nominatedCharities.length > 6 ? 6 : this.props.nominatedCharities.length;
        for (let i = 0; i < length; i += 1) {
            charities.push(this.props.nominatedCharities[i]);
        }

        // if (charities.length < 6) {
        //     style = { justifyContent: "center" };
        // }
        return (
            <div className={styles.charity_content_container}>
                {charities.map((charity) => (
                    <CharityCardItem
                        key={charity.id}
                        charity={charity}
                        image={imageUrlFor(charity.card.image) || null}
                        homeContent={charity.card.homeBlurb}/>
                ))}
            </div>

        );
    };

    render() {
        if (this.props.nominatedCharities && this.props.nominatedCharities.length === 0) {
            return null;
        }

        const buttons = [
            {
                buttonText: "See all charities",
                onPress: this.onViewMorePress,
                props: { outline: true }
            }
        ];

        return (
            <div className={styles.featured_charities_block_container}>
                <ButtonBlock
                    className={styles.button_block_first}
                    buttons={buttons}
                />
                <h2>Give our kids better chances, like these.</h2>
                {!this.props.nominatedCharities ? <ComponentLoader/> : (
                    this.renderNominatedCharities()
                )}
            </div>
        );
    }
}

export default connect((state) => {
    return {
        charities: state.charities
    };
})(withRouter(FeaturedCharitiesBlock));
