import React from "react";
import styles from "./Banner.module.scss";
import Button from "../button/Button";
import { Link } from "react-router-dom";

export default function Banner(props) {
    if (!props.banner) {
        return null;
    }

    return (
        <div className={ styles.banner }>
            <p>
                { props.banner.content }
            </p>
            <a href={ props.banner.button.type === "tel:" ? `tel:${props.banner.button.value}` : props.banner.button.value }>
                { props.banner.button.text }
            </a>
        </div>
    )
}