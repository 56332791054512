import React from "react";
import { Field, Formik } from "formik";
import * as classnames from "classnames";
import PropTypes from "prop-types";
import styles from "./SearchHeroBlock.module.scss";
import TextInput from "../../widgets/input/textInput/TextInput";
import SelectInput from "../../widgets/input/selectField/SelectInput";
import Button from "../../widgets/button/Button";

function SearchHeroBlock(props) {
    return (
        <div className={classnames(styles.search_hero_block_container, props.className)}
             style={{ backgroundColor: props.backgroundColor }}>
            <div className={styles.search_hero_block_content}>
                <h1>{props.heading}</h1>
                {!!props.subheading && <p>{props.subheading}</p>}
                {props.hideSearch ? null : (<Formik initialValues={{ search: "", filter: "" }}
                                                    enableReinitialize
                                                    onSubmit={props.onSubmit}>
                    {(formProps) => (
                        <form onSubmit={formProps.handleSubmit}>
                            <Field name={"search"}
                                   type={"text"}
                                   placeholder={"Search"}
                                   component={TextInput}
                            />

                            {props.hideFilters ? null : (
                                <Field name={"filter"}
                                       options={props.filterOptions}
                                       small
                                       component={SelectInput}
                                />
                            )}
                            <Button type={"submit"} onPress={formProps.handleSubmit} small formSubmit>Search</Button>
                            <Button type={"button"}
                                    clearLink
                                    onPress={() => {
                                        props.onClearPress();
                                        formProps.resetForm();
                                    }}>
                                Clear
                            </Button>
                        </form>
                    )}
                </Formik>)}
            </div>
        </div>
    );
}

SearchHeroBlock.propTypes = {
    className: PropTypes.string,
    heading: PropTypes.string,
    subheading: PropTypes.string,
    backgroundColor: PropTypes.any,
    filterOptions: PropTypes.array,
    onSubmit: PropTypes.func,
    onClearPress: PropTypes.func,
    hideFilters: PropTypes.bool
};

SearchHeroBlock.defaultProps = {
    className: null,
    heading: null,
    subheading: null,
    backgroundColor: null,
    filterOptions: [],
    hideFilters: false
};

export default SearchHeroBlock;
