export function getUserAPI() {
    return window.get("/v1/me");
}

export function getUserStatusAPI(email) {
    return window.post("/v1/me/status", email);
}

export function updateUserAPI(data) {
    return window.put("/v1/me", data);
}

export function deleteUserAPI(captcha) {
    return window.del("/v1/me", undefined, captcha);
}

export function addCardToUserAPI(cardToken, captcha) {
    return window.post(`/v1/me/cards/${cardToken}`, undefined, undefined, captcha);
}

export function getUserCardsAPI(captcha) {
    return window.get("/v1/me/cards", undefined, undefined, captcha);
}

export function updateUserCardAPI(cardId, data, captcha) {
    return window.put(`/v1/me/cards/${cardId}`, data, undefined, captcha);
}

export function setDefaultUserCardAPI(data, cardId) {
    return window.put(`/v1/me/cards/${cardId}`, data);
}

export function deleteUserCardAPI(cardId) {
    return window.del(`/v1/me/cards/${cardId}`);
}

// charity subscription

export function createSubscriptionAPI(data) {
    return window.post("/v1/me/subscription", data);
}

export function getSubscriptionAPI() {
    return window.get("/v1/me/subscription");
}

export function updateSubscriptionAPI(data) {
    return window.put("/v1/me/subscription", data);
}

export function cancelSubscriptionAPI() {
    return window.del("/v1/me/subscription");
}

export function pauseSubscriptionAPI(continueDate) {
    return window.put("/v1/me/subscription/pause", continueDate);
}

export function updateCharitySubscriptionAPI(charitySubId, data) {
    return window.put(`/v1/me/subscription/charities/${charitySubId}`, data);
}

export function cancelCharitySubscriptionAPI(charitySubId) {
    return window.del(`/v1/me/subscription/charity/${charitySubId}`);
}

export function updateCharitySubscriptionAmountsAPI(data) {
    return window.put("/v1/me/subscription/charities", data);
}

export function subscribeEmailToMailchimpListAPI(email) {
    const body = {email: email};
    return window.post("/v1/mailchimp/subscribe/email", JSON.stringify(body));
}

export function subscribeUserToMailChimpListAPI(user) {
    return window.post("/v1/mailchimp/subscribe/user", user);
}

export function getUserTransactionsAPI(params) {
    return window.get("/v1/me/transactions", params);
}

// one off donation

export function createOneOffDonationAPI(data) {
    return window.post("/v1/me/donate", data);
}
