import { SET_NOMINATED_CHARITIES } from "../actions/charities";

const initialState = [];

export default (state = initialState, action) => {

    if (action.type === SET_NOMINATED_CHARITIES) {

        return [
            ...state,
            ...action.payload
        ];
    }

    return [
        ...state
    ];
};
