/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-06-12.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import { Component as ReactComponent } from "react";
import { showError } from "../../../../redux/actions/snackbars";

const DEFAULT_OPTIONS = {
    loadingId: "loading",
    showErrorSnackbar: true,

    onSuccess: () => null,
    onLoadingSuccess: () => null,
    onError: () => null,
    onLoadingError: () => null,
};

export default class Component extends ReactComponent {

    dispatch = (dispatchFunc, dispatchPayload = {}, options = {}) => {

        const { loadingId, onSuccess, onLoadingSuccess, onError, onLoadingError, showErrorSnackbar } = { ...DEFAULT_OPTIONS, ...options };

        const func = () => {
            this.props.dispatch(dispatchFunc({
                ...dispatchPayload,
                onSuccess: (data) => {
                    if (!!onSuccess) {
                        onSuccess(data);
                    }
                    this.setState({
                        [loadingId]: false
                    }, onLoadingSuccess);
                },
                onError: (err) => {

                    if (!!showErrorSnackbar) {
                        this.props.dispatch(showError(err.message));
                    }

                    if (!!onError) {
                        onError(err);
                    }

                    this.setState({
                        [loadingId]: false
                    }, onLoadingError);
                }
            }));
        };

        if (!this.state[loadingId]) {
            this.setState({
                [loadingId]: true
            }, func);
        } else {
            func();
        }
    };

    timeoutLoader = (loadingId = "loading") => {
        const timeout = setTimeout(() => {
            this.setState({
                [loadingId]: false
            });
            clearTimeout(timeout);
        }, 500);
    };

    onRef = (name) => {
        return (ref) => {
            this[name] = ref;
        };
    };
}
