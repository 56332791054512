import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

import * as UsersAPI from "./api/user";

export const SET_SUBSCRIPTION = "subscription:SET_SUBSCRIPTION";

export const setSubscription = createAction(SET_SUBSCRIPTION);

export const createSubscription = wrapper(UsersAPI.createSubscriptionAPI, (dispatch, subscription) => {
    dispatch(setSubscription(subscription));
});

export const updateSubscription = wrapper(UsersAPI.updateSubscriptionAPI, (dispatch, subscription) => {
    dispatch(setSubscription(subscription));
});

export const pauseSubscription = wrapper(UsersAPI.pauseSubscriptionAPI, (dispatch, subscription) => {
    dispatch(setSubscription(subscription));
});

export const deleteSubscription = wrapper(UsersAPI.cancelSubscriptionAPI, (dispatch, subscription) => {
    dispatch(setSubscription(subscription));
});

export const getSubscription = wrapper(UsersAPI.getSubscriptionAPI, (dispatch, subscription) => {
    dispatch(setSubscription(subscription));
});

export const updateSubscriptionAmounts = wrapper(UsersAPI.updateCharitySubscriptionAmountsAPI, (dispatch, subscription) => {
    dispatch(setSubscription(subscription));
});

