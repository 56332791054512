import React from "react";
import qs from "qs";
import moment from "moment";
import styles from "./EofyReceipt.module.scss";

export default function EofyReceipt() {
    const urlParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    const receiptParams = JSON.parse((urlParams.receiptParams || {}));
    const {
        charityAddress,
        charityName,
        charityNumber,
        charityRepName,
        charityRepTitle,
        donationTotal,
        donorFullName,
        donorId,
        gstNumber,
        periodEndDate,
        periodStartDate,
        receiptDate,
        receiptNumber,
        signatureUrl,
        websiteUrl,
        logoUrl
    } =  receiptParams;

    return (
        <div className={styles.receipt_container}>
            <div className={styles.receipt_header}>
                <div className={styles.charity_logo}>
                    <img src={logoUrl} alt={""}/>
                </div>
                <div className={styles.charity_information}>
                    <p><strong>{charityName}</strong></p>
                    <p>{charityAddress}</p>
                    <p>Charities No. {charityNumber}</p>
                    <p><a href={websiteUrl}>{websiteUrl}</a></p>
                </div>
            </div>
            <div className={styles.receipt_intro}>
                <p>{moment(receiptDate).format("01-04-YYYY")}</p>
                <p>{`Donor ID: ${donorId}`}</p>
                <p><strong>Tax Certificate {moment(periodStartDate).format("YYYY")}-{moment(periodEndDate).format("YY")}</strong></p>
                <p>Dear {donorFullName},</p>
                <p> Thank you for your support of {charityName}.</p>
                <p>Thanks to you we can continue our work to improve the lives of New Zealand children, rangatahi,
                    their families and whānau.
                </p>
                <p>This tax certificate covers ALL donations to {charityName} through the Share My Super
                    platform for the period {moment(periodStartDate).format("01-04-YY")} to {moment(periodEndDate).format("31-03-YY")}.
                </p>
                <p><strong>Total donated:</strong> {donationTotal}<br/>
                    <strong>Receipt no:</strong> {receiptNumber}
                </p>
            </div>
            <div className={styles.receipt_secondary}>
                <p><strong>{charityName} IRD number:</strong> {gstNumber}
                    <br/><strong>Charities no:</strong> {charityNumber}
                    <br/><strong>Received from:</strong> {donorFullName}
                </p>
                <p>Yours sincerely,</p>
                <img src={signatureUrl} alt={""}/>
                <p>{charityName}</p>
                <p>{charityRepName}, {charityRepTitle}</p>
            </div>
        </div>
    );
}
