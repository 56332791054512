import React from "react";
import { Field } from "formik";
import TextInput from "../../../common/widgets/input/textInput/TextInput";
import Button from "../../../common/widgets/button/Button";
import styles from "../ContactUs.module.scss";

const ContactUsForm = (props) => {
    const { handleChange, handleSubmit, isValid, loading } = props;
    return (
        <form>
            <Field name={"name"}
                   label={"Name"}
                   component={TextInput}
                   onChange={handleChange}/>
            <Field name={"email"}
                   label={"Email"}
                   component={TextInput}
                   onChange={handleChange}/>
            <Field name={"phone"}
                   label={"Contact number"}
                   component={TextInput}
                   onChange={handleChange}/>
            <Field name={"message"}
                   label={"Message"}
                   inputComponent={"textarea"}
                   className={styles.message_wrapper}
                   inputClassName={styles.message_input}
                   component={TextInput}
                   onChange={handleChange}/>
            <Button text={"SUBMIT"}
                    formSubmit
                    disabled={!isValid}
                    loading={loading}
                    onPress={handleSubmit}/>
        </form>
    );
};

export default ContactUsForm;
