import React from "react";
import * as classnames from "classnames";
import { Field, Form, Formik } from "formik";
import { object, string } from "yup";
import { connect } from "react-redux";
import styles from "./PreFooter.module.scss";
import TextInput from "../../widgets/input/textInput/TextInput";
import Button from "../../widgets/button/Button";
import { subscribeEmailToMailChimpList } from "../../../../redux/actions/user";
import { createActiveCampaignContact } from "../../../../redux/actions/api/contact";
import withRouter from "../../../../util/hooks/withRouter";

const validationSchema = object().shape({
    email: string()
        .email("Invalid email")
        .required(" ")
});

class PreFooter extends React.Component {


    getText = () => {
        if ( this.props.bottom ) {
            return ({
                top: "Join our",
                middle: "Super Community",
            })
        }else{
            return({
                top: "Join the community",
                middle: "making a difference.",
            })
        }
    }

    onSubmit = (values, formProps) => {
        this.props.dispatch(subscribeEmailToMailChimpList({
            email: values.email,
            onSuccess: () => {
                formProps.resetForm();
                // subscribe to active campaign
                createActiveCampaignContact(values.email);
            },
            onError: () => {
                console.log("error");
            }
        }));
    };

    onDonatePress = () => {
        this.props.navigate("/donate");
    };

    render () {
        return (
            <div className={classnames(styles.pre_footer_container, this.props.className)}>
                <div className={styles.pre_footer_content}>
                    <div className={styles.footer_left}>
                        <h3>{this.getText().top}</h3>
                        <h1>
                            {this.getText().middle}
                        </h1>
                    </div>
                    <div className={styles.footer_right}>
                        <Button onPress={this.onDonatePress}
                                darkgreen
                                className={styles.donate_button}
                                text={"Donate"}/>
                        <p>
                            Together, we can do something amazing. Join our community of Kiwi superannuants giving New Zealand children a fair chance to thrive and succeed.
                        </p>
                        <Formik initialValues={{ email: "" }}
                                validationSchema={validationSchema}
                                onSubmit={this.onSubmit}>
                            {({ errors }) => (
                                <Form>
                                    <Field name={"email"}
                                           type={"email"}
                                           placeholder={"Email Address"}
                                           component={TextInput}
                                    />
                                    <Button text={"Subscribe"}
                                            type={"submit"}
                                            className={styles.submit_button}
                                            medium
                                            disabled={(!this.props.dirty && this.props.valid) || !!errors.email}
                                    />
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect()(withRouter(PreFooter));
