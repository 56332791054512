import React from "react";
import styles from "./PressItemBlock.module.scss";
import { imageUrlFor } from "../../../../util/util";
import AssetItem from "../../widgets/assetItem/AssetItem";

function PressItemBlock(props) {
    if (!props.assetsBlock) {
        return <div/>;
    }

    const assetsBlock = props.assetsBlock;

    return (
        <div className={styles.quad_block_container}>
            <h3>{assetsBlock.heading}</h3>
            <div className={styles.quad_block_inner}>
                {assetsBlock.assetItems.map(assetItem => (
                    <AssetItem
                        key={`assetItem.${assetItem._id}`}
                        image={imageUrlFor(assetItem.thumbnail)}
                        heading={assetItem.heading}
                        fileUpload={assetItem.file.upload}
                        link={assetItem.file.buttonText}

                    />
                ))}

            </div>
        </div>
    );
}

export default PressItemBlock;