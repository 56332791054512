import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from "react-accessible-accordion";
import PropTypes from "prop-types";
import * as classnames from "classnames";
import { connect } from "react-redux";
import styles from "./FaqBlock.module.scss";
import { renderBlockText } from "../../../../util/util";
import withRouter from "../../../../util/hooks/withRouter";


class FaqBlock extends Component {
    static propTypes = {
        heading: PropTypes.string,
        className: PropTypes.string,
    };

    static defaultProps = {
        heading: "",
        className: null,
    };

    render() {
        if (!this.props.faqBlock) {
            return null;
        }

        const faqBlock = this.props.faqBlock;

        return (
            <div className={classnames(styles.faq_block_container, this.props.className)}>
                {!!faqBlock.heading && <h2>{faqBlock.heading}</h2>}
                <div className={styles.faq_block_content}>
                    <Accordion className={styles.faq_accordion} allowZeroExpanded>

                        {faqBlock.faqs.map((faq) => {
                            return (
                                <AccordionItem className={styles.faq_accordion_item} key={faq._id}>
                                    <AccordionItemHeading className={styles.faq_accordion_heading}>
                                        <AccordionItemButton className={styles.faq_accordion_button} >
                                            {faq.heading}
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className={styles.faq_accordion_panel}>
                                        {renderBlockText(faq.faqExcerpt)}
                                    </AccordionItemPanel>
                                </AccordionItem>
                            );
                        })}

                    </Accordion>
                </div>

            </div>
        );
    }
}

export default connect(() => {
    return {};
})(withRouter(FaqBlock));
