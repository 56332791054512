import React, { Component } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet/es/Helmet";
import HeroBlock from "../../common/blocks/heroBlock/HeroBlock";
import ImageTextItem from "../../common/widgets/imageTextItem/ImageTextItem";
import FaqBlock from "../../common/blocks/faqBlock/FaqBlock";
import PreFooter from "../../common/blocks/preFooter/PreFooter";
import { pageReceived } from "../../../redux/actions/pages";
import styles from "./HowDoesItWork.module.scss";
import { getCmsButtons, imageUrlFor, renderBlockText } from "../../../util/util";
import ButtonBlock from "../../common/blocks/buttonBlock/ButtonBlock";
import FullPageLoader from "../../common/widgets/loaders/fullPageLoader/FullPageLoader";
import { SeoPages } from "../../../util/seoConstants";
import withRouter from "../../../util/hooks/withRouter";
import { VideoCard } from "../../common/widgets/videoCard/VideoCard";
import thumbnail from "../../../resources/images/png/how-it-works-video-thumbnail.png";

class HowDoesItWork extends Component {
    constructor(props) {
        super(props);
        const query = `*[_type == "howDoesItWork"]{
        _id,
        title,
        hero,
        content,
        "faqBlock":faqBlock{...,faqs[]->},
        preFooter,
        video
        }[0]`;

        this.state = {
            loading: true
        };

        window.sanity.fetch(query).then((page) => {
            this.props.dispatch(pageReceived({ "howDoesItWork": page }));
            this.setState({ loading: false });
        });
    }

    render() {
        if (this.state.loading) {
            return (
                <FullPageLoader />
            );
        }
        const page = this.props.page;
        const hero = page.hero;
        const video = page.video;

        // const preFooter = page.preFooter;

        const ldJson = {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [ {
                "@type": "Question",
                "name": "Can I change or stop donations that I set up through the first Share My Super website?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "If you set up donations through the 2019 Spend My Super website, we can help you with any changes you’d like to make – call us on 09 222 0668 or email contact@sharemysuper.org.nz"
                }
            }, {
                "@type": "Question",
                "name": "Does Share My Super take a cut from the donations?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "No. Share My Super is privately funded, so every dollar you donate goes towards helping children who really need it. We don’t take any percentage whatsoever. In some cases, we even have matching pledges, doubling the power of your dollar."
                }
            }, {
                "@type": "Question",
                "name": "Can I see all the donations I’ve made?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes. You can view your donation history in your account dashboard."
                }
            } ]
        };

        return (
            <>
                <Helmet>
                    <meta property={ "title" } content={ SeoPages.howDoesItWork.title } />
                    <meta property={ "description" } content={ SeoPages.howDoesItWork.description } />
                    <meta property={ "og:title" } content={ SeoPages.howDoesItWork.ogTitle } />
                    <meta property={ "og:description" } content={ SeoPages.howDoesItWork.ogDescription } />
                    <meta property={ "og:image" } content={ SeoPages.howDoesItWork.ogImage } />
                    <meta property={ "og:url" } content={ `${process.env.REACT_APP_WEB_URL}/how-does-it-work` } />
                    <title>{ SeoPages.howDoesItWork.title }</title>
                    <script type={ "application/ld+json" }>
                        { JSON.stringify(ldJson) }
                    </script>
                </Helmet>
                <div className={ styles.how_does_it_work_container }>
                    <HeroBlock
                        heading={ hero.heading }
                        subheading={ hero.subheading }
                        className={ styles.hero }
                        backgroundImage={ hero.backgroundImage } />
                    { video && (
                        <div className={ styles.video }>
                            <VideoCard thumbnail={ imageUrlFor(video.thumbnail) } video={ video.link } />
                        </div>
                    ) }
                    <div className={ styles.main_inner }>
                        <div className={ styles.how_does_it_work_content }>
                            <div className={ styles.how_does_it_work_intro }>
                                { page.content.map((content, index) => {
                                    return (
                                        <ImageTextItem
                                            itemIndex={ index }
                                            key={ content._key }
                                            image={ imageUrlFor(content.image) }
                                            heading={ content.heading }
                                            subheading={ content.subheading }
                                            content={ renderBlockText(content.content) }
                                        />
                                    );
                                }) }
                                <ButtonBlock
                                    buttons={ getCmsButtons(page.buttons) }
                                />
                            </div>
                            <FaqBlock
                                className={ styles.how_does_it_work_faq }
                                faqBlock={ page.faqBlock } />
                        </div>
                    </div>
                    <PreFooter />
                </div>
            </>
        );
    }
}

export default connect((state) => {
    return {
        page: state.pages.howDoesItWork,
    };
})(withRouter(HowDoesItWork));
