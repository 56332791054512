import { SET_ACTIVE_CHARITY } from "../actions/charitySelectionNavigation";

const initialState = {
    selectedCharity: ""
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_ACTIVE_CHARITY:
            return {
                ...state,
                selectedCharity: action.payload
            };
        default:
            return {
                ...state
            };
    }
};
