import React, { useState } from "react";

import Autocomplete from "react-google-autocomplete";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./AddressInput.module.scss";

const AddressInput = React.forwardRef((props, ref) => {

    const [value, setValue] = useState(props.initialAddress);

    const onChange = (e) => {
        setValue(e.target.value)
        props.form.setFieldValue(props.field.name, e.target.value)

    };

    const onPlaceSelected = (place) => {
        let address = "";
        let suburb = "";
        let city = "";
        let postCode = "";

        place.address_components.map((addressComponent) => {
            if ( addressComponent.types.indexOf("street_number") > -1 ) {
                address = addressComponent.long_name;
            }

            if ( addressComponent.types.indexOf("route") > -1 ) {
                address = `${address} ${addressComponent.long_name}`;
            }

            if ( addressComponent.types.indexOf("sublocality") > -1 ) {
                suburb = addressComponent.long_name;
            }

            if ( addressComponent.types.indexOf("locality") > -1 ) {
                city = addressComponent.long_name;
            }

            if ( addressComponent.types.indexOf("postal_code") > -1 ) {
                postCode = addressComponent.long_name;
            }
            return null;
        });

        if ( address !== "" ) {
            props.form.setFieldValue("line1", address);
        }

        if ( suburb !== "" ) {
            props.form.setFieldValue("state", suburb);
        }

        if ( city !== "" ) {
            props.form.setFieldValue("city", city);
        }

        if ( postCode !== "" ) {
            props.form.setFieldValue("postalCode", postCode);
        }
        props.form.setFieldValue(props.field.name, place.formatted_address)
        setValue(place.formatted_address);
    };
    return (
        <div className={classnames(styles.input_wrapper, props.classname)}>
            {props.label &&
                <label htmlFor={props.id} className={props.labelClassName}>{props.label}</label>}
            <div className={classnames(styles.input, props.inputClassName)}>
                <Autocomplete ref={ref}
                              {...props.field}
                              placeholder={"Enter address"}
                              onPlaceSelected={onPlaceSelected}
                              value={value || ""}
                              onChange={onChange}
                              onBlur={props.field.onBlur}
                              options={{
                                  types: ["address"],
                                  componentRestrictions: { country: "nz" }
                              }}
                />
            </div>
            {props.form  && props.form.errors[props.field.name] && props.form.touched[props.field.name] &&
                <div className={styles.error_text}>{props.form.errors[props.field.name]}</div>}
        </div>
    );
});

AddressInput.propTypes = {
    label: PropTypes.string,
    labelClassName: PropTypes.string,
    inputClassName: PropTypes.string
};

AddressInput.defaultProps = {
    label: "",
    labelClassName: "",
    inputClassName: "",
};

export default AddressInput;
