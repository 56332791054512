import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./Facts.module.scss";
import Banner from "../../../resources/images/png/SMS_banner_facts.jpg";
import MobileBanner from "../../../resources/images/png/SMS_banner_facts_mobile.jpg";
import { pageReceived } from "../../../redux/actions/pages";
import FullPageLoader from "../../common/widgets/loaders/fullPageLoader/FullPageLoader";
import PreFooter from "../../common/blocks/preFooter/PreFooter";
import { renderBlockText } from "../../../util/util";
import withRouter from "../../../util/hooks/withRouter";

class Facts extends Component {
    constructor(props) {
        super(props);
        const query = `*[_type == "facts"]{
        _id,
        heading,
        introContent,
        pageContent[]{..., "asset": asset->},
        ctaContent,
        preFooter,
        }[0]`;

        this.state = {
            loading: true
        };

        window.sanity.fetch(query).then((page) => {
            this.props.dispatch(pageReceived({ "facts": page }));
            this.setState({ loading: false });
        });
    }

    render() {
        if (this.state.loading) {
            return (
                <FullPageLoader/>
            );
        }
        const page = this.props.page;
        // const preFooter = page.preFooter;

        return (
            <div className={styles.facts_page_container}>
                <img className={styles.facts_banner_desktop} src={Banner} alt={""}/>
                <img className={styles.facts_banner_mobile} src={MobileBanner} alt={""}/>
                <div className={styles.facts_inner_container}>
                    <h1>{page.heading}</h1>
                    <div className={styles.intro_content}> {!!page.introContent && renderBlockText(page.introContent)}</div>
                    <div className={styles.page_content}> {!!page.pageContent && renderBlockText(page.pageContent)}</div>
                    <div className={styles.cta_content}> {!!page.ctaContent && renderBlockText(page.ctaContent)}</div>

                </div>
                <PreFooter/>
            </div>
        );
    }
}

export default connect((state) => {
    return {
        page: state.pages.facts,
    };
})(withRouter(Facts));
