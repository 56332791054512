import React, { useEffect } from "react";
import { connect } from "react-redux";
import Sticky from "react-stickynode";
import styles from "../charitySelection/CharitySelection.module.scss";
import CharitySidebar from "../../common/blocks/charitySidebar/CharitySidebar";
import Charity from "../charity/Charity";
import withRouter from "../../../util/hooks/withRouter";

function CharitiesView(props) {
    const { charities, charityId } = props;

    useEffect(() => {
        const el = document.getElementById(charityId);
        if (el) {
            el.scrollIntoView();
        }
    }, [charityId]);

    return (
        <div className={styles.charities_selection_content} id={"charity"}>
            <Sticky enabled top={50} bottom={50} bottomBoundary={"#charity"} className={styles.sticky}>
                <CharitySidebar
                    charities={charities}
                />
            </Sticky>

            <div className={styles.charities}>
                {Object.values(charities).map((charity) => {
                    return (
                        <Charity
                            charity={charity}
                            key={charity._id}
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default connect((state) => {
    return {
        charities: state.pages.charity,
    };
})(withRouter(CharitiesView));
