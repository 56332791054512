import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import ContactUsForm from "./contactUsForm/ContactUsForm";
import PreFooter from "../../common/blocks/preFooter/PreFooter";
import { contactFormAPI } from "../../../redux/actions/api/contact";
import { showError, showSuccess } from "../../../redux/actions/snackbars";
import styles from "./ContactUs.module.scss";
import SearchHeroBlock from "../../common/blocks/SearchHeroBlock/SearchHeroBlock";
import Routes from "../../../util/routes";
import { SeoPages } from "../../../util/seoConstants";
import withRouter from "../../../util/hooks/withRouter";
import { FirebaseContext } from "../../../context/firebase";

const ContactUsSchema = Yup.object().shape({
    name: Yup.string()
        .required("Required"),
    email: Yup.string()
        .email("Invalid email")
        .required("Required"),
    message: Yup.string()
        .required("Required")
});

const initValues = {
    name: "",
    email: "",
    message: "",
    phone: ""
}

class ContactUs extends Component {
    static contextType = FirebaseContext;

    state = {
        loading: false
    };

    onSubmit = (values) => {
        this.setState({ loading: true });
        contactFormAPI(values)
            .then(res => {
                this.setState({ loading: false });
                if (res) {
                    this.props.dispatch(showSuccess("Message successfully submitted"));
                    this.contactForm.resetForm({
                        name: "",
                        email: "",
                        phone: "",
                        message: ""
                    });
                } else {
                    this.props.dispatch(showError("Error submitting your message, please try again"));
                }
            });
    };

    onViewMorePress = () => {
        this.props.navigate(Routes.charities.root);
    };

    onDonateNowPress = () => {
        window.dataLayer.push({ "event": "clicked_donate" })
        this.props.navigate(Routes.donationTypeSelect);
    };

    render() {
        // const buttons = [
        //     {
        //         buttonText: "Donate",
        //         onPress: this.onDonateNowPress,
        //         props: {}
        //     },
        //     {
        //         buttonText: "View Charities",
        //         onPress: this.onViewMorePress,
        //         props: { outline: true }
        //     }
        // ];

        return (
            <>
                <Helmet>
                    <meta property={ "title" } content={ SeoPages.contactUs.title } />
                    <meta property={ "description" } content={ SeoPages.contactUs.description } />
                    <meta property={ "og:title" } content={ SeoPages.contactUs.ogTitle } />
                    <meta property={ "og:description" } content={ SeoPages.contactUs.ogDescription } />
                    <meta property={ "og:image" } content={ SeoPages.contactUs.ogImage } />
                    <meta property={ "og:url" } content={ `${process.env.REACT_APP_WEB_URL}/contact` } />
                    <title>{ SeoPages.contactUs.title }</title>
                </Helmet>
                <div className={ styles.contact_us_container }>
                    <SearchHeroBlock
                        className={ styles.contact_hero_block }
                        heading={ "Contact us" }
                        subheading={ "Please feel free to contact us regarding any queries you have. We are more then happy to help." }
                        hideSearch
                    />
                    <div className={ styles.contact_us_content }>
                        <div className={ styles.content_left }>
                            <Formik onSubmit={ this.onSubmit }
                                ref={ ref => this.contactForm = ref }
                                validationSchema={ ContactUsSchema }
                                initialValues={ initValues }
                            >
                                { (formProps) => {
                                    console.log(formProps);
                                    return (
                                        <ContactUsForm { ...formProps }
                                            loading={ this.state.loading } />
                                    )
                                } }
                            </Formik>
                        </div>
                        <div className={ styles.content_right }>
                            <h3>Help</h3>
                            <p><strong>For general enquiries</strong><br />
                                <a href={ "mailto:contact@sharemysuper.org.nz" }>contact@sharemysuper.org.nz</a>
                            </p>
                        </div>
                    </div>
                    <PreFooter />

                </div>
            </>
        );
    }
}

export default withRouter(connect()(ContactUs));
