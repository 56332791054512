import del from "./api/del";
import get from "./api/get";
import post from "./api/post";
import put from "./api/put";

window.get = get;
window.put = put;
window.post = post;
window.del = del;

if (process.env.ENV === "production") {
    if (!!window.console && !!window.console && !!window.console.log) {
        window.console.log = () => null;
    }
}

