export const CharityRegions = {
    NORTHLAND: { value: "NORTHLAND", label: "Northland" },
    AUCKLAND: { value: "AUCKLAND", label: "Auckland" },
    WAIKATO: { value: "WAIKATO", label: "Waikato" },
    TARANAKI: { value: "TARANAKI", label: "Taranaki" },
    BAY_OF_PLENTY: { value: "BAY_OF_PLENTY", label: "Bay of Plenty" },
    HAWKES_BAY: { value: "HAWKES_BAY", label: "Hawkes Bay" },
    GISBOURN_EAST_CAPE: { value: "GISBOURN_EAST_CAPE", label: "Gisborne/East Cape" },
    WHANDANUI_MANAWATU: { value: "WHANDANUI_MANAWATU", label: "Whanganui/Manawatu" },
    WELLINGTON: { value: "WELLINGTON", label: "Wellington" },
    TASMAN_NELSON: { value: "TASMAN_NELSON", label: "Tasman/Nelson" },
    MALBOROUGH: { value: "MALBOROUGH", label: "Malborough" },
    WEST_COAST: { value: "WEST_COAST", label: "West Coast" },
    CANTERBURY: { value: "CANTERBURY", label: "Canterbury" },
    OTAGO: { value: "OTAGO", label: "Otago" },
    SOUTHLAND: { value: "SOUTHLAND", label: "Southland" }
};
