import * as Types from "../actions/subscription";

const initialState = null;

export default function (state = initialState, action) {
    switch (action.type) {

        case Types.SET_SUBSCRIPTION: {
            return action.payload;
        }

        default: {
            return state;
        }
    }
}
