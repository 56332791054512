import React, { Component } from "react";
import styles from "./CharitySelection.module.scss";
import FullPageLoader from "../../common/widgets/loaders/fullPageLoader/FullPageLoader";
import Helmet from "react-helmet/es/Helmet";
import { SeoPages } from "../../../util/seoConstants";
import SearchHeroBlock from "../../common/blocks/SearchHeroBlock/SearchHeroBlock";
import Hand from "../../../resources/images/png/heart_hand.png";
import PreFooter from "../../common/blocks/preFooter/PreFooter";
import classnames from "classnames";
import { pageReceived } from "../../../redux/actions/pages";
import { connect } from "react-redux";
import CharitiesViewMobile from "../charitiesView/CharitiesViewMobile";
import CharitiesView from "../charitiesView/CharitiesView";
import BenefitItem from "../../common/widgets/benefitItem/BenefitItem";
import withRouter from "../../../util/hooks/withRouter";


class CharitySelection extends Component {
    constructor(props) {
        super(props);

        const charityQuery = `*[_type == "charity"]{
        _id,
        id,
        slug,
        name,
        heading,
        logo,
        intro,
        heroImage,     
        statIntro,
        statistic,
        content,
        keyPoint[]{
            ...,
        },
        }`;

        const charitySelectionQuery = `*[_type == "charitySelection"]{
        _id,
        heading,
        subheading,
        benefits[]->,
        }[0]`;

        this.state = {
            loading: true,
            isMobile: window.innerWidth <= 768
        };

        window.sanity.fetch(charityQuery).then((page) => {
            this.props.dispatch(pageReceived({ "charity": page }));
            window.sanity.fetch(charitySelectionQuery).then((charitySelectionPage) => {
                this.props.dispatch(pageReceived({ "charitySelection": charitySelectionPage }));
                this.setState({ loading: false });
            });
        });

        window.addEventListener("resize", () => {
            this.setState({ isMobile: window.innerWidth <= 768 });
        });
    }

    render() {
        if (this.state.loading) {
            return (
                <FullPageLoader />
            );
        }

        const charities = this.props.charities;
        const charitySelection = this.props.charitySelection;

        const { hash } = window.location;
        let charityId = null;

        if (hash) {
            charityId = hash.substring(1, hash.length);
        }

        return (
            <>
                <Helmet>
                    <meta property={"title"} content={SeoPages.charities.title} />
                    <meta property={"description"} content={SeoPages.charities.description} />
                    <meta property={"og:title"} content={SeoPages.charities.ogTitle} />
                    <meta property={"og:description"} content={SeoPages.charities.ogDescription} />
                    <meta property={"og:image"} content={SeoPages.charities.ogImage} />
                    <meta property={"og:url"} content={`${process.env.REACT_APP_WEB_URL}/charities`} />
                    <title>{SeoPages.charities.title}</title>
                </Helmet>
                <div className={styles.charities_selection_container}>
                    <SearchHeroBlock
                        hideSearch
                        className={styles.charity_selection_hero}
                        heading={charitySelection.heading}
                        subheading={charitySelection.subheading}
                    />
                    <div className={styles.charity_info_container}>
                        <div className={styles.charity_info_inner}>
                            <div className={styles.charity_info}>
                                <img src={Hand} alt={"Hand with a heart inside"} />
                                <div>
                                    <div className={styles.charity_info_header}>
                                        Focused on ending inequity for kids
                                    </div>
                                </div>

                            </div>
                            <div className={styles.charity_info}>
                                <img src={Hand} alt={"Hand with a heart inside"} />
                                <div className={styles.charity_info_header}>Chosen for their effectiveness</div>
                            </div>
                            <div className={styles.charity_info}>
                                <img src={Hand} alt={"Hand with a heart inside"} />
                                <div className={styles.charity_info_header}>Monitored and endorsed by us</div>
                            </div>
                        </div>


                    </div>
                    <div className={styles.main_inner}>
                        {this.state.isMobile ?
                            (<CharitiesViewMobile charities={charities} charityId={charityId} />)
                            :
                            (<CharitiesView charities={charities} charityId={charityId} />)
                        }
                    </div>
                    <PreFooter />
                    <div className={classnames(styles.thanks_container, styles.main_inner)}>
                        <h1>Thank you for making a <span>difference</span></h1>
                        {charitySelection.benefits.map((benefit) => {
                            return (
                                <BenefitItem
                                    key={benefit._id}
                                    benefit={benefit}
                                />
                            );
                        })}
                    </div>
                </div>
            </>
        );
    }
}

export default connect((state) => {
    const newCharities = {};

    (state.pages.charity || []).forEach((c) => {
        newCharities[c.id] = c;
    });

    return {
        charities: newCharities,
        charitySelection: state.pages.charitySelection
    };
})(withRouter(CharitySelection));
