import React, { Component } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet/es/Helmet";
import { pageReceived } from "../../../redux/actions/pages";
import styles from "./Press.module.scss";
import { renderBlockText } from "../../../util/util";
import FullPageLoader from "../../common/widgets/loaders/fullPageLoader/FullPageLoader";
import PressItemBlock from "../../common/blocks/pressItemBlock/PressItemBlock";
import MediaArticleBlock from "../../common/blocks/mediaArticleBlock/MediaArticleBlock";
import HeroBlock from "../../common/blocks/heroBlock/HeroBlock";
import { SeoPages } from "../../../util/seoConstants";
import PreFooter from "../../common/blocks/preFooter/PreFooter";
import withRouter from "../../../util/hooks/withRouter";

class Press extends Component {
    constructor(props) {
        super(props);

        const query = `*[ _type == "pressPage"]
        {
            ...,
            hero,
            assetBlock{
                ..., 
                assetItems[]->{
                    ...,
                    file{
                        ...,
                        "upload":upload.asset->url
                    }
                }
            },
            pressReleases[]->{
                ...,
                file{
                    ...,
                    "upload": upload.asset->url
                }
            }
        }[0]`;

        this.state = {
            loading: true,
        };
        window.sanity.fetch(query).then((page) => {
            this.props.dispatch(pageReceived({ "press": page }));
            this.setState({ loading: false });
        });
    }

    render() {
        if (this.state.loading) {
            return (
                <FullPageLoader />
            );
        }
        const page = this.props.page;
        const hero = page.hero;

        return (
            <>
                <Helmet>
                    <meta property={ "title" } content={ SeoPages.press.title } />
                    <meta property={ "description" } content={ SeoPages.press.description } />
                    <meta property={ "og:title" } content={ SeoPages.press.ogTitle } />
                    <meta property={ "og:description" } content={ SeoPages.press.ogDescription } />
                    <meta property={ "og:image" } content={ SeoPages.press.ogImage } />
                    <meta property={ "og:url" } content={ `${process.env.REACT_APP_WEB_URL}/press` } />
                    <title>{ SeoPages.press.title }</title>
                </Helmet>
                <div className={ styles.press_page }>
                    <HeroBlock
                        heading={ hero.heading }
                        subheading={ hero.subheading } />
                    <div className={ styles.main_inner }>
                        <MediaArticleBlock
                            mediaItems={ page.media } />
                        <div className={ styles.media_contact_container }>
                            <div className={ styles.media_contact_inner }>
                                <h3>For all media enquiries</h3>
                                <h4 className={ styles.h4_normal }>Get in contact with our media representative at <a
                                    href={ "mailto:contact@sharemysuper.org.nz" }>contact@sharemysuper.org.nz</a></h4>
                            </div>
                        </div>
                        <div className={ styles.press_release_container }>
                            <h3>Announcements</h3>
                            <div className={ styles.press_release_inner_content }>
                                { page.pressReleases && page.pressReleases.map((pressRelease) => {
                                    return (
                                        <div key={ pressRelease._id } className={ styles.press_item }>
                                            <h4>{ pressRelease.heading }</h4>
                                            { renderBlockText(pressRelease.content) }
                                            <a href={ pressRelease.file.upload } target={ "_blank" }
                                                rel={ "noopener noreferrer" }>
                                                { pressRelease.file.buttonText }
                                            </a>
                                        </div>
                                    );
                                }) }
                            </div>
                        </div>

                        <PressItemBlock
                            assetsBlock={ page.assetBlock }
                        />

                    </div>
                    <PreFooter />

                </div>
            </>
        );
    }
}


export default connect((state) => {
    return {
        page: state.pages.press,
    };
})(withRouter(Press));
