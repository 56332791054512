import { combineReducers } from "redux";

import * as SettingsTypes from "../actions/settings";
import settings from "./settings";
import user from "./user";
import snackbars from "./snackbars";
import pages from "./pages";
import charities from "./charities";
import subscription from "./subscription";
import nominatedCharities from "./nominatedCharities";
import cards from "./cards";
import transactions from "./transactions";
import charitySelectionNavigation from "./charitySelectionNavigation";
import banner from "./banner";


const appReducer = combineReducers({
    settings: settings,
    snackbars: snackbars,
    user: user,
    subscription: subscription,
    charities: charities,
    pages: pages,
    nominatedCharities: nominatedCharities,
    cards: cards,
    transactions: transactions,
    charitySelectionNavigation: charitySelectionNavigation,
    banner
});

const root = (state, action) => {

    if (action.type === SettingsTypes.PURGE_REDUX_STORE) {
        state = undefined;
    }

    return appReducer(state, action);
};

export default root;
