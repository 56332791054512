import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect } from "react-redux";
import styles from "./ContentCardItem.module.scss";
import { imageUrlFor, renderBlockText } from "../../../../util/util";
import withRouter from "../../../../util/hooks/withRouter";

class ContentCardItem extends Component {
    static propTypes = {
        className: PropTypes.string,
        title: PropTypes.string,
        image: PropTypes.any,
        excerpt: PropTypes.any,
        glance: PropTypes.any,
        subtitle: PropTypes.string,
        overview: PropTypes.string,
        motivation: PropTypes.string,
        solution: PropTypes.string,
        results: PropTypes.string,
        meta: PropTypes.string,
        testimonial: PropTypes.any,
        link: PropTypes.any,
        hidePointer: PropTypes.bool
    };

    static defaultProps = {
        className: null,
        title: null,
        image: null,
        excerpt: null,
        glance: null,
        subtitle: null,
        overview: null,
        motivation: null,
        solution: null,
        results: null,
        testimonials: null,
        meta: null,
        link: null,

        onPress: () => null,
    };

    onPress = () => {
        if (!!this.props.onPress) {
            this.props.onPress();
        }
    };

    render() {
        return (
            <div style={{ cursor: this.props.hidePointer ? "default" : "pointer" }} onClick={this.onPress}
                 className={classnames(styles.case_study_item_container, this.props.className)}>
                {!!this.props.image ? (
                    <img className={styles.case_study_image}
                         src={imageUrlFor(this.props.image).url()}
                         alt={this.props.imageAlt} />
                ) : null}
                <div className={styles.content_inner}>
                    <div className={styles.content_text}>
                        {!!this.props.title && <h4>{this.props.title}</h4>}
                        {!!this.props.excerpt && renderBlockText(this.props.excerpt)}
                        {!!this.props.meta ? <h5>{this.props.meta}</h5> : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect()(withRouter(ContentCardItem));
