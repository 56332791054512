import React, { Component } from "react";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";
import * as classnames from "classnames";
import BlogCardItem from "../../widgets/blogCardItem/BlogCardItem";
import styles from "./BlogItemsBlock.module.scss";
import ButtonBlock from "../buttonBlock/ButtonBlock";
import Routes from "../../../../util/routes";
import withRouter from "../../../../util/hooks/withRouter";

class BlogItemsBlock extends Component {
    static propTypes = {
        heading: PropTypes.string,
    };

    static defaultProps = {
        heading: "",
    };

    onViewMorePress = () => {
        this.props.navigate(Routes.blog.root);
    };

    render() {
        if (!this.props.blogPosts) {
            return null;
        }

        const buttons = [
            {
                buttonText: "View more",
                onPress: this.onViewMorePress,
                props: {}
            }
        ];

        return (
            <div className={classnames(styles.blog_items_container, this.props.className)}>
                {!!this.props.heading && <h2>{this.props.heading}</h2>}
                <div className={styles.blog_items_content}>
                    {this.props.blogPosts.map((blogPost) => {
                        return (
                            <BlogCardItem
                                key={uuid()}
                                className={this.props.blogClassname}
                                blogPost={blogPost}
                            />
                        );
                    })}
                </div>
                {this.props.hideButton ? null : (
                    <ButtonBlock
                        buttons={buttons}
                    />
                )}

            </div>
        );
    }
}

export default withRouter(BlogItemsBlock);
