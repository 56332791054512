import React, { useState } from "react";
import styles from "./VideoCard.module.scss"
import playButton from "../../../../resources/images/svg/playButton.svg"
import { Modal } from "@mui/material";

export const VideoCard = (props) => {

    const [ modalOpen, setModalOpen ] = useState(false);

    const onCardClick = () => {
        setModalOpen(true)
    }

    const closeModal = () => {
        setModalOpen(false)
    }

    const getIframeUrl = () => {
        const url = props.video
        if (!url) {
            return null;
        }
        const urlParts = url.split("/")
        return `https://player.vimeo.com/video/${urlParts[ 3 ]}?h=${urlParts[ 4 ]}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`
    }


    return (
        <>
            <div className={ styles.video_card } onClick={ onCardClick }>
                <img src={ props.thumbnail } className={ styles.thumbnail } />
                <div className={ styles.play }>
                    <img src={ playButton } className={ styles.play_button } />
                    <p>
                        Watch the video
                    </p>
                </div>

            </div>
            <Modal open={ modalOpen } onBackdropClick={ closeModal } className={ styles.light_box }>
                <iframe
                    src={ getIframeUrl() }
                    frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                >
                </iframe>
            </Modal>
        </>
    )
}