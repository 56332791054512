import React, { Component } from "react";
import Helmet from "react-helmet/es/Helmet";
import { connect } from "react-redux";
import * as classnames from "classnames";
import { pageReceived } from "../../../redux/actions/pages";
import { renderBlockText } from "../../../util/util";
import PreFooter from "../../common/blocks/preFooter/PreFooter";
import FullPageLoader from "../../common/widgets/loaders/fullPageLoader/FullPageLoader";
import Routes from "../../../util/routes";
import { SeoPages } from "../../../util/seoConstants";
import styles from "./PrivacyPolicy.module.scss";
import withRouter from "../../../util/hooks/withRouter";
import { FirebaseContext } from "../../../context/firebase";

class PrivacyPolicy extends Component {
    static contextType = FirebaseContext;

    constructor(props) {
        super(props);
        const query = `*[_type == "policyPage" && slug.current == "/privacy-policy"]{
        _id,
        heading,
        content,
        preFooter,
        }[0]`;

        this.state = {
            loading: true
        };

        window.sanity.fetch(query).then((page) => {
            this.props.dispatch(pageReceived({ "policyPage": page }));
            this.setState({ loading: false });
        });
    }

    onJoinTodayPress = () => {
        window.dataLayer.push({"event": "clicked_donate"})
        this.props.navigate(Routes.donationTypeSelect);
    };

    onContactUsPress = () => {
        this.props.navigate(Routes.contactUs);
    };


    render() {
        if (this.state.loading) {
            return (
                <FullPageLoader />
            );
        }

        // const buttons = [
        //     {
        //         buttonText: "Donate",
        //         onPress: this.onJoinTodayPress,
        //         props: {}
        //     },
        //     {
        //         buttonText: "Contact us",
        //         onPress: this.onContactUsPress,
        //         props: { outline: true }
        //     },
        // ];

        const page = this.props.page;

        return (
            <>
                <Helmet>
                    <meta property={"title"} content={SeoPages.policies.title} />
                    <meta property={"description"} content={SeoPages.policies.description} />
                    <meta property={"og:title"} content={SeoPages.policies.ogTitle} />
                    <meta property={"og:description"} content={SeoPages.policies.ogDescription} />
                    <meta property={"og:image"} content={SeoPages.policies.ogImage} />
                    <meta property={"og:url"} content={`${process.env.REACT_APP_WEB_URL}/privacy-policy`} />
                    <title>{SeoPages.policies.title}</title>
                </Helmet>
                <div className={classnames(styles.content_container, styles.policy_container)}>
                    <div className={styles.main_inner}>
                        <div className={styles.content_inner}>
                            <h1>{page.heading}</h1>
                            {!!page.content && renderBlockText(page.content)}
                        </div>
                    </div>
                    <PreFooter />
                </div>
            </>
        );
    }
}


export default connect((state) => {
    return {
        page: state.pages.policyPage,
    };
})(withRouter(PrivacyPolicy));
