import React, { Component } from "react";
import { imageUrlFor, renderBlockText } from "../../../util/util";
import AnnualReviewCover21 from "../../../resources/images/png/report-cover-2021.png";
import AnnualReviewCover22 from "../../../resources/images/png/report-cover.png";
import AnnualReviewCover23 from "../../../resources/images/png/report-cover-23.png";
import AnnualReviewCover24 from "../../../resources/images/png/report-cover-24.png";
import FullPageLoader from "../../common/widgets/loaders/fullPageLoader/FullPageLoader";
import Helmet from "react-helmet/es/Helmet";
import HeroBlock from "../../common/blocks/heroBlock/HeroBlock";
import PreFooter from "../../common/blocks/preFooter/PreFooter";
import { SeoPages } from "../../../util/seoConstants";
import TeamMemberBlock from "../../common/blocks/teamMemberBlock/TeamMemberBlock";
import TrioImageText from "../../common/widgets/trioImageText/TrioImageText";
import { connect } from "react-redux";
import { pageReceived } from "../../../redux/actions/pages";
import styles from "./AboutUs.module.scss";
import withRouter from "../../../util/hooks/withRouter";
import classNames from "classnames";

class AboutUs extends Component {
    constructor(props) {
        super(props);
        const query = `*[_type == "about"]{
        _id,
        title,
        hero,
        introHeading,
        introBody,
        ourMission,
        ourVision,
        membersHeading,
        aboutLogosBlock,
        "teamMembers":teamMembers[]->,
        preFooter,
        }[0]`;

        this.state = {
            loading: true
        };

        window.sanity.fetch(query).then((page) => {
            this.props.dispatch(pageReceived({ "about": page }));
            this.setState({ loading: false });
        });
    }

    render() {
        if (this.state.loading) {
            return (
                <FullPageLoader />
            );
        }
        const page = this.props.page;
        const hero = page.hero;
        // const preFooter = page.preFooter;
        const ourMission = page.ourMission || {};
        const ourVision = page.ourVision || {};
        const aboutLogosBlock = page.aboutLogosBlock;
        const teamMembers = page.teamMembers || [];

        return (
            <>
                <Helmet>
                    <meta property={ "title" } content={ SeoPages.aboutUs.title } />
                    <meta property={ "description" } content={ SeoPages.aboutUs.description } />
                    <meta property={ "og:title" } content={ SeoPages.aboutUs.ogTitle } />
                    <meta property={ "og:description" } content={ SeoPages.aboutUs.ogDescription } />
                    <meta property={ "og:image" } content={ SeoPages.aboutUs.ogImage } />
                    <meta property={ "og:url" } content={ `${process.env.REACT_APP_WEB_URL}/about-us` } />
                    <title>{ SeoPages.aboutUs.title }</title>
                </Helmet>
                <div className={ styles.about_us_container }>
                    <HeroBlock
                        className={ styles.about_hero }
                        heading={ hero.heading }
                        subheading={ hero.subheading } />
                    <div className={ styles.main_inner }>
                        <h2>{ page.introHeading }</h2>
                        <h4>{ page.introBody }</h4>
                        <div className={ styles.about_us_content }>
                            <TrioImageText
                                itemIndex={ 0 }
                                image={ imageUrlFor(ourMission.image) }
                                heading={ ourMission.heading }
                                subheading={ ourMission.subheading }
                                content={ renderBlockText(ourMission.content) }
                            />
                            <TrioImageText
                                itemIndex={ 1 }
                                image={ imageUrlFor(ourVision.image) }
                                heading={ ourVision.heading }
                                subheading={ ourVision.subheading }
                                content={ renderBlockText(ourVision.content) } />
                        </div>
                        <div className={ styles.team_container }>
                            <h2>{ page.membersHeading }</h2>
                            <TeamMemberBlock
                                teamMembers={ teamMembers }
                            />
                        </div>
                        <div className={ styles.annual_report_container } id={ "annual-review" }>
                            <h2>Our Annual Review</h2>
                            <div className={ styles.annual_reviews }>
                                <a href={ "/annual-report" } rel={ "noopener noreferrer" } >
                                    <img src={ AnnualReviewCover24 } alt={ "SMS Annual Review 2024" } />
                                </a>
                            </div>
                        </div>
                        <div className={ styles.annual_report_container } id={ "annual-review" }>
                            <h2>Our Previous Annual Reviews</h2>
                            <div className={ classNames(styles.annual_reviews, styles.past_reviews) }>
                                <a href={ "/annual-report-22-23" } rel={ "noopener noreferrer" } >
                                    <img src={ AnnualReviewCover23 } alt={ "SMS Annual Review 2023" } />
                                </a>
                                <a href={ "/annual-report-21-22" } rel={ "noopener noreferrer" } >
                                    <img src={ AnnualReviewCover22 } alt={ "SMS Annual Review 2022" } />
                                </a>
                                <a href={ "https://cdn.sanity.io/files/e9x53att/production/f41b82a95414ebca8c146fe2586548af1cf28ca2.pdf" } target="_blank" rel={ "noopener noreferrer" } >
                                    <img src={ AnnualReviewCover21 } alt={ "SMS Annual Review 2021" } />
                                </a>
                            </div>

                        </div>


                    </div>
                    <PreFooter />
                </div>
            </>
        );
    }
}

export default connect((state) => {
    return {
        page: state.pages.about,
    };
})(withRouter(AboutUs));
