/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-08-08.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";
import * as UsersAPI from "./api/user";
import { showError, showSuccess } from "./snackbars";

export const SET_CARDS = "cards:SET_CARDS";
export const SET_CARD = "cards:SET_CARD";

export const setCards = createAction(SET_CARDS);
export const setCard = createAction(SET_CARD);

export const addCardToUser = wrapper(UsersAPI.addCardToUserAPI, (dispatch, card) => {
    dispatch(showSuccess("Successfully added payment details"));
    dispatch(setCard(card));
}, (dispatch) => {
    dispatch(showError("Failed to add payment details"));
});

export const updateUserCard = wrapper(UsersAPI.updateUserCardAPI, (dispatch, card) => {
    dispatch(setCard(card));
});

export const getUserCards = wrapper(UsersAPI.getUserCardsAPI, (dispatch, cards) => {
    dispatch(setCards(cards));
});

export const deleteCardFromUser = wrapper(UsersAPI.deleteUserCardAPI, (dispatch, cards) => {
    dispatch(setCards(cards));
});
