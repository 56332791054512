import React, { Component } from "react";
import styles from "./MediaArticleCard.module.scss";
import PropTypes from "prop-types";
import { renderBlockText } from "../../../../../util/util";

class MediaArticleCard extends Component {
    static propTypes = {
        className: PropTypes.string,
        meta: PropTypes.string,

        heading: PropTypes.string,
        subheading: PropTypes.string,
        publisher: PropTypes.string,
        content: PropTypes.any,
        onClick: PropTypes.any,
        image: PropTypes.any,

    };

    static defaultProps = {
        className: null,
        meta: null,
        heading: null,
        publisher: null,
        content: null,
        subheading: null,
        image: null,
        onClick: null,

    };

    onClick = () => {
        if (this.props.href) {
            window.open(this.props.href, "_blank");
        }
    };


    render() {
        return (
            <div className={styles.media_article_card} onClick={this.onClick}>
                <div className={styles.media_intro}>
                    <div className={styles.date}>{this.props.subheading}</div>
                    {this.props.image && <img src={this.props.image} alt={this.props.meta} />}
                </div>
                <div className={styles.media_content}>
                    <h4>{this.props.heading}</h4>
                    <span>{this.props.publisher}</span>
                    <div> {!!this.props.content && renderBlockText(this.props.content)}</div>
                    <div className={styles.read_more}>Read more</div>
                </div>
            </div>
        );
    }
}

export default MediaArticleCard;
