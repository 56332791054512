import classnames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import styles from "./Snackbar.module.scss";
import { SnackbarContent, Snackbar as MatSnackbar, IconButton, Fade } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";

export default class Snackbar extends Component {

    static propTypes = {
        className: PropTypes.string,
        classes: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array
        ]),

        snackbarShown: PropTypes.bool,
        autoHideDuration: PropTypes.number,
        onClose: PropTypes.func,

        config: PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
            ]),
            text: PropTypes.string,
            success: PropTypes.bool,
            error: PropTypes.bool,
            warning: PropTypes.bool
        })
    };

    static defaultProps = {
        className: null,
        classes: [],

        snackbarShown: false,
        autoHideDuration: 4000,
        onClose: () => null,

        config: {
            id: null,
            icon: null,
            text: null,
            success: false,
            error: false,
            warning: false,
            disableAutoHide: false
        }
    };

    onClose = () => {
        this.props.onClose(this.props.config);
    };

    render() {

        const config = this.props.config || {};
        const className = classnames(styles.snackbar_content, {
            [styles.success]: config.success,
            [styles.error]: config.error,
            [styles.warning]: config.warning
        }, this.props.className, this.props.classes);

        let Icon = null;

        if (!!config.success) {
            Icon = CheckCircleIcon;
        } else if (!!config.warning) {
            Icon = WarningIcon;
        } else {
            Icon = InfoIcon;
        }

        if (!!config.icon) {
            Icon = config.icon;
        }

        return (
            <MatSnackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                className={styles.snackbar}
                open={this.props.snackbarShown}
                TransitionComponent={Fade}
                ClickAwayListenerProps={{
                    touchEvent: false,
                    mouseEvent: false
                }}
                autoHideDuration={!!config.duration ? config.duration : this.props.autoHideDuration}
                onClose={this.onClose}
                key={this.props.config.id}
            >
                <SnackbarContent
                    className={className}
                    aria-describedby="client-snackbar"
                    message={(
                        <span id="client-snackbar" className={styles.snackbar_content_message}>
                            {!!Icon ? <Icon /> : null}
                            <span>{config.text}</span>
                        </span>
                    )}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.onClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
            </MatSnackbar>
        );
    }
}
