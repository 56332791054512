import React, { Component } from "react";
import { connect } from "react-redux";
import { filter } from "lodash/collection";
import Helmet from "react-helmet/es/Helmet";
import styles from "./CharitiesHome.module.scss";
import SearchHeroBlock from "../../common/blocks/SearchHeroBlock/SearchHeroBlock";
import PreFooter from "../../common/blocks/preFooter/PreFooter";
import { CharityRegions } from "../../../util/charityRegions";
import FullPageLoader from "../../common/widgets/loaders/fullPageLoader/FullPageLoader";
import Routes from "../../../util/routes";
import { SeoPages } from "../../../util/seoConstants";
import Hand from "../../../resources/images/png/heart_hand.png";
import { pageReceived } from "../../../redux/actions/pages";
import { arrayMove, imageUrlFor } from "../../../util/util";
import { FirebaseContext } from "../../../context/firebase";
import OldCharityCardItem from "../../common/widgets/charityCardItem/OldCharityCardItem";
import { VideoCard } from "../../common/widgets/videoCard/VideoCard";
import thumbnail from "../../../resources/images/png/charities-video-thumbnail.png";

class CharitiesHome extends Component {
    static contextType = FirebaseContext;

    constructor(props) {
        super(props);

        const query = `*[_type == "charity"]{
        _id,
        id,
        name,
        card,
        } | order(name, asc)`;

        window.sanity.fetch(query).then((page) => {
            this.props.dispatch(pageReceived({ "charity": page }));
            this.setState({ loading: false });
        });

        this.state = {
            loading: true,
        };
    }

    getOptions = () => {
        const options = [ <option key={ 0 } value={ "nationwide" }>All</option> ];
        Object.values(CharityRegions).map((region) => {
            options.push(<option key={ region.value } value={ region.value }>{ region.label }</option>);
            return null;
        });
        return options;
    };

    onSearchSubmit = (values) => {
        let filteredCharities = [];

        if (values.search) {
            Object.values(this.props.charities).map((charity) => {
                if (charity.name.toLowerCase().indexOf(values.search.toLowerCase()) > -1 && filteredCharities.indexOf(charity) === -1) {
                    filteredCharities.push(charity);
                }
                return null;
            });
        }

        if (values.search && values.filter) {
            filteredCharities = filter(filteredCharities, (charity) => charity.regions.indexOf(values.filter) > -1);
        }

        if (!values.search && values.filter) {
            Object.values(this.props.charities).map((charity) => {
                if (charity.regions.indexOf(values.filter) > -1 && filteredCharities.indexOf(charity) === -1) {
                    filteredCharities.push(charity);
                }
                return null;
            });
        }


        this.setState({ filteredCharities: filteredCharities });
    };

    onClearPress = () => {
        this.setState({ filteredCharities: Object.values(this.props.charities) });
    };

    onViewMorePress = () => {
        this.props.navigate(Routes.charities.root);
    };

    onDonateNowPress = () => {
        window.dataLayer.push({ "event": "clicked_donate" })
        this.props.navigate(Routes.donationTypeSelect);
    };

    renderCharityCards = () => {
        const orderedCharities = this.props.charities.map((charity) => (
            <OldCharityCardItem
                charity={ charity }
                key={ charity._id }
                image={ imageUrlFor(charity.card.image) }
                heading={ charity.card.heading }
                content={ charity.card.blurb }
                hero={ charity.card.hero } />
        ));

        let heroIndex = null;

        orderedCharities.forEach((card, i) => {
            if (card.props.hero) {
                heroIndex = i;
            }
        });

        arrayMove(orderedCharities, heroIndex, 3);

        return orderedCharities;
    }

    render() {
        if (this.state.loading) {
            return (
                <FullPageLoader />
            );
        }

        return (
            <>
                <Helmet>
                    <meta property={ "title" } content={ SeoPages.charities.title } />
                    <meta property={ "description" } content={ SeoPages.charities.description } />
                    <meta property={ "og:title" } content={ SeoPages.charities.ogTitle } />
                    <meta property={ "og:description" } content={ SeoPages.charities.ogDescription } />
                    <meta property={ "og:image" } content={ SeoPages.charities.ogImage } />
                    <meta property={ "og:url" } content={ `${process.env.REACT_APP_WEB_URL}/charities` } />
                    <title>{ SeoPages.charities.title }</title>
                </Helmet>
                <div className={ styles.charities_home_container }>
                    <SearchHeroBlock
                        hideSearch
                        className={ styles.charity_home_hero }
                        heading={ "Choose where you’d like to put your support." }
                        subheading={ "When you give through Share My Super, you can split your single donation amongst a mix of New Zealand’s most effective children’s charities, supporting Kiwi kids living in poverty in a variety of critical ways. We call this the ‘whole of child’ approach. Watch the video to see how your donation can make a broader impact, by lifting up our kids and lifting up our country." }
                    />
                    <div className={ styles.charity_info_container }>
                        <div className={ styles.charity_info_inner }>
                            <div className={ styles.charity_info }>
                                <img src={ Hand } alt={ "Hand with a heart inside" } />
                                <div>
                                    <div className={ styles.charity_info_header }>
                                        Focused on ending inequity for kids
                                    </div>
                                </div>

                            </div>
                            <div className={ styles.charity_info }>
                                <img src={ Hand } alt={ "Hand with a heart inside" } />
                                <div className={ styles.charity_info_header }>Chosen for their effectiveness</div>
                            </div>
                            <div className={ styles.charity_info }>
                                <img src={ Hand } alt={ "Hand with a heart inside" } />
                                <div className={ styles.charity_info_header }>Monitored and endorsed by us</div>
                            </div>
                        </div>

                    </div>
                    <div className={ styles.video }>
                        <VideoCard thumbnail={ thumbnail } video={ "https://vimeo.com/955722882" } />
                    </div>
                    <div className={ styles.main_inner }>

                        <div className={ styles.charities_home_content }>
                            { this.state.loading ? <h3>Loading...</h3> : this.renderCharityCards() }
                        </div>
                    </div>
                    <PreFooter />
                </div>
            </>
        );
    }
}

export default connect((state) => {
    return {
        charities: state.pages.charity
    };
})(CharitiesHome);
