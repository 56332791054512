import React from "react";
import * as classnames from "classnames";
import PropTypes from "prop-types";
import styles from "./HeroBlock.module.scss";
import ButtonBlock from "../buttonBlock/ButtonBlock";

export default function HeroBlock(props) {

        return (
            <div className={classnames(styles.hero_block_container, props.className)}>
                <div className={styles.hero_block_content}>
                    {!!props.heading && <h1>{props.heading}</h1>}
                    {!!props.subheading && <p>{props.subheading}</p>}
                    {props.buttons && props.buttons.length > 0 && (
                        <ButtonBlock
                            className={styles.hero_buttons}
                            buttons={props.buttons}
                        />
                    )}
                </div>
            </div>
        );
}

HeroBlock.propTypes = {
    className: PropTypes.string,
    heading: PropTypes.string,
    subheading: PropTypes.string,
    preheading: PropTypes.string,
    backgroundImage: PropTypes.any,
};

HeroBlock.defaultProps = {
    className: null,
    heading: null,
    subheading: null,
    preheading: null,
    backgroundImage: null,
};

