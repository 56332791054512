import * as Yup from "yup";

import {Field, Form, Formik} from "formik";
import React, {useRef, useState} from "react";

import Button from "../../common/widgets/button/Button";
import FormikCheckbox from "../../common/widgets/formikCheckbox/FormikCheckbox";
import TextInput from "../../common/widgets/input/textInput/TextInput";
import styles from "./LandingPage.module.scss";
import {submitContact} from "../../../redux/actions/api/contact";

const QuestionsFormSchema = Yup.object().shape({
    firstName: Yup.string()
        .required("Required"),
    lastName: Yup.string()
        .required("Required"),
    email: Yup.string()
        .email("Invalid email")
        .required("Required"),
    questions: Yup.string()
});

export const QuestionsForm = (props) => {
    const formRef = useRef();
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [checkBox, setCheckBox] = useState(false)
    const initValues = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        questions: "",
        optIn: true
    }
    const onSubmit = async (values) => {
        setLoading(true)

        window.dataLayer.push({"event": "Webinar registration"})

        try {
            await submitContact(values, "webinar");
            setSuccess(true)
            formRef.current.handleReset();

        } catch (e) {
            console.log(e)
        }

        setLoading(false)


    }
    return (
        <div>
            <Formik onSubmit={onSubmit}
                    innerRef={formRef}
                    validationSchema={QuestionsFormSchema}
                    initialValues={initValues}
            >
                {({handleSubmit, handleChange, isValid}) => {
                    return (
                        <Form>
                            <div>
                                <Field name={"firstName"}
                                       label={"*First Name"}
                                       component={TextInput}
                                       disabled={success}

                                       inputClassName={styles.input}
                                       className={styles.wrapper}
                                       labelClassName={styles.label}
                                       onChange={handleChange}/>
                                <Field name={"lastName"}
                                       label={"*Last Name"}
                                       disabled={success}

                                       inputClassName={styles.input}
                                       className={styles.wrapper}
                                       labelClassName={styles.label}
                                       component={TextInput}
                                       onChange={handleChange}/>
                            </div>
                            <div>
                                <Field name={"email"}
                                       label={"*Email"}
                                       disabled={success}

                                       component={TextInput}
                                       inputClassName={styles.input}
                                       className={styles.wrapper}
                                       labelClassName={styles.label}
                                       onChange={handleChange}/>
                                <Field name={"phone"}
                                       label={"Contact number"}
                                       disabled={success}

                                       component={TextInput}
                                       inputClassName={styles.input}
                                       className={styles.wrapper}
                                       labelClassName={styles.label}
                                       onChange={handleChange}/>
                            </div>
                            <div>
                                <Field name={"questions"}
                                       label={"Questions for Liz"}
                                       disabled={success}

                                       inputComponent={"textarea"}
                                       inputClassName={styles.input}
                                       className={styles.wrapper}
                                       labelClassName={styles.label}
                                       component={TextInput}
                                       onChange={handleChange}/>
                            </div>
                            <div className={styles.opt_in}>
                                <Field name={"optIn"}
                                       disabled={success}

                                       id={"optIn"}
                                       component={FormikCheckbox}/>
                                <span>Opt in to receive email updates from us</span>

                            </div>

                            {/*<Field name={"optIn"} onChange={(v)=>setCheckBox(v)} value={checkBox} id={"checkbox"} label={"Opt-in to recieve email updates from me."} component={<FormikCheckbox />}/>*/}
                            <div className={styles.submit}>
                                {success ? <div className={styles.success}>
                                        <p>
                                            Thanks for registering. Please keep an eye on your inbox for an email from Zoom
                                            with meeting details within the next day or two.
                                        </p>
                                    </div> :
                                    <Button text={"Send"}
                                            formSubmit
                                            disabled={!isValid}
                                            darkgreen
                                            loading={loading}
                                            onPress={handleSubmit}/>
                                }
                            </div>


                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}
