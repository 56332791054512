
/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-08-08.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import wrapper from "./helpers/wrapper";
import * as UsersAPI from "./api/user";
import createAction from "./helpers/createAction";

export const SET_TRANSACTIONS = "transactions:SET_TRANSACTIONS";
export const CLEAR_TRANSACTIONS = "transactions:CLEAR_TRANSACTIONS";
export const setTransactions = createAction(SET_TRANSACTIONS);
export const clearTransactions = createAction(CLEAR_TRANSACTIONS);

export const getUserTransactions = wrapper(UsersAPI.getUserTransactionsAPI, (dispatch, r) => {
    dispatch(setTransactions(r));
});
