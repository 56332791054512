import React, { Component } from "react";
import { connect } from "react-redux";
import { renderBlockText } from "../../../../util/util";
import styles from "./BenefitItem.module.scss";
import Placeholder from "../../../../resources/images/svg/placeholderBenefitTick.svg";
import withRouter from "../../../../util/hooks/withRouter";

class BenefitItem extends Component {

    render() {
        if (!this.props.benefit) {
            return null;
        }
        const benefit = this.props.benefit;

        return (
            <div className={styles.benefit_item_container}>
                <img src={Placeholder} alt={"Share my Super benefits"}/>
                <div className={styles.benefit_item_content}>
                    <h4>{benefit.heading}</h4>
                    {!!benefit.blurb && renderBlockText(benefit.blurb)}
                </div>

            </div>
        );
    }
}

export default connect(() => {
    return {};
})(withRouter(BenefitItem));
