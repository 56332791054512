import React from "react";
import qs from "qs";
import styles from "./EofyReceipt.module.scss";

export default function TransactionalReceipt() {
    const {
        charityAddress,
        charityName,
        charityNumber,
        charityRepName,
        charityRepTitle,
        donationDate,
        donationTotal,
        receiptNumber,
        donorFullName,
        donorId,
        gstNumber,
        receiptDate,
        logoUrl,
        signatureUrl,
        websiteUrl,
    } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    return (
        <div className={styles.receipt_container}>
            <div className={styles.receipt_header}>
                <div className={styles.charity_logo}>
                    <img src={logoUrl} alt={""}/>
                </div>
                <div className={styles.charity_information}>
                    <p><strong>{charityName}</strong></p>
                    <p>{charityAddress}</p>
                    <p>Charities No. {charityNumber}</p>
                    <p><a href={websiteUrl}>{websiteUrl}</a></p>
                </div>
            </div>
            <div className={styles.receipt_intro}>
                <p>{receiptDate}</p>
                <p>{`Donor ID: ${donorId}`}</p>
                <p><strong>Tax Certificate for {donationDate}</strong></p>
                <p>Dear {donorFullName},</p>
                <p>Thank you for your support of {charityName}.</p>
                <p>Thanks to you we can continue our work to improve the lives of New Zealand children, rangatahi,
                    their families and whanau.
                </p>
                <p>This tax certificate covers the donation to {charityName} through the Share My Super platform
                    on {donationDate}.
                </p>
                <p><strong>Total donated:</strong> {donationTotal}<br/>
                    <strong>Receipt no:</strong> {receiptNumber}
                </p>
            </div>
            <div className={styles.receipt_secondary}>
                <p><strong>(Charity name) IRD number:</strong> {gstNumber}
                    <br/><strong>Charities no:</strong> {charityNumber}
                    <br/><strong>Received from:</strong> {donorFullName}
                </p>
                <p>Yours sincerely,</p>
                <img src={signatureUrl} alt={""}/>
                <p>{charityName}</p>
                <p>{charityRepName}, {charityRepTitle}</p>
            </div>
        </div>
    )
}
