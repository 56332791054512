/* eslint-disable */
import React, {Component} from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import styles from "./Button.module.scss";
import Spinner from "../spinner/Spinner";

class Button extends Component {
    static propTypes = {
        className: PropTypes.string,
        onPress: PropTypes.func,
        loading: PropTypes.bool,
        disabled: PropTypes.bool,
        clearLink: PropTypes.bool,
        clearLinkWhite: PropTypes.bool,
        text: PropTypes.string,
        children: PropTypes.any,
        type: PropTypes.string,
    };

    static defaultProps = {
        className: "",
        loading: false,
        disabled: false,
        onPress: () => null,
        type: "button",
    };

    onPress = () => {
        if (!!this.props.onPress) {
            this.props.onPress();
        }
    };

    renderText = () => {
        if (this.props.loading) {
            return <Spinner hCenter color={"#FFFFFF"}/>
        }

        if (this.props.children) {
            return this.props.children
        } else {
            return this.props.text
        }
    };

    render() {
        const className = classnames(styles.button, {
            [styles.disabled]: this.props.disabled,
            [styles.form_submit]: this.props.formSubmit,
            [styles.clear_link]: this.props.clearLink,
            [styles.clear_link_white]: this.props.clearLinkWhite,
            [styles.turquoise]: this.props.turquoise,
            [styles.orange]: this.props.orange,
            [styles.green]: this.props.green,
            [styles.dark_green]: this.props.darkgreen,
            [styles.white]: this.props.white,
            [styles.outline]: this.props.outline,
            [styles.small]: this.props.small

        }, this.props.className,);

        return (
            <button className={className}
                    id={this.props.id}
                    onClick={this.onPress}
                    disabled={this.props.disabled || this.props.loading}
                    type={this.props.type ? this.props.type : "button"}>
                {this.renderText()}
            </button>
        );
    }
}

export default Button;
