import React, { Component } from "react";
import styles from "./HowWeMadeOurCommercial.module.scss";
import Cute from "../../../resources/images/png/commercial_baby_1.jpg";
import Conveyor from "../../../resources/images/png/commercial_baby_conveyor.jpg";
import Green1 from "../../../resources/images/png/commercial_baby_2.jpg";
import Green2 from "../../../resources/images/png/commercial_baby_3.jpg";
import Green3 from "../../../resources/images/png/commercial_baby_4.jpg";
import PreFooter from "../../common/blocks/preFooter/PreFooter";

class HowWeMadeOurCommercial extends Component {

    render() {
        return (
            <div className={styles.how_we_made_the_ad_container}>
                <div className={styles.main_inner}>
                    <div className={styles.intro_block}>
                        <h1>How we made our commercial</h1>
                        <h3>Our new commercial has generated lots of conversation all around New Zealand.
                        </h3>
                        We understand it is uncomfortable for some people to watch. Our intention is to bring attention
                        to the alarming rate of child poverty in New Zealand, and to demonstrate that we all come into
                        the world the same and have no choice in being born into poverty.
                    </div>
                    <div className={styles.video}>
                        <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                            <iframe src={"https://player.vimeo.com/video/391883219?title=0&byline=0&portrait=0"}
                                    frameBorder="0"
                                    title={"How we made our commercial"}
                                    allow="autoplay; fullscreen"
                                    allowFullScreen>

                            </iframe>
                        </div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                    </div>
                    <div>
                        <h4>We want to share how we made the commercial, and the fun our babies and families had with us
                            creating it.</h4>
                    </div>
                    <div className={styles.video}>
                        <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
                            <iframe src={"https://www.youtube.com/embed/TyGO2Q8nEzA"}
                                    frameBorder="0"
                                    title={"How we made our commercial - 1"}
                                    allow="autoplay; fullscreen"
                                    allowFullScreen>

                            </iframe>
                        </div>
                    </div>
                    <div className={styles.main_content}>
                        <h2>The magic of film making</h2>
                        <h4>Location one: The factory</h4>
                        <p>This commercial was created in two halves, in two very different locations.</p>

                        <p>The first location was the huge industrial space. Use of this factory was offered for free
                            because the owner felt this was such an important cause.</p>

                        <p>No babies were filmed here. Instead we used lifelike dolls placed in trays and dressed the
                            same way our real babies would be.</p>

                        <p> In the factory we filmed many different pieces of conveyor belts and machinery, which we
                            would use to create our CGI baby factory world. This world was then down to masterful and
                            very talented computer special effects experts to develop and piece together the factory you
                            see in the commercial.
                        </p>
                        <div className={styles.green_images}>
                            <img src={Cute} alt={""}/>
                            <img src={Conveyor} alt={""}/>
                        </div>
                        <h4>Location two: A green screen film studio in Ponsonby.</h4>
                        <p>Location two is a film studio in Ponsonby. It’s a very warm and comfortable environment. All
                            the babies and their mums had a lovely room to relax in until it was their turn to be
                            filmed. We provided delicious food and a barista was on hand to serve the mums and dads
                            anything they needed.
                        </p>

                        <p>Our 20 volunteer babies were with their parents at all times. Even when we filmed, they were
                            no more than a few feet away.
                        </p>

                        <p>The babies wore little merino wool leggings, were on a soft blanket, with another blanket
                            over them and had a hidden safety harness securing them to the tray. Which in turn was
                            secured to the table.
                        </p>

                        <p> We then filmed the babies in the studio, on the same angles as the conveyor belts.
                        </p>
                        <p>We filmed 20 babies in all and they enjoyed it so much, our greatest challenge was waiting
                            for them to stop smiling.
                        </p>
                        <p>No baby was filmed for longer than 5 minutes as we wanted both the babies and their parents
                            to feel relaxed and comfortable.
                        </p>
                        <p>Once we had all the pieces, our CGI experts began creating our baby factory, by placing the
                            footage of our real babies, filmed in the studio, onto the conveyor belts of the factory we
                            created on a computer.
                        </p>
                        <p>It looks lifelike because it needed to. But in reality it was down to the very considered
                            planning in very controlled environments and extraordinary computer graphics.
                        </p>
                        <p>The end result can make you feel uncomfortable, because one in four children feel that every
                            day.
                        </p>
                        <div className={styles.green_images}>
                            <img src={Green1} alt={""}/>
                            <img src={Green3} alt={""}/>
                        </div>
                        <p>After filming, we took professional family portraits for all our babies and families as a
                            thank you to them.
                        </p>
                        <p>The voiceover you hear in the commercial is voiced by Share My Super founder Liz Greive’s
                            grad daughter.
                        </p>
                        <img src={Green2} alt={""}/>

                    </div>
                </div>
                <PreFooter />

            </div>
        );
    }
}

export default HowWeMadeOurCommercial;
