import React, { useState } from "react";
import { connect } from "react-redux";
import Sticky from "react-stickynode";
import styles from "../charitySelection/CharitySelection.module.scss";
import Charity from "../charity/Charity";
import Select from "../../common/widgets/select/Select";
import withRouter from "../../../util/hooks/withRouter";

function CharitiesViewMobile(props) {
    const { charities } = props;
    const [selectedCharity, setSelectedCharity] = useState(props.charityId);

    const getOptions = () => {
        return Object.values(charities).map((charity) => ({
            value: charity.id, label: charity.name
        }));
    };

    const onSelectChange = (e) => {
        setSelectedCharity(e.target.value);
    };

    return (
        <div className={styles.charities_selection_content} id={"charity"}>
            <Sticky enabled top={0} bottom={50} bottomBoundary={"#charity"} className={styles.sticky_mobile}>
                <Select id={"charities"}
                        options={getOptions()}
                        initialValue={selectedCharity}
                        onChange={onSelectChange}
                        className={styles.dropdown_menu} />
            </Sticky>

            <div className={styles.charities}>
                <Charity charity={props.charities[selectedCharity]} />
            </div>
        </div>
    );
}

export default connect()(withRouter(CharitiesViewMobile));
