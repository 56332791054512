import React from "react";
import styles from "./VideoBlock.module.scss"
import { getCmsButtons, imageUrlFor, renderBlockText } from "../../../../util/util";
// import { VideoCard } from "../../widgets/videoCard/VideoCard";
import ButtonBlock from "../buttonBlock/ButtonBlock";
import checkWithTick from "../../../../resources/images/png/checkWithTick.png"
export const VideoBlock = (props) => {
    const videoBlock = props.videoBlock;
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.video_wrapper}>
                    {/*<VideoCard thumbnail={imageUrlFor(videoBlock.videoThumbnail)} video={videoBlock.videoUrl}/>*/}
                    <img src={imageUrlFor(videoBlock.leftImage)} />
                </div>

                <div className={styles.right}>
                    <h2>
                        {renderBlockText(videoBlock.heading)}
                    </h2>
                    <p>
                        {renderBlockText(videoBlock.subheading)}
                    </p>
                    {(videoBlock.points||[]).map((p)=>(
                        <div className={styles.points} key={p}>
                            <img src={checkWithTick}/>
                            <p>
                                {p}
                            </p>
                        </div>
                    ))}
                    <span></span>

                    <ButtonBlock buttons={getCmsButtons(videoBlock.buttons)} className={styles.buttons} />
                </div>
            </div>
        </div>
    )
}
