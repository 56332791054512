import React from "react";
import styles from "../../../pages/landingPage/LandingPage.module.scss";
import { imageUrlFor, renderBlockText } from "../../../../util/util";


export const AnimatedCard = (props) => {

    const { heading, description, image, number } = props;


    return (
        <div className={ styles.card }>
            <div className={ styles.top }>
                <h1>{ number }</h1>
                {/*<img src={imageUrlFor(image)}/>*/ }
                <h4>
                    { heading }
                </h4>
            </div>
            <div className={ styles.bottom }>
                { renderBlockText(description) }

            </div>
        </div>
    )
}

export const CardBlock = (props) => {
    const { heading, subheading, cards } = props

    // let calc = 10
    // if(Math.floor(height/(scroll - offset)) > 0 && Math.floor(height/(scroll - offset)) < 10){
    //     calc = Math.floor(height/(scroll - offset));
    // }
    // console.log(calc)

    // visible={((5-i)*2 > calc)}


    return (
        <div className={ styles.cards_block } id={ "cards_block" }>
            <div className={ styles.inner }>
                <div className={ styles.text }>
                    <h2>
                        { heading }
                    </h2>
                    <p>
                        { subheading }
                    </p>
                </div>
                <div className={ styles.cards }>
                    { cards.map((c, i) => (
                        <AnimatedCard i={ i } heading={ c.heading } description={ c.subheading } image={ c.image }
                            number={ c.number }
                            key={ `card-${i}` } />
                    )) }

                </div>
            </div>
        </div>
    )
}