import createAction from "./helpers/createAction";
import wrapper from "./helpers/wrapper";

import * as UsersAPI from "./api/user";
import { showError, showSuccess } from "./snackbars";
import { getSubscription } from "./subscription";
import { setAuthLoading } from "./settings";

export const SET_USER = "user:SET_USER";

export const setUser = createAction(SET_USER);

export const getUser = wrapper(UsersAPI.getUserAPI, (dispatch, user) => {
    dispatch(setUser(user));
    dispatch(getSubscription({
        onSuccessOrError: () => dispatch(setAuthLoading(false))
    }));
});

export const updateUser = wrapper(UsersAPI.updateUserAPI, (dispatch, user) => {
    dispatch(setUser(user));
    dispatch(setAuthLoading(false));
});

export const deleteUser = wrapper(UsersAPI.deleteUserAPI);

export const subscribeEmailToMailChimpList = wrapper(UsersAPI.subscribeEmailToMailchimpListAPI, (dispatch) => {
    dispatch(showSuccess("Thanks for subscribing to our newsletter"));
}, (dispatch, error) => {
    dispatch(showError(error.message));
});
