export function getCharitiesAPI() {
    return window.get("/v1/charities");
}

export function getCharityReceiptInfoAPI(orgId) {
    return window.get(`/v1/organisations/${orgId}/receipt`);
}

export function getNominatedCharitiesAPI() {
    return window.get("/v1/charities/nominated");
}

export function getIp() {
    return window.get("/v1/web/ip");
}
