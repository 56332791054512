import * as Yup from "yup";
import * as classnames from "classnames";

import {Field, Form, Formik} from "formik";
import React, {useRef, useState} from "react";
import {createActiveCampaignContact, submitContact} from "../../../../redux/actions/api/contact";

import AddressInput from "../../widgets/input/addressInput/AddressInput";
import Button from "../../widgets/button/Button";
import FormikCheckbox from "../../widgets/formikCheckbox/FormikCheckbox";
import TextInput from "../../widgets/input/textInput/TextInput";
import {connect} from "react-redux";
import styles from "./HomeFormBlock.module.scss";
import withRouter from "../../../../util/hooks/withRouter";

const InfoFormScema = Yup.object().shape({
    firstName: Yup.string()
        .required("Required"),
    lastName: Yup.string()
        .required("Required"),
    email: Yup.string()
        .email("Invalid email")
        .required("Required"),
    address: Yup.string().when("postal", {
        is: true,
        then: (schema) => schema.required("Please enter an address")
    }),
});

const HomeFormBlock = (props) => {

    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const formRef = useRef();
    const [postal, setPostal] = useState(false);
    const initValues = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        address: "",
        postal: false,
        optIn: true
    }

    const onSubmit = async (values) => {
        setLoading(true)


        window.dataLayer.push({"event": "Info pack request"})
            
        try {
           
            await submitContact(values, values.postal? "PostInfoPack" : "DownloadInfoPack");
            setSuccess(true)
            formRef.current.handleReset();

        } catch (e) {
            console.log(e)
        }

        setLoading(false)


    }

    const onDonatePress = () => {

        props.navigate("/donate")
    }

    return (
        <div className={classnames(styles.pre_footer_container, props.className)}>
            <div className={styles.pre_footer_content}>
                <div className={styles.footer_left}>
                    <h3>Invest in our kids today and help secure a</h3><h1>bright future for New Zealand.</h1>
                    {/*<h4>*/}
                    {/*    Together we can create a future where every child has the chance to succeed, irrespective of*/}
                    {/*    their background or circumstances.</h4>*/}
                </div>
                <div className={styles.footer_right}>
                    {/*<Button onPress={onDonatePress}*/}
                    {/*        darkgreen*/}
                    {/*        className={styles.donate_button}*/}
                    {/*        text={"Donate"}/>*/}
                    <p>
                        Would you like to receive an information pack about Share My Super and our charity partners? We
                        can send you one in the post or by email. Simply fill in your details below.
                    </p>
                    <div className={styles.form}>
                        <Formik onSubmit={onSubmit}
                                innerRef={formRef}
                                validationSchema={InfoFormScema}
                                initialValues={initValues}
                        >
                            {({handleSubmit, handleChange, isValid, values}) => {
                                return (
                                    <Form>
                                        <div className={styles.checkbox_row}>
                                            <div className={styles.checkbox}>

                                                <Field name={"postal"}
                                                       id={"postal"}
                                                       onChange={()=>setPostal(!postal)}
                                                       component={FormikCheckbox}
                                                />

                                                <span>By Post</span>
                                            </div>
                                            {/*<div  className={styles.checkbox}>*/}
                                            {/*    <input type={"checkbox"} onChange={()=>setEmail(!postal)} checked={postal}/>*/}
                                            {/*    <span>By Email</span>*/}
                                            {/*</div>*/}
                                        </div>
                                        <div>
                                            <Field name={"firstName"}
                                                   label={"*First Name"}
                                                   component={TextInput}
                                                   inputClassName={styles.input}
                                                   className={styles.wrapper}
                                                   labelClassName={styles.label}
                                                   onChange={handleChange}/>
                                            <Field name={"lastName"}
                                                   label={"*Last Name"}
                                                   inputClassName={styles.input}
                                                   className={styles.wrapper}
                                                   labelClassName={styles.label}
                                                   component={TextInput}
                                                   onChange={handleChange}/>
                                        </div>


                                        <div>
                                            <Field name={"email"}
                                                   label={"*Email"}
                                                   component={TextInput}
                                                   inputClassName={styles.input}
                                                   className={styles.wrapper}
                                                   labelClassName={styles.label}
                                                   onChange={handleChange}/>
                                            <Field name={"phone"}
                                                   label={"Contact number"}
                                                   component={TextInput}
                                                   inputClassName={styles.input}
                                                   className={styles.wrapper}
                                                   labelClassName={styles.label}
                                                   onChange={handleChange}/>
                                        </div>

                                        <div className={styles.postal}>
                                            {values.postal &&
                                            <Field
                                                label={"*Postal Address"}
                                                name={"address"}
                                                component={AddressInput}
                                                inputClassName={styles.wrapper}
                                                labelClassName={styles.label}
                                            />
                                            }

                                        </div>
                                        <div className={styles.opt_in}>
                                            <Field name={"optIn"}
                                                   id={"optIn"}
                                                   component={FormikCheckbox}/>
                                            <span>Opt in to receive email updates from us</span>

                                        </div>
                                        <div className={styles.submit}>

                                            {success ? <div className={styles.success}>
                                                    {postal ? <p>
                                                                Thanks! Your information pack is on its way.
                                                            </p> :
                                                            <p>
                                                                Thanks for requesting our information pack. We've emailed it to you - if you
                                                                can't see it in your inbox, please check your spam folder.
                                                            </p>
                                                    }
                                                </div> :
                                                <Button text={"Send"}
                                                        formSubmit
                                                        disabled={!isValid}
                                                        darkgreen
                                                        loading={loading}
                                                        onPress={handleSubmit}/>
                                            }
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeFormBlock
