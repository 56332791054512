import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as classnames from "classnames";
import styles from "./Footer.module.scss";
import Facebook from "../../../../resources/images/svg/footerSocialPlaceholder.svg";
import Instagram from "../../../../resources/images/svg/footerSocialInstagram.svg";
import LinkedIn from "../../../../resources/images/svg/footerSocialLinkedIn.svg";
import Routes from "../../../../util/routes";
import { FirebaseContext } from "../../../../context/firebase";
import Button from "../../widgets/button/Button";

class Footer extends Component {
    static contextType = FirebaseContext;

    state = {
        loading: false
    };

    onDonatePress = () => {
        window.dataLayer.push({ "event": "clicked_donate" })
    };

    render() {
        const navigate = this.props.navigate
        return (
            <div className={ styles.footer_container }>
                <div className={ styles.footer_content }>
                    <div className={ styles.footer_content_inner }>
                        <h6 style={ { marginTop: "12px" } }>Contact us</h6>
                        <a href={ "mailto:contact@sharemysuper.org.nz" }>contact@sharemysuper.org.nz</a>
                        <div className={ styles.footer_content_inner_socials }>
                            <a href={ "https://www.facebook.com/ShareMySuper/" }><img src={ Facebook }
                                alt={ "Share my Super Facebook" } />
                            </a>
                            <a href={ "https://www.instagram.com/sharemysuper/" }><img src={ Instagram }
                                alt={ "Share my Super Instagram" } />
                            </a>
                            <a href={ "https://www.linkedin.com/company/share-my-super/" }><img src={ LinkedIn }
                                alt={ "Share my Super LinkedIn" } />
                            </a>
                        </div>
                    </div>
                    <div className={ classnames(styles.footer_content_inner, styles.footer_content_inner_hide) }>
                        <h6>Share my super</h6>
                        <Link to={ Routes.root }>Home</Link>
                        <Link to={ Routes.charities.root }>Charities</Link>
                        <Link to={ Routes.aboutUs }>About</Link>
                        <Link to={ Routes.blog.root }>Insights</Link>
                        {/*<Link to={Routes.faq}>FAQ</Link>*/ }
                        <h6>Insights</h6>
                        <a href="https://join.sharemysuper.org.nz/article/oecd-data-reveals-trends-between-elderly-and-child-poverty-in-new-zealand">Child and Elder Poverty in New Zealand</a>
                    </div>
                    <div className={ styles.footer_content_inner }>
                        <h6>Join the Super Community</h6>
                        <Link to={ Routes.donationTypeSelect } onClick={ this.onDonatePress }>Join Today</Link>
                        <Link to={ Routes.login }>Log in</Link>
                    </div>
                    <div className={ classnames(styles.footer_content_inner, styles.footer_buttons) }>
                        <Button onPress={ () => navigate("/donate") } text={ "Donate" } />
                        <Button onPress={ () => navigate("/charities") } text={ "Browse Charities" } />

                    </div>

                </div>
                <div className={ styles.footer_legal_line }>
                    <div className={ styles.footer_legal_content }>
                        <span>© Share My Super { new Date().getFullYear() }</span>
                        <div className={ styles.terms_privacy_content }>
                            <Link to={ Routes.termsAndConditions }>Terms and Conditions</Link>
                            <Link to={ Routes.privacyPolicy }>Privacy Policy</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect()(Footer);
