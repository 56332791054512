import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import root from "../reducers/root";

const composeEnhancers = (process.env.REACT_APP_ENV === "development" || process.env.REACT_APP_ENV === "staging") &&
!!window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

export default function configureStore() {
    const store = createStore(root, composeEnhancers(applyMiddleware(thunk)));

    return { store };
}
