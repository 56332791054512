/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 2019-08-08.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */

import * as Types from "../actions/transactions";

const initialState = {
    0: {
        items: {},
        metaData: {},
        prevCursor: null,
        nextCursor: null,
        limit: 10
    }
};

export default function transactions(state = initialState, action = {
    type: null,
    payload: null
}) {
    switch (action.type) {

        case Types.SET_TRANSACTIONS: {
            const hasTransactions = !!Object.keys(state[0].items).length;
            const data = action.payload;
            const page = hasTransactions ? Object.keys(state).length : 0;

            const items = {};
            data.items.map((transaction) => {
                items[transaction.id] = transaction;
                return null;
            });

            const newData = {
                ...data,
                items: items,
            };

            const oldState = hasTransactions ? state : {};

            return {
                ...oldState,
                [page]: newData
            };
        }

        case Types.CLEAR_TRANSACTIONS: {
            return initialState;
        }

        default: {
            return state;
        }
    }
}
