import { initializeApp } from "firebase/app";
import {
    getAuth,
    onAuthStateChanged,
    sendPasswordResetEmail as fbSendPasswordResetEmail,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut as fbSignOut,
    updateProfile as fbUpdateProfile
} from "firebase/auth";
import { logEvent as fbLogEvent, getAnalytics } from "firebase/analytics";
import React, { createContext, useContext, useEffect, useState, } from "react";
import { getUser } from "../redux/actions/user";
import { useDispatch } from "react-redux";
import { setAuthLoading } from "../redux/actions/settings";

export const FirebaseContext = createContext({
    fbUser: undefined,
    signIn: () => Promise.resolve(),
    signOut: () => Promise.resolve(),
    sendPasswordResetEmail: () => Promise.resolve(),
    logEvent: () => {
    },
    signUp: () => Promise.resolve(),
    updateProfile: () => Promise.resolve()
});

export const FirebaseProvider = (props) => {
    const [app, setApp] = useState();
    const [auth, setAuth] = useState();
    const [fbUser, setFbUser] = useState();
    const [analytics, setAnalytics] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!app) {
            const fbapp = initializeApp({
                apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
                authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
                databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
                projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
                storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
                messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
                appId: process.env.REACT_APP_FIREBASE_APP_ID,
                measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
            });
            const analytics = getAnalytics(fbapp);
            setApp(fbapp);
            setAnalytics(analytics);

            const auth = getAuth();
            setAuth(auth);

            onAuthStateChanged(auth, async (fbuser) => {
                if (fbuser) {
                    setFbUser(fbuser);
                    await dispatch(getUser());
                    await dispatch(setAuthLoading(false));
                } else {
                    await dispatch(setAuthLoading(false));
                }
            });
        }
    }, [app]);

    const signIn = async (email, password) => {
        if (!auth) {
            throw new Error("firebase application is not initialised");
        }

        try {
            await dispatch(setAuthLoading(true));
            return await signInWithEmailAndPassword(
                auth,
                email,
                password
            );
        } finally {
            await dispatch(setAuthLoading(false));
        }
    };

    const signOut = async () => {
        if (!auth) {
            throw new Error("firebase application is not initialised");
        }
        return fbSignOut(auth);
    };

    const sendPasswordResetEmail = async (email) => {
        if (!auth) {
            throw new Error("firebase application is not initialised");
        }

        return fbSendPasswordResetEmail(auth, email);
    };

    const logEvent = async (event, params) => {
        return fbLogEvent(analytics, event, params);
    };

    const signUp = async (email, password) => {
        return createUserWithEmailAndPassword(auth, email, password);
    };

    const updateProfile = async (user, displayName) => {
        return fbUpdateProfile(user, { displayName });
    };

    return (
        <FirebaseContext.Provider
            value={{
                fbUser,
                signIn,
                signOut,
                sendPasswordResetEmail,
                logEvent,
                signUp,
                updateProfile
            }}
            {...props}
        />
    );
};

export const useFirebase = () => useContext(FirebaseContext);
