import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./IntroBlock.module.scss";
import {  imageUrlFor, renderBlockText } from "../../../../util/util";
import arrow from "../../../../resources/images/png/arrow-blue.png"

class IntroBlock extends Component {
    static propTypes = {
        introBlock: PropTypes.object.isRequired
    };

    render () {
        if ( !this.props.introBlock ) {
            return null;
        }

        const introBlock = this.props.introBlock;
        return (
            <div className={styles.intro_block_container} id={this.props.id}>
                <div className={styles.title_and_arrow}>
                <h3>
                    {introBlock.title}
                </h3>
                    <img src={arrow}/>
                </div>
                <div className={styles.how_it_works_card}>
                {introBlock.content.map((content) => {
                    return (
                        <div key={content._key}
                             className={styles.info}
                        >
                            <div className={styles.icon_wrapper}>
                            <img src={imageUrlFor(content.image)}/>
                            </div>
                            <h5 className={styles.heading}>
                                {content.heading}
                            </h5>
                            <p className={styles.subheading}>
                                {content.subheading}
                            </p>
                            <div>
                                {renderBlockText(content.content)}
                            </div>
                        </div>

                    );
                })}
                </div>
            </div>
        );
    }
}

export default IntroBlock;
