import { Navigate } from "react-router-dom";
import Routes from "../../util/routes";
import FullPageLoader from "../common/widgets/loaders/fullPageLoader/FullPageLoader";
import React from "react";
import { useSelector } from "react-redux";

export default function AuthRoute(props) {
    const user = useSelector((state) => state.user);
    const authLoading = useSelector((state) => state.settings.authLoading);

    if (authLoading) {
        return (
            <FullPageLoader />
        );
    }

    if (!user && !authLoading) {
        return (
            <Navigate to={Routes.login} />
        );
    }

    return props.children;
}
