import React from "react";
import { Field, Formik } from "formik";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as Yup from "yup";
import styles from "./ForgotPassword.module.scss";
import Button from "../../common/widgets/button/Button";
import TextInput from "../../common/widgets/input/textInput/TextInput";
import { showError, showSuccess } from "../../../redux/actions/snackbars";
import Component from "../../common/widgets/component/Component";
import Logo from "../../../resources/images/png/Logo_ShareMS_Colour.png";
import { FirebaseContext } from "../../../context/firebase";

const ForgotSchema = Yup.object()
    .shape({
        email: Yup.string()
            .email("Invalid email")
            .required("Required")
    });

class ForgotPassword extends Component {
    static contextType = FirebaseContext;

    state = {
        loading: false
    };

    onSubmit = (values) => {
        this.setState({ loading: true });
        this.context.sendPasswordResetEmail(values.email)
            .then(this.props.dispatch(showSuccess("Password reset email sent")))
            .catch(err => this.props.dispatch(showError(err.message)))
            .finally(() => this.setState({ loading: false }));
    };

    renderForm = (formProps) => {
        const {
            onChange,
            isValid,
            handleSubmit
        } = formProps;
        return (
            <form>
                <Field name={"email"}
                       label={"Email"}
                       component={TextInput}
                       placeholder={"Type your email here"}
                       onChange={onChange} />
                <Button text={"Reset Password"}
                        formSubmit
                        loading={this.state.loading}
                        disabled={!isValid}
                        onPress={handleSubmit} />
            </form>
        );
    };

    render() {
        return (
            <div className={styles.forgot_password_container}>
                <img className={styles.logo} src={Logo} alt={"Share my Super"} />
                <div className={styles.forgot_password_content}>
                    <h2>Forgot Password</h2>
                    <Formik onSubmit={this.onSubmit}
                            initialValues={{ email: "" }}
                            validationSchema={ForgotSchema}
                            render={this.renderForm} />
                    <div className={styles.forgot_password_button_container}>
                        <Link to={"/login"}>Back to login</Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect()(ForgotPassword);
