const Routes = {
    root: "/",
    aboutUs: "/about-us",
    blog: {
        root: "/blog",
        blogPost: "/blog/content/:slug",
    },
    charities: {
        root: "/charities",
        charityDetail: "/charities/:slug",
        charitySelection: "/charity-selection",
        charitySelectionMobile: "/charity-selection-mobile"
    },
    contactUs: "/contact",
    // faq: "/faq",
    facts: "/facts",
    howWeMadeOurCommercial: "/how-we-made-our-commercial",
    press: "/press",
    partners: "/partners",
    joinme: "joinme",
    howDoesItWork: "/how-does-it-work",
    privacyPolicy: "/privacy-policy",
    eofyReceipt: "/eofy-receipt",
    donationReceipt: "/donation-receipt",
    rebate: "/donate-your-tax-rebate",
    termsAndConditions: "/terms-and-conditions",
    login: "/login",
    forgotPassword: "/forgot-password",
    donationTypeSelect: "/donate",
    donationSetup: "/donate/setup",
    annualReport: "/annual-report",
    annualReport23: "/annual-report-22-23",
    annualReportOld: "/annual-report-21-22",
    user: {
        donorDashboard: "/dashboard",
        donationSetup: "/donate/setup",
        myDonations: "/my-donations",
        myProfile: "/my-profile",
        transactionHistory: "/transaction-history"
    }
};

export default Routes;
