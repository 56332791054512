import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import * as classnames from "classnames";
import PreFooter from "../../common/blocks/preFooter/PreFooter";
import { pageReceived } from "../../../redux/actions/pages";
import { renderBlockText } from "../../../util/util";
import styles from "./BlogPost.module.scss";
import FullPageLoader from "../../common/widgets/loaders/fullPageLoader/FullPageLoader";
import withRouter from "../../../util/hooks/withRouter";

function BlogPost(props) {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const { page } = props;
    const params = useParams();

    useEffect(() => {
        const query = `{
        heading,
        subheading,
        meta,
        date,
        imageLinkAlt,
        form,
        content[]{..., "asset": asset->},
        }`;

        window.sanity.fetch(`*[slug.current == "/${params.slug}"]${query}[0]`)
            .then((page) => {
                dispatch(pageReceived({ "blogPost": page }));
                setLoading(false);
            });
    }, []);

    if (loading) {
        return (
            <FullPageLoader />
        );
    }

    return (
        <div className={classnames(styles.content_container, styles.blog_post_container)}>
            <div className={styles.main_inner}>
                <div className={styles.content_inner}>
                    {!!page.meta && (
                        <div className={styles.small_meta}>
                            {`On ${moment(page.date)
                                .format("MMM DD YYYY")} / by ${page.meta}`}
                        </div>
                    )}
                    <h2>{page.heading}</h2>
                    <h4>{page.subheading}</h4>
                    <div> {!!page.content && renderBlockText(page.content)}</div>
                    <div className={styles.back_button_container}>
                        <Link to={"/blog"}>Back to blog</Link>
                    </div>
                </div>
            </div>
            <PreFooter />

        </div>
    );
}

export default connect((state) => {
    return {
        page: state.pages.blogPost
    };
})(withRouter(BlogPost));
