import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import qs from "qs";
import LoginForm from "./loginForm/LoginForm";
import { showError } from "../../../redux/actions/snackbars";
import styles from "./Login.module.scss";
import Logo from "../../../resources/images/png/Logo_ShareMS_Colour.png";
import { getUser } from "../../../redux/actions/user";
import { setAuthLoading } from "../../../redux/actions/settings";
import FullPageLoader from "../../common/widgets/loaders/fullPageLoader/FullPageLoader";
import Routes from "../../../util/routes";
import { getUserStatusAPI } from "../../../redux/actions/api/user";
import withRouter from "../../../util/hooks/withRouter";
import { useFirebase } from "../../../context/firebase";

const LoginSchema = Yup.object()
    .shape(
        {
            email: Yup.string()
                .required("Required"),
            password: Yup.string()
                .required("Required")
        }
    );

function Login(props) {
    const [loading, setLoading] = useState(false);
    const [emailPrefill, setEmailPrefill] = useState("");
    const { signIn } = useFirebase();
    const params = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const donationRoute = Routes.donationSetup;

    useEffect(() => {
        (async () => {
            if (params.charities && !props.authLoading) {
                // has pathed from Double landing page, check user status
                setLoading(true);

                await getUserStatusAPI(params.email)
                    .then((isDonor) => {
                        if (!isDonor) {
                            // not a donor, redirect to create account page with values prefilled
                            return props.navigate(
                                donationRoute,
                                {
                                    state: {
                                        doubleReferral: true,
                                        oneOff: true,
                                        doubleCharities: params.charities?.split(","),
                                        amount: Number(params.amount)
                                    }
                                }
                            );
                        }
                        if (props.user?.email === params.email) {
                            // donor & is logged in with supplied email, redirect to payment page
                            return props.navigate(
                                donationRoute,
                                {
                                    state: {
                                        doubleReferral: true,
                                        oneOff: true,
                                        doubleCharities: params.charities?.split(","),
                                        amount: Number(params.amount)
                                    }
                                }
                            );
                        }
                        // user is a donor not logged in prefill login with their email
                        return setEmailPrefill(params.email);
                    })
                    .catch((e) => {
                        console.log("err", e);
                    })
                    .finally(() => setLoading(false));
            }
        })();

    }, [props.authLoading]);

    useEffect(() => {
        if (!!props.user && !params.charities) {
            props.navigate(Routes.user.donorDashboard);
        }
    }, [props.user]);

    if (props.authLoading) {
        return <FullPageLoader />;
    }

    const onSubmit = (values) => {
        setLoading(true);

        signIn(values.email, values.password)
            .then(() => {
                props.dispatch(setAuthLoading(true));
                props.dispatch(getUser({
                    onSuccess: () => {
                        setLoading(false);
                        if (params.charities) {
                            // redirect logged in double user to donation summary page
                            props.navigate(
                                donationRoute,
                                {
                                    state: {
                                        doubleReferral: true,
                                        oneOff: true,
                                        doubleCharities: params.charities?.split(","),
                                        amount: Number(params.amount)
                                    }
                                }
                            );
                        }
                    },
                    onError: (error) => {
                        handleError(error);
                    }
                }));
            })
            .catch((error) => {
                handleError(error);
            });
    };

    const handleError = (error) => {
        props.dispatch(showError(error.message));
        setLoading(false);
    };

    return (
        <div className={styles.login_container}>
            <img className={styles.logo} src={Logo} alt={"Share my Super"} />
            <div className={styles.login_content}>
                <h2>Welcome back!</h2>
                <h4>Log in below</h4>
                <Formik onSubmit={onSubmit}
                        validationSchema={LoginSchema}
                        enableReinitialize
                        initialValues={{
                            email: emailPrefill,
                        }}
                        render={(formProps) => <LoginForm {...formProps} loading={loading} />} />
            </div>
        </div>
    );
}

export default withRouter(connect((state) => {
    return {
        user: state.user,
        authLoading: state.settings.authLoading
    };
})(Login));
