export const SeoPages = {
    home: {
        route: "/",
        title: "Share My Super | Lift up our kids, lift up our country.  We’re on a mission to end child poverty in New Zealand",
        description: "Share My Super offers New Zealanders an effective way to give back and tackle the inequity faced by Kiwi children.",
        ogTitle: "Share My Super | Lift up our kids, lift up our country.  We’re on a mission to end child poverty in New Zealand",
        ogDescription: "Share My Super offers New Zealanders an effective way to give back and tackle the inequity faced by Kiwi children.",
        ogImage: "https://storage.googleapis.com/spend-my-super-uploads/sms-og-image.png?new=true"

    },
    charities: {
        route: "/charities",
        title: "Inspirational Charities in NZ Making A Difference | Share My Super",
        description: "We partner with leading New Zealand charities who work hard to eliminate child poverty. One donation can support multiple charities such as Variety, First Foundation, Child Poverty Action Group, Pillars, KidsCan and more.",
        ogTitle: "Inspirational Charities in NZ Making A Difference | Share My Super",
        ogDescription: "We partner with leading New Zealand charities who work hard to eliminate child poverty. One donation can support multiple charities such as Variety, First Foundation, Child Poverty Action Group, Pillars, KidsCan and more.",
        ogImage: "https://storage.googleapis.com/spend-my-super-uploads/sms-og-image.png?new=true"
    },
    aboutUs: {
        route: "/about-us",
        title: "About Us",
        description: "We are on a mission to end child poverty in NZ. Meet the passionate team committed to giving Kiwi kids a fair chance to thrive and succeed. See more.",
        ogTitle: "About Us",
        ogDescription: "We are on a mission to end child poverty in NZ. Meet the passionate team committed to giving Kiwi kids a fair chance to thrive and succeed. See more.",
        ogImage: "https://storage.googleapis.com/spend-my-super-uploads/sms-og-image.png?new=true"
    },
    howDoesItWork: {
        route: "/how-does-it-work",
        title: "How does it work",
        description: "We make it easy for you to donate some or all of your superannuation to support outstanding charities that are making a difference for New Zealand children.",
        ogTitle: "How does it work",
        ogDescription: "We make it easy for you to donate some or all of your superannuation to support outstanding charities that are making a difference for New Zealand children.",
        ogImage: "https://storage.googleapis.com/spend-my-super-uploads/sms-og-image.png?new=true"
    },
    blog: {
        route: "/blog",
        title: "Blog | Share My Super",
        description: "Read the latest stories from our donors and children's charities here.",
        ogTitle: "Blog | Share My Super",
        ogDescription: "Read the latest stories from our donors and children's charities here.",
        ogImage: "https://storage.googleapis.com/spend-my-super-uploads/sms-og-image.png?new=true"
    },
    faq: {
        route: "/faq",
        title: "FAQs",
        description: "FAQs | How much can I donate? Can I change or stop donations at any point in time? Does Share My Super take a cut from the donations? Read more.",
        ogTitle: "FAQs",
        ogDescription: "FAQs | How much can I donate? Can I change or stop donations at any point in time? Does Share My Super take a cut from the donations? Read more.",
        ogImage: "https://storage.googleapis.com/spend-my-super-uploads/sms-og-image.png?new=true"
    },
    partners: {
        route: "/partners",
        title: "Our Partners",
        description: "Partners | Our partners help us achieve our goals.",
        ogTitle: "Our Partners",
        ogDescription: "Partners | Our partners help us achieve our goals.",
        ogImage: "https://storage.googleapis.com/spend-my-super-uploads/sms-og-image.png?new=true"

    },
    press: {
        route: "/press",
        title: "In the Press | Share My Super",
        description: "Keep up to date with what we are doing, and our latest news at Share My Super",
        ogTitle: "In the Press | Share My Super",
        ogDescription: "Keep up to date with what we are doing, and our latest news at Share My Super",
        ogImage: "https://storage.googleapis.com/spend-my-super-uploads/sms-og-image.png?new=true"
    },
    policies: {
        route: "/privacy-policy",
        title: "Privacy Policy | Share My Super",
        description: "Share My Super is a registered New Zealand charity on a mission to help end child poverty. We take your privacy and security seriously. Here are the details.",
        ogTitle: "Privacy Policy | Share My Super",
        ogDescription: "Share My Super is a registered New Zealand charity on a mission to help end child poverty. We take your privacy and security seriously. Here are the details.",
        ogImage: "https://storage.googleapis.com/spend-my-super-uploads/sms-og-image.png?new=true"
    },
    contactUs: {
        route: "/contact",
        title: "Contact Our Team",
        description: "Questions? No worries.  You can contact us via email contact@sharemysuper.org.nz for further information or call 0800 300 106",
        ogTitle: "Contact Our Team",
        ogDescription: "Questions? No worries.  You can contact us via email contact@sharemysuper.org.nz for further information or call 0800 300 106",
        ogImage: "https://storage.googleapis.com/spend-my-super-uploads/sms-og-image.png?new=true"
    },
    donate: {
        route: "/donate",
        title: "Donate Now | Help Break The Cycle Of Child Poverty",
        description: "Share My Super offers an effective way to give back and tackle the inequity faced by Kiwi children. Your donation goes directly to our charity partners, we are 100% funded by our founder Liz Greive",
        ogTitle: "Donate Now | Help Break The Cycle Of Child Poverty",
        ogDescription: "Share My Super offers an effective way to give back and tackle the inequity faced by Kiwi children. Your donation goes directly to our charity partners, we are 100% funded by our founder Liz Greive",
        ogImage: "https://storage.googleapis.com/spend-my-super-uploads/sms-og-image.png?new=true"
    }
}
