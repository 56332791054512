/* eslint-disable */
import React, {Component} from "react";
import styles from "./ImageTextItem.module.scss";
import PropTypes from "prop-types";
import * as classnames from "classnames";

class ImageTextItem extends Component {
    static propTypes = {
        heading: PropTypes.string,
        content: PropTypes.any,
        className: PropTypes.string,
    };

    static defaultProps = {
        heading: "",
        content: null,
        className: null,
    };

    render() {
        let className = styles.right;
        if (!!this.props.itemIndex && this.props.itemIndex % 2 === 1) {
            className = styles.left;
        }

        return (
            <div className={classnames(styles.image_text_container, className, this.props.className)}>
                {!!this.props.image ? <div className={styles.image_text_image}>
                    <img src={this.props.image} alt={this.props.imageAlt}/>
                </div> : null}
                <div className={styles.image_text_content}>
                    {!!this.props.heading ?
                        <h3>{this.props.heading}</h3> : null}
                    {!!this.props.subheading && <p><strong>{this.props.subheading}</strong></p>}
                    {!!this.props.content ? <div>{this.props.content}</div> : null}
                </div>
            </div>
        );
    }
}

export default ImageTextItem;