import React from "react";
import * as classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styles from "./ButtonBlock.module.scss";
import Button from "../../widgets/button/Button";
import withRouter from "../../../../util/hooks/withRouter";
import { useFirebase } from "../../../../context/firebase";

function ButtonBlock(props) {
    const { logEvent } = useFirebase();

    if (!props.buttons || (props.buttons && props.buttons.length === 0)) {
        return null;
    }

    return (
        <div className={classnames(styles.button_block_container, props.className)}>
            {!!props.heading && <h3>{props.heading}</h3>}
            <div className={props.innerClassName}>
                {props.buttons.map((button) => {
                    const key = button.href ? button.href.current : Math.random();

                    function onPress() {
                        if (button.onPress) {
                            button.onPress();
                        }
                        if (button.href) {
                            if (button.href.current === "/donate") {
                                window.dataLayer.push({"event" : "clicked_donate"});

                            }
                            props.navigate(button.href.current);
                        }
                    }

                    return (
                        <Button text={button.buttonText}
                                key={key}
                                className={styles.button_block_button}
                                onPress={onPress}
                                {...button.props} />
                    );
                })}

            </div>
        </div>
    );
}

export default connect()(withRouter(ButtonBlock));

ButtonBlock.propTypes = {
    className: PropTypes.string,
    heading: PropTypes.string,
    innerClassName: PropTypes.string,
    mainButton: PropTypes.any,
    secondaryButton: PropTypes.any,

};
ButtonBlock.defaultProps = {
    className: "",
    heading: "",
    innerClassName: "",
    mainButton: null,
    secondaryButton: null,
};
