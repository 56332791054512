import React, { Component } from "react";

export default class SelectInput extends Component {
    onChange = (e) => {
        if (this.props.field) {
            this.props.field.onChange(e);
        }

        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }
    };

    render() {
        return (
            <select {...this.props.field} value={this.props.value} onChange={this.onChange}>
                {this.props.options}
            </select>
        );
    }
}
