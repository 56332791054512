import React, { Component } from "react";
import styles from "./NotFound.module.scss";
import Button from "../../common/widgets/button/Button";
import Routes from "../../../util/routes";
import withRouter from "../../../util/hooks/withRouter";

class NotFound extends Component {

    onButtonPress = () => {
        this.props.navigate(Routes.root);
    };

    render() {
        return (
            <div className={ styles.not_found_container }>
                <div className={ styles.not_found_content }>
                    <h2>Sorry, that page doesn't exist.</h2>
                    <h4>Go back to home and try again.</h4>
                    <Button turquoise text={ "Back to home" } onPress={ this.onButtonPress } />
                </div>

            </div>
        );
    }
}

export default withRouter(NotFound);
