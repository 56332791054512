import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import _values from "lodash/values";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Logo from "../../../../resources/images/png/Logo_ShareMS_Colour.png";
import Routes from "../../../../util/routes";
import styles from "./navBar.module.scss";
import { purgeReduxStore, setAuthLoading } from "../../../../redux/actions/settings";
import { showError } from "../../../../redux/actions/snackbars";
import withRouter from "../../../../util/hooks/withRouter";
import { FirebaseContext } from "../../../../context/firebase";
import close from "../../../../resources/images/svg/close-pop-up-white.svg";
import arrowblue from "../../../../resources/images/svg/arrowblue.svg";
import Banner from "../banner/Banner";

class NavBar extends Component {
    static contextType = FirebaseContext;

    constructor(props) {
        super(props);

        this.state = {
            showMenu: false,
            hidingMenu: false,
            resourcesOpen: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({ showMenu: false, resourcesOpen: false });
        }
    }

    toggleMenu = () => {
        if (this.state.showMenu) {
            // this.setState({hidingMenu: true});
            window.setTimeout(async () => {
                this.setState({ showMenu: false }
                );
            }, 180);
        } else {
            this.setState((prevState) => {
                return { showMenu: !prevState.showMenu };
            });
        }
    };

    onLogoutPress = () => {
        this.context.signOut()
            .then(this.props.dispatch(purgeReduxStore()))
            .then(this.props.dispatch(setAuthLoading(false)))
            .then(this.props.navigate(Routes.root))
            .catch(error => this.props.dispatch(showError(error)));
        this.toggleMenu()
    };

    onDonatePress = () => {
        window.dataLayer.push({ "event": "clicked_donate" })
        this.toggleMenu()
    };

    onDropDownClick = () => {
        this.setState({ resourcesOpen: !this.state.resourcesOpen })
    }

    renderDropDownNav = () => (
        <Fragment>
            <div
                className={ classNames(styles.dropdown_container, { [ styles.show_container_size ]: this.state.showMenu }) }
                onClick={ this.toggleMenu }>
                <div className={ classNames(styles.dropdown_nav, { [ styles.show_nav ]: this.state.showMenu }) }
                >
                    <div className={ styles.dropdown_nav_left }>
                        <Link
                            className={ classNames(styles.landing_page_button) }
                            to={ Routes.charities.root } onClick={ this.toggleMenu }>
                            Charities
                        </Link>
                        <Link
                            className={ classNames(styles.landing_page_button) }
                            to={ Routes.aboutUs } onClick={ this.toggleMenu }>
                            About
                        </Link>
                        <Link
                            className={ classNames(styles.landing_page_button) }
                            to={ Routes.howDoesItWork } onClick={ this.toggleMenu }>
                            How it works
                        </Link>
                        <div className={ styles.resource_links } onClick={ this.toggleMenu }>
                            <Link
                                className={ classNames(styles.landing_page_button) }
                                to={ Routes.partners } onClick={ this.toggleMenu }>
                                Our partners
                            </Link>
                            <Link
                                className={ classNames(styles.landing_page_button) }
                                to={ Routes.blog.root } onClick={ this.toggleMenu }>
                                Insights
                            </Link>
                            <Link
                                className={ classNames(styles.landing_page_button) }
                                to={ Routes.press } onClick={ this.toggleMenu }>
                                In the media
                            </Link>
                            <Link
                                className={ classNames(styles.landing_page_button) }
                                to={ Routes.contactUs } onClick={ this.toggleMenu }>
                                Contact us
                            </Link>
                        </div>
                        {/*<Link*/ }
                        {/*    className={classNames(styles.landing_page_button, { [styles.active]: this.props.location.pathname === Routes.contactUs })}*/ }
                        {/*    to={Routes.contactUs}>*/ }
                        {/*    Contact*/ }
                        {/*</Link>*/ }
                        <div className={ styles.buttons }>

                            { !this.props.user ?
                                (
                                    <Fragment>
                                        <Link
                                            className={ classNames(styles.login_button, { [ styles.active ]: this.props.location.pathname === Routes.donationTypeSelect }) }
                                            to={ Routes.donationTypeSelect }
                                            onClick={ this.onDonatePress }>
                                            Donate
                                        </Link>
                                        <Link
                                            className={ classNames(styles.login_button_outline, { [ styles.active ]: this.props.location.pathname === Routes.login }) }
                                            to={ Routes.login } onClick={ this.toggleMenu }>
                                            Login
                                        </Link>
                                    </Fragment>
                                ) : (
                                    <a
                                        className={ classNames(styles.login_button_outline, { [ styles.active ]: this.props.location.pathname === Routes.login }) }
                                        onClick={ this.onLogoutPress }>
                                        Logout
                                    </a>
                                ) }
                        </div>
                    </div>
                    <div className={ styles.dropdown_nav_right }>
                        <div className={ styles.cross } onClick={ this.toggleMenu } id={ "closeButton" }>
                            <img src={ close } />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )


    renderDefaultNav = () => (
        <Fragment>
            {/*<Link*/ }
            {/*    className={classNames(styles.landing_page_button, { [styles.active]: this.props.location.pathname === Routes.root })}*/ }
            {/*    to={Routes.root}>*/ }
            {/*    Home*/ }
            {/*</Link>*/ }
            <Link
                className={ classNames(styles.landing_page_button, { [ styles.active ]: this.props.location.pathname === Routes.charities.root }) }
                to={ Routes.charities.root }>
                Charities
            </Link>
            <Link
                className={ classNames(styles.landing_page_button, { [ styles.active ]: this.props.location.pathname === Routes.aboutUs }) }
                to={ Routes.aboutUs }>
                About
            </Link>
            <Link
                className={ classNames(styles.landing_page_button, { [ styles.active ]: this.props.location.pathname === Routes.howDoesItWork }) }
                to={ Routes.howDoesItWork }>
                How it works
            </Link>
            <div
                className={ classNames(styles.landing_page_button, styles.dropdown) }
            >
                <div className={ classNames(styles.drop_button) }
                    onClick={ this.onDropDownClick }>
                    More <img src={ arrowblue }
                        className={ classNames(styles.chevron, { [ styles.chevron_active ]: this.state.resourcesOpen }) } />
                </div>
                <div className={ classNames(styles.dropdown_content, this.state.resourcesOpen ? styles.show : "") }>
                    <Link
                        className={ classNames(styles.landing_page_button) }
                        to={ Routes.blog.root } onClick={ this.toggleMenu }>
                        Insights
                    </Link>
                    <Link
                        className={ classNames(styles.landing_page_button, { [ styles.active ]: this.props.location.pathname === Routes.partners }) }
                        to={ Routes.partners }>
                        Our partners
                    </Link>
                    <Link
                        className={ classNames(styles.landing_page_button, { [ styles.active ]: this.props.location.pathname === Routes.press }) }
                        to={ Routes.press }>
                        In the media
                    </Link>
                </div>
            </div>

            {/*<Link*/ }
            {/*    className={classNames(styles.landing_page_button, { [styles.active]: this.props.location.pathname === Routes.contactUs })}*/ }
            {/*    to={Routes.contactUs}>*/ }
            {/*    Contact*/ }
            {/*</Link>*/ }
            {
                this.props.user
                    ? (
                        <Link
                            className={ classNames(styles.dashboard_button) }
                            to={ Routes.user.donorDashboard }>
                            Dashboard
                        </Link>

                    )
                    : null
            }

        </Fragment>
    );

    renderUserNav = () => (
        <Fragment>
            <Link
                className={ classNames(styles.landing_page_button, { [ styles.active ]: this.props.location.pathname === Routes.user.donorDashboard }) }
                to={ Routes.user.donorDashboard }>
                Dashboard
            </Link>

            <Link
                className={ classNames(styles.landing_page_button, { [ styles.active ]: this.props.location.pathname === Routes.user.myDonations }) }
                to={ Routes.user.myDonations }>
                My donations
            </Link>
            <Link
                className={ classNames(styles.landing_page_button, { [ styles.active ]: this.props.location.pathname === Routes.user.myProfile }) }
                to={ Routes.user.myProfile }>
                My Profile
            </Link>
            <Link
                className={ classNames(styles.landing_page_button, { [ styles.active ]: this.props.location.pathname === Routes.user.transactionHistory }) }
                to={ Routes.user.transactionHistory }>
                Transactions
            </Link>
            <Link
                className={ classNames(styles.login_button_outline, { [ styles.active ]: this.props.location.pathname === Routes.root }) }
                to={ Routes.root }>
                Back to home
            </Link>
        </Fragment>
    );

    render() {
        const userRoutes = _values(Routes.user);
        return (
            <>
                { this.props.banner && this.props.banner.showBanner && (
                    <Banner banner={ this.props.banner } />
                ) }
                <div className={ classNames(styles.toolbar) }>

                    <div className={ styles.nav_bar_container }>
                        <div className={ styles.nav_bar_logo }>
                            <a href={ Routes.root }> <img src={ Logo } alt={ "Share my Super Logo" } /></a>
                        </div>
                        <div>
                            <nav id={ "nav_bar" } className={ styles.nav_bar_right_items }>
                                <div id={ "user-routes" }>
                                    { userRoutes.includes(this.props.location.pathname) ? this.renderUserNav() : this.renderDefaultNav() }
                                </div>
                                <div id={ "buttons" } className={ styles.buttons }>
                                    { !this.props.user ?
                                        (
                                            <Fragment>
                                                <Link
                                                    className={ classNames(styles.login_button, { [ styles.active ]: this.props.location.pathname === Routes.donationTypeSelect }) }
                                                    to={ Routes.donationTypeSelect }
                                                    onClick={ this.onDonatePress }>
                                                    Donate
                                                </Link>
                                                <Link
                                                    className={ classNames(styles.login_button_outline, { [ styles.active ]: this.props.location.pathname === Routes.login }) }
                                                    to={ Routes.login }>
                                                    Login
                                                </Link>
                                            </Fragment>
                                        ) : (
                                            <a
                                                className={ classNames(styles.login_button_outline, { [ styles.active ]: this.props.location.pathname === Routes.login }) }
                                                onClick={ this.onLogoutPress }>
                                                Logout
                                            </a>
                                        ) }
                                </div>
                            </nav>
                            <div className={ styles.nav_bar_toggle } onClick={ this.toggleMenu }>
                                <span />
                                <span />
                                <span />
                            </div>
                        </div>
                    </div>

                </div>
                { this.renderDropDownNav() }

            </>
        );
    }
}

NavBar.propTypes = {
    user: PropTypes.object
};

NavBar.defaultProps = {
    user: null
};

export default withRouter(connect()(NavBar));









