import * as Types from "../actions/charities";

const initialState = {};

export default function charities(state = initialState, action) {

    switch (action.type) {
        case Types.SET_CHARITIES: {
            const newCharities = action.payload;
            const newState = { ...state };

            newCharities.forEach(charity => {
                newState[charity.charity] = charity;
            });

            return newState;
        }

        default: {
            return state;
        }
    }
}
