import * as Types from "../actions/pages";

const initialState = {
    loading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case Types.PAGE_RECEIVED:
            if (!action.payload) {
                window.location.href = "/not-found";
            }

            return {
                ...state,
                ...action.payload
            };
        case Types.LOADING:
            return {
                ...state,
                loading: action.loading
            };
        default:
            return state;

    }
};
