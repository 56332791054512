import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.scss";
import "./util/prototypes";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { sanityClient } from "./util/util";

window.sanity = sanityClient;
ReactDOM.render(<App />, document.getElementById("root"));
