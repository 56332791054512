import classnames from "classnames";
import omit from "lodash/omit";
import PropTypes from "prop-types";
import React from "react";
import styles from "./Select.module.scss";
import { MenuItem, Select as MatSelect } from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

function renderMenuItem(props, i) {
    return (
        <MenuItem key={i} value={props.value} disabled={!!props.disabled}>{props.label}</MenuItem>
    );
}

function Select(props) {
    let errorClass = {};
    if (props.form) {
        errorClass = { [styles.error]: props.form.errors[props.field.name] && props.form.touched[props.field.name] };
    }
    const wrapperClassName = classnames(styles.select_wrapper, props.wrapperClassName);
    const labelClassName = classnames(styles.label, props.labelClassName);
    const className = classnames(styles.select, errorClass, props.className);
    const [value, setValue] = React.useState(props.initialValue);

    function onChange(e) {
        const newValue = e.target.value;
        setValue(newValue);
        props.onChange(e, newValue);
    }

    const newProps = omit({ ...props }, ["children", "field", "formRef", "wrapperClassName", "labelClassName", ...Object.keys(Select.defaultProps)]);

    const field = (props.field || {});
    let selectValue = field.value || value;
    let options = props.options;

    if (props.disabled) {
        selectValue = "DISABLED";

        if (props.multiple) {
            selectValue = [selectValue];
        }

        options = [{
            value: "DISABLED",
            label: props.disabledText
        }];
    }

    if (!props.multiple) {
        selectValue = (selectValue || "").toString();
    }

    return (
        <div className={wrapperClassName}>
            {props.label && <span className={labelClassName}>{props.label}</span>}
            <MatSelect
                disableUnderline
                IconComponent={UnfoldMoreIcon}
                {...newProps}
                disabled={props.disabled}
                className={className}
                onChange={onChange}
                {...field}
                multiple={props.multiple}
                variant={props.variant || "standard"}
                value={selectValue}>
                {options.map(renderMenuItem)}
            </MatSelect>
            {props.form && props.form.errors[props.field.name] && props.form.touched[props.field.name] &&
                <div className={styles.error_text}>{props.form.errors[props.field.name]}</div>}
        </div>
    );
}

Select.propTypes = {
    wrapperClassName: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    disabledText: PropTypes.string,
    initialValue: PropTypes.any,
    options: PropTypes.array,
    onChange: PropTypes.func,
    label: PropTypes.string,
    multiple: PropTypes.bool,
};

Select.defaultProps = {
    className: null,
    disabled: false,
    disabledText: "",
    initialValue: "",
    options: [],
    onChange: () => null,
    label: null,
    multiple: false,
};

export default Select;
