import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Rodal from "rodal";
import ComponentLoader from "../../widgets/loaders/componentLoader/ComponentLoader";
import styles from "./TeamMemberBlock.module.scss";
import "rodal/lib/rodal.css";
import { renderBlockText } from "../../../../util/util";
import ContentCardItem from "../../widgets/contentCardItem/ContentCardItem";

class TeamMemberBlock extends Component {
    static propTypes = {
        className: PropTypes.string,
    };

    static defaultProps = {
        className: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            // loading: !props.teamMembers,
            teamMember: null,
            showModal: false,
        };

        this.persistScroll = null;
    }

    componentDidMount() {
        this.persistScroll = document.getElementById("persistScroll");
    }

    onPress = (teamMember) => {
        if (!!teamMember.excerpt) {
            this.setState({ teamMember: teamMember, showModal: true });
            document.getElementsByTagName("body")[0].style.overflow = "hidden";

        }
    };

    hideModal = () => {
        this.setState({ teamMember: null, showModal: false });
        document.getElementsByTagName("body")[0].style.overflow = "";
    };

    render() {
        if (!this.props.teamMembers) {
            return (
                <ComponentLoader width={50} height={20}/>
            );
        }

        let divStyles = {};

        if (window.wurfl && window.wurfl.is_mobile) {
            divStyles = {
                height: "101%",
                overflowY: "auto",
                overflowX: "hidden",
                WebkitOverflowScrolling: "touch"
            };
        }

        return (
            <>
            <div className={classnames(styles.team_member_container, this.props.className)}>
                {this.props.teamMembers.map((teamMember, index) => {
                    if (index <= 3) {
                        return (
                            <>
                            <ContentCardItem
                                key={teamMember._id}
                                onPress={() => this.onPress(teamMember)}
                                className={styles.team_member_item}
                                image={teamMember.image}
                                imageAlt={teamMember.imageAlt}
                                title={teamMember.name}
                                meta={teamMember.role}
                                hidePointer={!teamMember.excerpt}
                            />
                            </>
                        );
                    }
                    return null;
                })}
                <Rodal visible={this.state.showModal} 
                onClose={this.hideModal} 
                customStyles={{
                    padding: "42px 27px",
                    borderRadius: "12px",
                    maxWidth: "500px",
                    width: "unset",
                    left: "10px",
                    right: "10px",
                    display: "block",
                    margin: "auto",
                    height: "80%",
                    maxHeight: "550px",
                    overflow: "auto",
                    WebkitOverflowScrolling: "touch"
                }}>
                    <div id={"persistScroll"} style={divStyles}>
                        {!!this.state.teamMember &&
                        (
                            <React.Fragment>
                            {<h4>{this.state.teamMember.name}</h4>}
                            {<h5>{this.state.teamMember.role}</h5>}
                            {renderBlockText(this.state.teamMember.excerpt)}
                            </React.Fragment>
                        )}
                    </div>
                </Rodal>
            </div>
            <div className={classnames(styles.team_member_container2, this.props.className)}>
                {this.props.teamMembers.map((teamMember, index) => {
                    if (index >= 4 ) {
                        return (
                            <>
                            <ContentCardItem
                                key={teamMember._id}
                                onPress={() => this.onPress(teamMember)}
                                className={styles.team_member_item2}
                                image={teamMember.image}
                                imageAlt={teamMember.imageAlt}
                                title={teamMember.name}
                                meta={teamMember.role}
                                hidePointer={!teamMember.excerpt}
                            />
                            </>
                        );
                    }
                    return null;
                })}
                <Rodal visible={this.state.showModal} 
                onClose={this.hideModal} 
                customStyles={{
                    padding: "42px 27px",
                    borderRadius: "12px",
                    maxWidth: "500px",
                    width: "unset",
                    left: "10px",
                    right: "10px",
                    display: "block",
                    margin: "auto",
                    height: "80%",
                    maxHeight: "550px",
                    overflow: "auto",
                    WebkitOverflowScrolling: "touch"
                }}>
                    <div id={"persistScroll"} style={divStyles}>
                        {!!this.state.teamMember &&
                        (
                            <React.Fragment>
                            {<h4>{this.state.teamMember.name}</h4>}
                            {<h5>{this.state.teamMember.role}</h5>}
                            {renderBlockText(this.state.teamMember.excerpt)}
                            </React.Fragment>
                        )}
                    </div>
                </Rodal>
            </div>
            </>
        );
    }
}

export default TeamMemberBlock;
