import imageUrlBuilder from "@sanity/image-url";
import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import Sanity from "@sanity/client";

export const sanityClient = Sanity({
    projectId: "e9x53att",
    // dataset: process.env.REACT_APP_ENV === "production" ? "production" : "production",
    dataset: process.env.REACT_APP_ENV === "production" ? "production" : "staging",
    useCdn: false
});

const imageBuilder = imageUrlBuilder(sanityClient);

const serializers = {
    types: {
        point: () => {
            return <div />;
        }
    }
};

export function imageUrlFor(source) {
    return imageBuilder.image(source);
}

export function getImageDetails(url) {
    return new Promise((resolve) => {
        const image = new Image();
        const dimensions = {};

        image.src = url;
        image.addEventListener("load", (i) => {
            dimensions.width = (((i || {}).path || [])[0] || {}).naturalWidth;
            dimensions.height = (((i || {}).path || [])[0] || {}).naturalHeight;
            if (dimensions.width && dimensions.height) {
                dimensions.aspectRatio = dimensions.width / dimensions.height;
            }
            resolve(dimensions);
        });
    });
}

export function renderBlockText(block) {
    if (!block) {
        return null;
    }

    return <BlockContent blocks={block} serializers={serializers} />;
}

export function getCmsButtons(buttonObject) {
    if (!buttonObject) {
        return [];
    }

    const buttons = [];
    if (buttonObject.mainButton) {
        buttons.push({
            ...buttonObject.mainButton,
            props: {darkgreen: true}
        });
    }
    if (buttonObject.secondaryButton) {
        buttons.push({
            ...buttonObject.secondaryButton,
            props: { white: true }
        });
    }
    return buttons;
}

// https://stackoverflow.com/questions/487073/how-to-check-if-element-is-visible-after-scrolling
export function isScrolledIntoView(el) {
    let rect = el.getBoundingClientRect();
    const top = rect.top;
    const height = rect.height;
    el = el.parentNode;

    // Check if bottom of the element is off the page
    if (rect.bottom < 0) {
        return false;
    }

    // Check its within the document viewport
    if (top > document.documentElement.clientHeight) {
        return false;
    }

    do {
        rect = el.getBoundingClientRect();
        if (!(top <= rect.bottom)) {
            return false;
        }
        // Check if the element is out of view due to a container scrolling
        if ((top + height) <= rect.top) {
            return false;
        }
        el = el.parentNode;
    } while (el !== document.body);
    return true;
}

// https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
export function arrayMove(arr, oldIndex, newIndex) {
    if (newIndex >= arr.length) {
        let k = newIndex - arr.length + 1;
        // eslint-disable-next-line no-plusplus
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
}
