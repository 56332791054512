import React, { useEffect, useRef } from "react";

export const ScrollingImages = (props) => {
    const { topImage, bottomImage } = props
    const topRef = useRef(null)
    const bottomRef = useRef(null)
    const screenSize = window.visualViewport.height


    useEffect(() => {
        const scrollListener = () => {
            const distanceToTopBottom = bottomRef.current.getBoundingClientRect().top
            const distanceToTopTop = topRef.current.getBoundingClientRect().top
            if ( distanceToTopTop <= screenSize && distanceToTopTop >= 0 ) {
                const percentageTop = ((distanceToTopTop / screenSize) * 100)
                const quarterPercentageTop = percentageTop / 4;
                topRef.current.style.setProperty("transform", `translate3d(-${quarterPercentageTop}%, 0px, 0px)`)
            }
            if ( distanceToTopBottom <= screenSize && distanceToTopBottom >= 0 ) {
                const percentageBottom = ((distanceToTopBottom / screenSize) * 100)
                const quarterPercentageBottom = percentageBottom / 4;
                bottomRef.current.style.setProperty("transform", `translate3d(${quarterPercentageBottom-50}%, 0px, 0px)`)
            }
        }
        window.addEventListener("scroll", scrollListener)
        return function () {
            window.removeEventListener("scroll", scrollListener)
        }
    }, [])
    return (
        <>
                <img src={topImage} ref={topRef}/>
                <img src={bottomImage} ref={bottomRef}/>
        </>
    )
}