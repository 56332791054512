/**
 *
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *    Created by Chris on 7/11/18.
 * ▬▬ι═══════ﺤ            -═══════ι▬▬
 *
 */


import React, { Component, Fragment } from "react";
import connect from "react-redux/es/connect/connect";
import Snackbar from "./Snackbar";
import styles from "./SnackbarContainer.module.scss";
import { removeSnackbar } from "../../../../redux/actions/snackbars";

class SnackbarContainer extends Component {

    state = {
        snackbarShown: false,
        current: null
    };

    onClose = (config) => {
        this.props.dispatch(removeSnackbar(config.id));
    };

    renderSnackbars = () => {
        return this.props.snackbars.map(this.renderSnackbar);
    };

    renderSnackbar = (config) => {
        return (
            <Snackbar key={config.id}
                      snackbarShown
                      onClose={this.onClose}
                      config={config}/>
        );
    };

    render() {
        return (
            <Fragment>
                {this.props.children}
                <div className={styles.snackbar_container}>
                    {this.renderSnackbars()}
                </div>
            </Fragment>
        );
    }
}

export default connect((state) => {
    return {
        snackbars: state.snackbars
    };
})(SnackbarContainer);
