import * as Yup from "yup";

import { Field, Form, Formik } from "formik";
import React, { useRef, useState } from "react";

import AddressInput from "../../common/widgets/input/addressInput/AddressInput";
import Button from "../../common/widgets/button/Button";
import FormikCheckbox from "../../common/widgets/formikCheckbox/FormikCheckbox";
import TextInput from "../../common/widgets/input/textInput/TextInput";
import styles from "./LandingPage.module.scss";
import { submitContact } from "../../../redux/actions/api/contact";

const InfoFormScema = Yup.object().shape({
    postal: Yup.boolean(),
    firstName: Yup.string()
        .required("Required"),
    lastName: Yup.string()
        .required("Required"),
    email: Yup.string()
        .email("Invalid email")
        .required("Required"),
    
});
export const RequestInfoForm = (props) => {
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const formRef = useRef();
    const [postal, setPostal] = useState(false);
    const initValues = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        address: "",
        optIn: true,
        postal: false,
    }

    const onSubmit = async (values) => {
        setLoading(true)

        window.dataLayer.push({ "event": "Info pack request" })
        let newValues = values;
        try {
           
            await submitContact(values, values.postal? "PostInfoPack" : "DownloadInfoPack");
            setSuccess(true)
            formRef.current.handleReset();

        } catch ( e ) {
            console.log(e)
        }

        setLoading(false)


    }
    return (
        <div>
            <Formik onSubmit={onSubmit}
                    innerRef={formRef}
                    validationSchema={InfoFormScema}
                    initialValues={initValues}
            >
                {({ handleSubmit, handleChange, isValid, values }) => {
                    return (
                        <Form>
                            <div className={styles.checkbox_row}>
                                <div className={styles.checkbox}>

                                    <Field name={"postal"}
                                           id={"postal"}
                                           onChange={()=>setPostal(!postal)}
                                           component={FormikCheckbox}
                                    />

                                    <span>By Post</span>
                                </div>

                            </div>
                            <div>
                                <Field name={"firstName"}
                                       label={"*First Name"}
                                       component={TextInput}
                                       inputClassName={styles.input}
                                       className={styles.wrapper}
                                       labelClassName={styles.label}
                                       onChange={handleChange}/>
                                <Field name={"lastName"}
                                       label={"*Last Name"}
                                       inputClassName={styles.input}
                                       className={styles.wrapper}
                                       labelClassName={styles.label}
                                       component={TextInput}
                                       onChange={handleChange}/>
                            </div>


                            <div>
                                <Field name={"email"}
                                       label={"*Email"}
                                       component={TextInput}
                                       inputClassName={styles.input}
                                       className={styles.wrapper}
                                       labelClassName={styles.label}
                                       onChange={handleChange}/>
                                <Field name={"phone"}
                                       label={"Contact number"}
                                       component={TextInput}
                                       inputClassName={styles.input}
                                       className={styles.wrapper}
                                       labelClassName={styles.label}
                                       onChange={handleChange}/>
                            </div>

                            <div className={styles.postal}>
                                {values.postal &&
                                    <Field
                                        label={"*Postal Address"}
                                        name={"address"}
                                        component={AddressInput}
                                        inputClassName={styles.wrapper}
                                        labelClassName={styles.label}
                                    />
                                }
                            </div>
                            <div className={styles.opt_in}>
                                <Field name={"optIn"}
                                       id={"optIn"}
                                       component={FormikCheckbox}/>
                                <span>Opt in to receive email updates from us</span>

                            </div>
                            <div className={styles.submit}>

                                {success ? <div className={styles.success}>{
                                        postal ? <p>
                                                Thanks! Your information pack is on its way.
                                            </p> :
                                            <p>
                                                Thanks for requesting our information pack. We've emailed it to you - if you
                                                can't see it in your inbox, please check your spam folder.
                                            </p>
                                    }

                                    </div> :
                                    <Button text={"Send"}
                                            formSubmit
                                            disabled={!isValid}
                                            darkgreen
                                            loading={loading}
                                            onPress={handleSubmit}/>
                                }
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}
