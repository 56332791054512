import React, { Component } from "react";
import styles from "./MediaArticleBlock.module.scss";
import MediaArticleCard from "./mediaArticleCard/MediaArticleCard";
import { imageUrlFor } from "../../../../util/util";


class MediaArticleBlock extends Component {

    render() {
        if (!this.props.mediaItems) {
            return null;
        }
        return (
            <div className={styles.media_article_block_container}>
                <div className={styles.media_article_inner_content}>
                    {this.props.mediaItems.map((mediaItem) => {
                        return (
                            <MediaArticleCard
                                image={imageUrlFor(mediaItem.thumbnail)}
                                href={mediaItem.href}
                                key={mediaItem._key}
                                meta={mediaItem.meta}
                                heading={mediaItem.heading}
                                content={mediaItem.content}
                                publisher={mediaItem.publisher}
                                subheading={mediaItem.subheading} />
                        );
                    })}

                </div>

            </div>
        );
    }
}

export default MediaArticleBlock;
