import instance from "./instance";

export default function get(url, params, captcha = "") {
    return instance()
        .get(url, {
            params: params,
            headers: {
                "f": captcha
            }
        })
        .then(data => data.data);
}
