export function contactFormAPI(data) {
    return window.post("/v1/contact", data);
}

export function createActiveCampaignContact(email) {
    return window.post("/v1/campaign/contact", email);
}

export function submitContact(values, type) {
    return window.post(`/v1/campaigns/landing/contact/${type}`, values);
}
