import React, { Component } from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet/es/Helmet";
import moment from "moment";
import _isEmpty from "lodash/isEmpty"
import SearchHeroBlock from "../../common/blocks/SearchHeroBlock/SearchHeroBlock";
import BlogItemsBlock from "../../common/blocks/blogItemsBlock/BlogItemsBlock";
import BlogCardItem from "../../common/widgets/blogCardItem/BlogCardItem";
import PreFooter from "../../common/blocks/preFooter/PreFooter";
import { pageReceived } from "../../../redux/actions/pages";
import styles from "./BlogHome.module.scss";
import FullPageLoader from "../../common/widgets/loaders/fullPageLoader/FullPageLoader";
import Routes from "../../../util/routes";
import { SeoPages } from "../../../util/seoConstants";
import { FirebaseContext } from "../../../context/firebase";

class BlogHome extends Component {
    static contextType = FirebaseContext;

    constructor(props) {
        super(props);
        const featuredBlogPostsQuery = "*[_type == \"blogHome\"]{\"blogPosts\": blogPost[]->}[0]";
        const allBlogsQuery = "*[_type == \"blogPost\"]";

        this.state = {
            loading: true,
            hideFeaturedBlogPost: false
        };

        window.sanity.fetch(featuredBlogPostsQuery).then((featuredBlogPosts) => {
            props.dispatch(pageReceived({ "featuredBlogPosts": featuredBlogPosts }));
            window.sanity.fetch(allBlogsQuery).then((allBlogs) => {
                props.dispatch(pageReceived({ "allBlogs": allBlogs }));
                this.setState({ loading: false });
            });
        });
    }

    renderBlogPosts = () => {
        const featuredBlogPostIds = this.props.featuredBlogPosts.blogPosts.map((blogPost) => blogPost._id);
        const blogPosts = [];
        this.props.allBlogs.map((blog) => {
            if (featuredBlogPostIds.indexOf(blog._id) === -1) {
                blogPosts.push(<BlogCardItem key={blog._id} blogPost={blog}/>);
            }
            return null;
        });

        blogPosts.sort((a, b) => {
            const aDate = !_isEmpty(((a || {}).props || {}).blogPost) ? moment(a.props.blogPost.date) : "";
            const bDate = !_isEmpty(((b || {}).props || {}).blogPost) ? moment(b.props.blogPost.date) : "";

            if (aDate > bDate) {
                return -1;
            }
            if (aDate < bDate) {
                return 1;
            }
            return 0;
        });

        return blogPosts;
    };

    onSearchSubmit = (values) => {
        const query = `*[_type == "blogPost" ${!!values.search ? ` && ([
                heading, 
                subheading, 
                meta, 
              ] match "${values.search}*" || 
               content[].children[].text match "${values.search}*" || 
               blurb[].children[].text match "${values.search}*")` : ""}
        ]`;

        const featuredBlogPostIds = this.props.featuredBlogPosts.blogPosts.map((blogPost) => blogPost._id);
        this.setState({ loading: true });

        window.sanity.fetch(query).then((blogs) => {
            let containsFeatured = false;
            const allBlogs = [];
            blogs.map((blog) => {
                if (featuredBlogPostIds.indexOf(blog._id) > -1) {
                    containsFeatured = true;
                }

                if (featuredBlogPostIds.indexOf(blog._id) === -1) {
                    allBlogs.push(blog);
                }
                return null;
            });

            this.props.dispatch(pageReceived({ "allBlogs": allBlogs }));
            this.setState({ loading: false, hideFeaturedBlogPost: !containsFeatured });
        });
    };

    onClearPress = () => {
        this.onSearchSubmit({});
    };

    onDonateNowPress = () => {
        window.dataLayer.push({"event": "clicked_donate"})
        this.props.navigate(Routes.donationTypeSelect);
    };

    onViewMorePress = () => {
        this.props.navigate(Routes.charities.root);
    };

    render() {
        if (this.state.loading) {
            return (
                <FullPageLoader/>
            );
        }

        // const buttons = [
        //     {
        //         buttonText: "Donate",
        //         onPress: this.onDonateNowPress,
        //         props: {}
        //     },
        //     {
        //         buttonText: "View Charities",
        //         onPress: this.onViewMorePress,
        //         props: { outline: true }
        //     }
        // ];

        return (
            <>
                <Helmet>
                    <meta property={"title"} content={SeoPages.blog.title}/>
                    <meta property={"description"} content={SeoPages.blog.description}/>
                    <meta property={"og:title"} content={SeoPages.blog.ogTitle}/>
                    <meta property={"og:description"} content={SeoPages.blog.ogDescription}/>
                    <meta property={"og:image"} content={SeoPages.blog.ogImage}/>
                    <meta property={"og:url"} content={`${process.env.REACT_APP_WEB_URL}/blog`}/>
                    <title>{SeoPages.blog.title}</title>
                </Helmet>
                <div className={styles.blog_home_container}>
                    <SearchHeroBlock
                        hideFilters
                        heading={"Blog"}
                        className={styles.search}
                        subheading={"You can find stories here about how your donations are changing the lives of Kiwi kids."}
                        onSubmit={this.onSearchSubmit}
                        onClearPress={this.onClearPress}/>
                    <div className={styles.main_inner}>

                        {this.state.loading ? <h3>Loading...</h3> : (
                            <div className={styles.blog_home_content}>
                                {this.state.hideFeaturedBlogPost ? null : this.props.featuredBlogPosts && (
                                    <BlogItemsBlock
                                        blogClassname={styles.featured_card}
                                        className={styles.blog_home_featured}
                                        blogPosts={this.props.featuredBlogPosts.blogPosts}
                                        hideButton
                                    />
                                )}

                                {this.props.allBlogs && (
                                    <div className={styles.blog_items_content}>
                                        {this.renderBlogPosts()}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <PreFooter/>

                </div>
            </>
        );
    }
}

export default connect((state) => {
        return {
            featuredBlogPosts: state.pages.featuredBlogPosts,
            allBlogs: state.pages.allBlogs
        };
    }
)(BlogHome);
