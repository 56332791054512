import React, { Component } from "react";
import { connect } from "react-redux";
import { pageReceived } from "../../../redux/actions/pages";
import FullPageLoader from "../../common/widgets/loaders/fullPageLoader/FullPageLoader";
import styles from "./PartnersPage.module.scss"
import { imageUrlFor, renderBlockText } from "../../../util/util";
import Button from "../../common/widgets/button/Button";
import withRouter from "../../../util/hooks/withRouter";
import arrowGreen from "../../../resources/images/svg/arrowRightGreen.svg"


const BlogCard = (props) => {
    const onPress = () => {
        let link = "";

        if ( !!props.blogPost.slug ) {
            link = props.blogPost.slug.current.includes("/") ? props.blogPost.slug.current : `/${props.blogPost.slug.current}`;
        }
        props.navigate(`/blog/content${link}`);
    };


    if ( !props.blogPost ) {
        return null;
    }

    const blogPost = props.blogPost;

    return (
        <div className={styles.blog_post} onClick={onPress}>
            <div className={styles.image_container}
                 style={{ backgroundImage: !!blogPost.imageLinkAlt && `url(${imageUrlFor(blogPost.imageLinkAlt.image).url()})` }}/>
            <div className={styles.card_content}>
                {!!blogPost.heading && <h4>{blogPost.heading}</h4>}
                <div> {!!blogPost.blurb && renderBlockText(blogPost.blurb)}</div>
                <a onClick={onPress}>
                    Read more <img src={arrowGreen}/>
                </a>
            </div>

        </div>
    );

}

const PartnerCard = (props) => {
    const { partner } = props;



    return (
        <div className={styles.card}>
            <div style={{ backgroundImage: `url(${imageUrlFor(partner.image)})` }} className={styles.image}/>
            <div className={styles.card_text}>
                <div>
                    <h4>
                        {partner.name}
                    </h4>
                    {renderBlockText(partner.description)}
                </div>
            </div>
        </div>
    )
}

class PartnersPage extends Component {

    constructor (props) {
        super(props);
        const partnersQuery = "*[_type == \"partners\"]{cards[]->,featuredCards[]->, subheading, description, pageName, blogSubheading, blogPost->}[0]";

        this.state = {
            loading: true,
        };

        window.sanity.fetch(partnersQuery).then((partners) => {
            props.dispatch(pageReceived({ "partners": partners }))
            this.setState({ loading: false });

        });

    }

    onViewBlogsClick = () => {
        this.props.navigate("/blog")
    }

    onJoinClick = () => {
        this.props.navigate("/donate")
    }

    onViewClick = (partner) => {
        window.open(partner.url);
    }

    render () {


        if ( this.state.loading ) {
            return (
                <FullPageLoader/>
            );
        }
        const {
            cards,
            featuredCards,
            blogSubheading,
            blogPost,
            description,
            pageName,
            subheading
        } = this.props.partners;


        return (
            <div className={styles.page}>

                <h1>
                    {pageName}
                </h1>
                {!!subheading &&
                    <div className={styles.subheading}>
                        <div className={styles.subheadingInner}>
                            <h2>
                                {subheading}
                            </h2>
                            <p>
                                {description}
                            </p>
                        </div>
                    </div>
                }
                <div className={styles.page_inner}>

                    <div className={styles.featured_cards}>
                        {(featuredCards || []).map((c, i) =>
                            <>
                                <PartnerCard key={`partner-${i + 1}`} partner={c}
                                             navigate={this.props.navigate}/>
                                <div className={styles.cardTextBlock}>
                                    {renderBlockText(c.longDescription)}
                                    {!!c.url &&
                                        <Button className={styles.partner_button} onPress={() => this.onViewClick(c)}>View
                                            partner</Button>
                                    }
                                </div>
                            </>)}
                    </div>
                    <div className={styles.cards}>
                        {(cards || []).map((c, i) =>
                            <>
                                <PartnerCard key={`partner-${i + 1}`} partner={c}
                                           navigate={this.props.navigate}/>
                                <div className={styles.cardTextBlock}>
                                    {renderBlockText(c.longDescription)}
                                    {!!c.url &&
                                        <Button className={styles.partner_button} onPress={() => this.onViewClick(c)}>View
                                            partner</Button>
                                    }
                                </div>
                            </>
                        )}
                    </div>

                </div>
                <div className={styles.pre_footer}>
                    <div className={styles.pre_footer_inner}>
                    <h3>
                        Join the community making a difference
                    </h3>
                    <p>
                        Together we can do something amazing. Join us as a partner to connect with our community of Kiwi
                        superannuants giving New Zealand children a fair chance to thrive and succeed.
                    </p>
                    <Button onPress={this.onJoinClick}>Join today</Button>
                    </div>
                </div>
                <div className={styles.blog_post_container}>
                    <h2>
                        {blogSubheading}
                    </h2>
                    <BlogCard blogPost={blogPost} navigate={this.props.navigate}/>
                    <Button onPress={this.onViewBlogsClick}>View all</Button>
                </div>

            </div>
        );
    }
}


export default withRouter(connect((state) => {
        return {
            partners: state.pages.partners,
        };
    }
)(PartnersPage))
