import React from "react";
import { Field } from "formik";
import { Link } from "react-router-dom";
import styles from "../Login.module.scss";
import Button from "../../../common/widgets/button/Button";
import TextInput from "../../../common/widgets/input/textInput/TextInput";


const LoginForm = (props) => {
    const { isValid, handleChange, handleSubmit } = props;
    const handleInputKeyPress = (e) => {
        if (e.key === "Enter" && isValid) {
            handleSubmit();
        }
    };

    return (
        <form>
            <Field name={"email"}
                   label={"Email"}
                   component={TextInput}
                   onKeyDown={handleInputKeyPress}
                   onChange={handleChange}/>
            <Field name={"password"}
                   label={"Password"}
                   component={TextInput}
                   password
                   onKeyDown={handleInputKeyPress}
                   onChange={handleChange}/>
            <div className={styles.login_button_container}>
                <Button text={"Login"}
                        formSubmit
                        turquoise
                        loading={props.loading}
                        onPress={handleSubmit}
                        disabled={!isValid}/>
                <Link to={"/forgot-password"}>Forgot password</Link>
            </div>
        </form>
    );
};

export default LoginForm;
