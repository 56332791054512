import wrapper from "./helpers/wrapper";
import createAction from "./helpers/createAction";
import * as CharitiesAPI from "./api/charities";

export const SET_CHARITIES = "SET_CHARITIES";
export const SET_NOMINATED_CHARITIES = "SET_NOMINATED_CHARITIES";

const setCharities = createAction(SET_CHARITIES);
const setNominatedCharities = createAction(SET_NOMINATED_CHARITIES);

export const getCharities = wrapper(CharitiesAPI.getCharitiesAPI, (dispatch, charities) => {
    dispatch(setCharities(charities));
});

export const getNominatedCharities = wrapper(CharitiesAPI.getNominatedCharitiesAPI, (dispatch, charities) => {
    dispatch(setNominatedCharities(charities));
});

export const getIp = wrapper(CharitiesAPI.getIp);