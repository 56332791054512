import values from "lodash/values";
import * as Types from "../actions/cards";

const initialState = {};

export default function cards(state = initialState, action = { type: null, payload: null }) {
    switch (action.type) {

        case Types.SET_CARDS: {
            const newState = {};
            values(action.payload).forEach((card) => {
                newState[card.id] = card;
            });

            return newState;
        }

        case Types.SET_CARD: {
            const newState = { ...state };
            const card = action.payload;

            if (!card) {
                return state;
            }

            newState[card.id] = card;

            return newState;
        }

        default: {
            return state;
        }
    }
}
