import React, { Component } from "react";
import PropTypes from "prop-types";
import * as classnames from "classnames";
import styles from "./OldCharityCardItem.module.scss";
import Button from "../button/Button";
import withRouter from "../../../../util/hooks/withRouter";

class OldCharityCardItem extends Component {
    static propTypes = {
        heading: PropTypes.string,
        image: PropTypes.any,
        className: PropTypes.string,
        id: PropTypes.string,
        hero: PropTypes.bool,
    };

    static defaultProps = {
        heading: "",
        image: null,
        className: null,
        id: null,
        hero: false,
    };

    onButtonPress = () => {
        this.props.navigate(`/charity-selection#${this.props.charity.id}`);
    };


    renderHeroCard = () => {
        return (
            <div onClick={ this.onButtonPress }
                className={ classnames(styles.charity_card_container_hero, this.props.className) }
                id={ this.props.id }>
                <div className={ styles.charity_card_image_container_hero }
                    style={ { backgroundImage: `url(${this.props.image})` } } />
                <div className={ styles.charity_card_inner_hero }>
                    <div>
                        { !!this.props.heading && <h4>{ this.props.heading }</h4> }
                        { !!this.props.content && <p>{ this.props.content }</p> }
                    </div>
                    <Button onPress={ this.onButtonPress } darkgreen>View Charity</Button>
                </div>
            </div>
        );
    };

    renderCard = () => {
        return (
            <div onClick={ this.onButtonPress }
                className={ classnames(styles.charity_card_container, this.props.className) }
                id={ this.props.id }>
                <div className={ styles.charity_card_image_container }
                    style={ { backgroundImage: `url(${this.props.image})` } } />
                <div className={ styles.charity_card_inner }>
                    { !!this.props.heading && <h4>{ this.props.heading }</h4> }
                    {/* {!!this.props.content && <p>{this.props.content}</p>} */ }
                    <Button onPress={ this.onButtonPress } darkgreen>View Charity</Button>
                </div>
            </div>
        );
    };

    render() {
        if (this.props.hero) {
            return this.renderHeroCard();
        }

        return this.renderCard();
    }
}

export default withRouter(OldCharityCardItem);
