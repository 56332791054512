import React, { useEffect, useState } from "react";

import styles from "./AnnualReport.module.scss";

const AnnualReport = ({ url }) => {
    const [ windowSize, setwindowSize ] = useState(null);
    useEffect(() => {
        setwindowSize(window.innerWidth);
        console.log(windowSize);
    });
    if (windowSize <= 425) {
        return (
            <div className={ styles.wrapper }>
                <div className={ styles.iframeContainerMobile }>
                    <iframe className={ styles.mobileIframe } title="annual report" frameBorder="0" width="320" height="320" src={ url } scrolling="no" marginWidth="0" marginHeight="0" allowFullScreen />
                </div>
            </div>
        );
    }
    return (
        <div className={ styles.wrapper }>
            { windowSize >= 768 ? (
                <div className={ styles.iframeContainer }>
                    <iframe className={ styles.desktopIframe } title="annual report" frameBorder="0" width="1024" height="800" src={ url } scrolling="no" marginWidth="0" marginHeight="0" allowFullScreen />
                </div>
            ) : (
                <div className={ styles.iframeContainerTablet }>
                    <iframe className={ styles.tabletIframe } title="annual report" frameBorder="0" width="425" height="400" src={ url } scrolling="no" marginWidth="0" marginHeight="0" allowFullScreen />
                </div>
            ) }
        </div>
    );

};

export default AnnualReport;
