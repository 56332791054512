import * as Yup from "yup";

import {Field, Form, Formik} from "formik";
import React, {useRef, useState} from "react";

import Button from "../../common/widgets/button/Button";
import FormikCheckbox from "../../common/widgets/formikCheckbox/FormikCheckbox";
import TextInput from "../../common/widgets/input/textInput/TextInput";
import styles from "./LandingPage.module.scss";
import {submitContact} from "../../../redux/actions/api/contact";

const BookingFormScema = Yup.object().shape({
    firstName: Yup.string()
        .required("Required"),
    lastName: Yup.string()
        .required("Required"),
    email: Yup.string()
        .email("Invalid email")
        .required("Required"),
});

export const BookingForm = (props) => {
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const formRef = useRef();

    const [checkBox, setCheckBox] = useState(false)
    const initValues = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        optIn: true
    }
    const onSubmit = async (values) => {
        setLoading(true)
        window.dataLayer.push({"event": "Book a call"})

        try {
            await submitContact(values, "BookCallwLiz");
            setSuccess(true)
            formRef.current.handleReset();
        } catch (e) {
            console.log(e)
        }

        setLoading(false)


    }
    return (
        <div className={styles.booking_form}>
            <Formik onSubmit={onSubmit}
                    enableReinitialize
                    innerRef={formRef}

                    validationSchema={BookingFormScema}
                    initialValues={initValues}
            >
                {({handleSubmit, handleChange, isValid}) => {
                    return (
                        <Form>
                            <div>
                                <Field name={"firstName"}
                                       label={"*First Name"}
                                       component={TextInput}
                                       inputClassName={styles.input}
                                       className={styles.wrapper}
                                       labelClassName={styles.label}
                                       onChange={handleChange}/>
                                <Field name={"lastName"}
                                       label={"*Last Name"}
                                       inputClassName={styles.input}
                                       className={styles.wrapper}
                                       labelClassName={styles.label}
                                       component={TextInput}
                                       onChange={handleChange}/>
                            </div>
                            <div>
                                <Field name={"email"}
                                       label={"*Email"}
                                       component={TextInput}
                                       inputClassName={styles.input}
                                       className={styles.wrapper}
                                       labelClassName={styles.label}
                                       onChange={handleChange}/>
                                <Field name={"phone"}
                                       label={"Contact number"}
                                       component={TextInput}
                                       inputClassName={styles.input}
                                       className={styles.wrapper}
                                       labelClassName={styles.label}
                                       onChange={handleChange}/>
                            </div>
                            <div className={styles.opt_in}>
                                <Field name={"optIn"}
                                       id={"optIn"}
                                       component={FormikCheckbox}/>
                                <span>Opt in to receive email updates from us</span>

                            </div>
                            <div className={styles.submit}>

                                {success ? <div className={styles.success}>
                                        <p>
                                            Thanks for requesting our booking a call. We've emailed you - if you can't see
                                            it in your inbox, please check your spam folder.
                                        </p>
                                    </div> :
                                    <Button text={"Book a call"}
                                            formSubmit
                                            disabled={!isValid}
                                            darkgreen
                                            loading={loading}
                                            onPress={handleSubmit}/>
                                }
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}
