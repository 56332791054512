import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import styles from "./CharitySidebar.module.scss";

function CharitySidebar(props) {
    return (
        <div className={styles.sidebar_container}>
            {props.charities.map((charity) => (
                <a className={classNames(styles.charity_choice, { [styles.charity_choice_active]: charity.id === props.selectedCharity })}
                   key={`sidebar.${charity._id}`}
                   href={`#${charity.id}`}>
                    {charity.name}
                </a>
            ))}
        </div>
    );
}

export default connect((state) => {
    return {
        selectedCharity: state.charitySelectionNavigation.selectedCharity
    };
})(CharitySidebar);
