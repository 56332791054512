import React, { useEffect } from "react";
import { connect } from "react-redux";
import ComponentLoader from "../componentLoader/ComponentLoader";
import Logo from "../../../../../resources/images/svg/loaderIcon.svg";
import styles from "./FullPageLoader.module.scss";
import { setPageLoading } from "../../../../../redux/actions/pages";


function FullPageLoader(props) {

    useEffect(() => {
        props.dispatch(setPageLoading(true));
        return () => {
            props.dispatch(setPageLoading(false));
        };
    });

    return (
        <div className={styles.full_page_loader_container}>
            <div className={styles.full_page_loader_inner}>
                <img src={Logo} alt={"We make it easy for Superannuants to donate to NZ Charities"}/>
                <ComponentLoader width={50} height={20}/>
            </div>
        </div>
    );
}

export default connect()(FullPageLoader);
