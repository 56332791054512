import React from "react";
import { imageUrlFor, renderBlockText } from "../../../../util/util";
import styles from "./OurStoryBlock.module.scss"
import {VideoCard} from "../../widgets/videoCard/VideoCard";

export const OurStoryBlock = (props) => {
    const { ourStory } = props;
    const imageUrl = imageUrlFor(ourStory.bio.image)
    return (

        <div className={styles.block}>
            <div className={styles.heading}>
                <h4>
                    Our story
                </h4>
                <h1>
                    {renderBlockText(ourStory.heading)}
                </h1>
            </div>
            <div className={styles.contents}>
                <div className={styles.text}>
                        {renderBlockText(ourStory.bio.bio)}
                </div>
                <div className={styles.testimonial_image}>
                    <VideoCard thumbnail={imageUrl}
                               video={ourStory.videoUrl}/>
                    <p>{ourStory.bio.name}</p>

                </div>
            </div>
        </div>
    )
}
