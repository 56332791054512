import * as Types from "../actions/settings";

const initialState = {
    authLoading: true,
};

export default function settings(state = initialState, action = { type: null, payload: null }) {
    switch (action.type) {

        case Types.SET_AUTH_LOADING: {
            return {
                ...state,
                authLoading: action.payload,
            };
        }

        default: {
            return state;
        }
    }
}
