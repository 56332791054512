import instance from "./instance";

export default function put(url, data, params, captcha = "") {
    return instance()
        .put(url, data, {
            params: params,
            headers: {
                "f": captcha
            }
        })
        .then(data => data.data);
}
