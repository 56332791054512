import React, { Component } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import configureStore from "./redux/store/configureStore";
import SnackbarContainer from "./components/common/widgets/snackbar/SnackbarContainer";
import Main from "./Main";
import { FirebaseProvider } from "./context/firebase";

export const { store } = configureStore();

export default class App extends Component {

    render() {

        return (
            <Provider store={store}>
                <FirebaseProvider>
                    <SnackbarContainer>
                        <Router>
                            <Routes>
                                <Route path={"*"} element={<Main />} />
                            </Routes>
                        </Router>
                    </SnackbarContainer>
                </FirebaseProvider>
            </Provider>
        );
    }
}
