import { Navigate, Routes as ReactRouterRoutes, Route } from "react-router-dom";
import React, { Suspense } from "react";
import { getCharities, getNominatedCharities } from "./redux/actions/charities";

import AboutUs from "./components/pages/aboutUs/AboutUs";
import AnnualReport from "./components/pages/annual-report/AnnualReport";
import AuthRoute from "./components/authRoute/AuthRoute";
import BlogHome from "./components/pages/blogHome/BlogHome";
import BlogPost from "./components/pages/blogPost/BlogPost";
import CharitiesHome from "./components/pages/charitiesHome/CharitiesHome";
import CharitySelection from "./components/pages/charitySelection/CharitySelection";
import CharitySelectionMobile from "./components/pages/charitiesView/CharitiesView";
import ContactUs from "./components/pages/contactUs/ContactUs";
import DonationTypeSelect from "./components/pages/donationSetup/DonationTypeSelect";
import EofyReceipt from "./components/pages/receipts/EofyReceipt";
import Facts from "./components/pages/facts/Facts";
import Footer from "./components/common/blocks/footer/Footer";
import ForgotPassword from "./components/pages/forgotPassword/ForgotPassword";
import FullPageLoader from "./components/common/widgets/loaders/fullPageLoader/FullPageLoader";
import Home from "./components/pages/home/Home";
import HowDoesItWork from "./components/pages/howDoesItWork/HowDoesItWork";
import HowWeMadeOurCommercial from "./components/pages/howWeMadeOurCommercial/HowWeMadeOurCommercial";
import LandingPage from "./components/pages/landingPage/LandingPage";
import Login from "./components/pages/login/Login";
import NavBar from "./components/common/widgets/navBar/NavBar";
import NotFound from "./components/pages/notFound/NotFound";
import PartnersPage from "./components/pages/partners/PartnersPage";
import Press from "./components/pages/press/Press";
import PrivacyPolicy from "./components/pages/privacyPolicy/PrivacyPolicy";
import PropTypes from "prop-types";
import Routes from "./util/routes";
import TaxRebate from "./components/pages/taxRebate/TaxRebate";
import TermsAndConditions from "./components/pages/termsAndConditions/TermsAndConditions";
import TransactionalReceipt from "./components/pages/receipts/TransactionalReceipt";
import { connect } from "react-redux";
import withRouter from "./util/hooks/withRouter";
import { setBannerLoading, bannerReceived } from "./redux/actions/banner";

const DonorDashboard = React.lazy(() => import("./components/pages/donors/donorDashboard/DonorDashboard"));
const MyDonations = React.lazy(() => import("./components/pages/donors/myDonations/MyDonations"));
const MyProfile = React.lazy(() => import("./components/pages/donors/myProfile/MyProfile"));
const TransactionHistory = React.lazy(() => import("./components/pages/donors/reports/transactionHistory/TransactionHistory"));
const DonationSetup = React.lazy(() => import("./components/pages/donationSetup/DonationSetup"));

const bannerQuery = `
*[_type == "campaignBanner"]{...}[0]`;

class Main extends React.Component {

    static propTypes = {
        user: PropTypes.object
    };

    static defaultProps = {
        user: null
    };

    componentDidMount() {
        this.props.dispatch(setBannerLoading(true));
        window.sanity.fetch(bannerQuery).then((b) => {
            this.props.dispatch(bannerReceived(b));
            this.props.dispatch(setBannerLoading(false));
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            window.scrollTo(0, 0);
        }

        if (prevProps.loading !== this.props.loading && prevProps.authLoading !== this.props.authLoading && !this.props.loading && !this.props.authLoading) {
            this.props.dispatch(getNominatedCharities());
            this.props.dispatch(getCharities());
        }
    }

    renderUserRoute = (Component) => {
        return (props) => {
            if (!this.props.user && !this.props.authLoading) {
                return (
                    <Navigate to={ Routes.login } />
                );
            }

            if (this.props.authLoading) {
                return (
                    <FullPageLoader />
                );
            }

            return (
                <Component { ...props } />
            );
        };
    };

    render() {
        const loadingPages = this.props.loading;
        const isReceiptRoute = window.location.pathname === Routes.eofyReceipt || window.location.pathname === Routes.donationReceipt;
        const isLandingPage = window.location.pathname.includes("/joinme")
        return (
            <>
                {/*{!isReceiptRoute && <PopUp/>}*/ }
                { !isReceiptRoute && !isLandingPage && <NavBar user={ this.props.user } banner={ this.props.banner } /> }
                <Suspense fallback={ <FullPageLoader /> }>
                    <ReactRouterRoutes>
                        <Route exact path={ Routes.root } element={ <Home /> } />
                        <Route exact path={ Routes.aboutUs } element={ <AboutUs /> } />
                        <Route exact path={ Routes.blog.root } element={ <BlogHome /> } />
                        <Route path={ Routes.blog.blogPost } element={ <BlogPost /> } />
                        <Route exact path={ Routes.charities.root } element={ <CharitiesHome /> } />
                        <Route path={ Routes.charities.charitySelection } element={ <CharitySelection /> } />
                        <Route path={ Routes.charities.charitySelectionMobile } element={ <CharitySelectionMobile /> } />
                        <Route exact path={ Routes.contactUs } element={ <ContactUs /> } />
                        {/*<Route exact path={Routes.faq} element={<FaqHome />} />*/ }
                        <Route exact path={ Routes.partners } element={ <PartnersPage /> } />
                        <Route exact path={ Routes.joinme } element={ <LandingPage /> } />

                        <Route exact path={ Routes.press } element={ <Press /> } />
                        <Route exact path={ Routes.eofyReceipt } element={ <EofyReceipt /> } />
                        <Route exact path={ Routes.donationReceipt } element={ <TransactionalReceipt /> } />
                        <Route exact path={ Routes.rebate } element={ <TaxRebate /> } />
                        <Route exact path={ Routes.facts } element={ <Facts /> } />
                        <Route exact path={ Routes.howWeMadeOurCommercial } element={ <HowWeMadeOurCommercial /> } />
                        <Route exact path={ Routes.howDoesItWork } element={ <HowDoesItWork /> } />
                        <Route exact path={ Routes.privacyPolicy } element={ <PrivacyPolicy /> } />
                        <Route exact path={ Routes.termsAndConditions } element={ <TermsAndConditions /> } />
                        <Route exact path={ Routes.login } element={ <Login /> } />
                        <Route exact path={ Routes.forgotPassword } element={ <ForgotPassword /> } />
                        <Route exact path={ Routes.annualReport } element={ <AnnualReport url={ "https://indd.adobe.com/view/bf403487-ee2e-467a-9943-007b308024ac" } /> } />
                        <Route exact path={ Routes.annualReportOld } element={ <AnnualReport url={ "https://indd.adobe.com/view/8e64f60e-173e-423d-aa5a-50c11104193e" } /> } />
                        <Route exact path={ Routes.annualReport23 } element={ <AnnualReport url={ "https://indd.adobe.com/view/3c04e057-9885-4a65-a682-95e4efa113ae" } /> } />

                        <Route exact path={ Routes.donationTypeSelect } element={ <DonationTypeSelect /> } />
                        <Route path={ Routes.donationSetup } element={ <DonationSetup /> } />

                        {/* ------USER ROUTES------ */ }
                        <Route exact path={ Routes.user.donorDashboard }
                            element={ (
                                <AuthRoute>
                                    <DonorDashboard />
                                </AuthRoute>) } />
                        <Route exact path={ Routes.user.myDonations } element={ (
                            <AuthRoute>
                                <MyDonations />
                            </AuthRoute>
                        ) } />
                        <Route exact path={ Routes.user.myProfile } element={ (
                            <AuthRoute>
                                <MyProfile />
                            </AuthRoute>
                        ) } />
                        <Route exact
                            path={ Routes.user.transactionHistory }
                            element={ (
                                <AuthRoute>
                                    <TransactionHistory />
                                </AuthRoute>
                            ) } />
                        {/* ------USER ROUTES------ */ }

                        <Route path={ "*" } element={ <NotFound /> } />
                    </ReactRouterRoutes>
                </Suspense>
                { !loadingPages && (window.location.pathname !== Routes.user.donationSetup && !isReceiptRoute && !isLandingPage) ?
                    <Footer navigate={ this.props.navigate } /> : null }
            </>
        );
    }

}

export default connect((state) => {
    return {
        loading: state.pages.loading,
        authLoading: state.settings.authLoading,
        user: state.user,
        banner: state.banner.banner
    };
})(withRouter(Main));
