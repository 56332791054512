import React from "react";
import styles from "./TaxRebate.module.scss";
import moment from "moment";

function TaxRebate() {
    return (
        <div className={styles.tax_rebate_page}>
            <div className={styles.main_inner}>
                <div className={styles.rebate_intro}>
                    <h1>Donate your {moment().year() - 1}-{moment().format("YY")} tax rebate to Share My Super</h1>
                    <h3>Thank you so much for your kind donations in {moment().year() - 1}-{moment().format("YY")}</h3>
                    <p>Did you know you can allocate all or part of your credit at the end of the tax credit
                        process? This is a great way to make your donation go further.

                        <br /><br />For example, if you have donated $100, you are eligible to claim $33.33. Donating
                        this refund back makes your gift a total of $133.33, which will be used to support our work to
                        break the cycle of child poverty in New Zealand
                        <br /><br />You can also transfer your credit at the end of the process. If you would like to
                        donate your credit back to Share My Super - thank you. You are able to do this at the end of
                        the online process, or you could get in touch with us when you receive your tax rebate.
                        <br /><br />More information is available on the Inland Revenue website – <a
                            href={"https://ird.govt.nz/donations"} rel={"noreferrer noopener"}
                            target={"_blank"}>ird.govt.nz/donations</a> or feel free to get in touch us with any
                        questions –
                        <a href={"mailto:contact@sharemysuper.org.nz"}>contact@sharemysuper.org.nz</a>

                    </p>
                </div>
                {/*<div className={styles.border_bottom}/>*/}

                {/*<div className={styles.rebate_content}>*/}
                {/*    <h4>What is my tax rebate?</h4>*/}
                {/*    <p>*/}
                {/*        It’s the money you can claim back from the government for donations you’ve given over a tax*/}
                {/*        year. You get 1/3 of your donations back for each donation over $5.*/}
                {/*    </p>*/}
                {/*    <div className={styles.border_bottom}/>*/}

                {/*    <h4>How do I donate my tax rebate to Share My Super?</h4>*/}
                {/*    <p><strong>If filling by paper:</strong>*/}
                {/*        <ol>*/}
                {/*            <li>Download and print <a href={Form}>this IR256 form</a> (You’ll see it has Share My Super’s name and bank account number pre-printed on it).</li>*/}
                {/*            <li>Complete the form with your details</li>*/}
                {/*            <li>Attach your tax certificates and receipts and return it to: Inland Revenue*/}
                {/*                Department PO Box 39090 Wellington Mail Centre Lower Hutt 5045*/}
                {/*            </li>*/}
                {/*            <li>Please email <a*/}
                {/*                href={"mailto:queries@spendmysuper.org.nz"}>queries@spendmysuper.org.nz</a> to*/}
                {/*                advise us of your donation. Please <strong>include your*/}
                {/*                    name and IRD number</strong> so that we can identify you and thank you for your*/}
                {/*                gift.*/}
                {/*            </li>*/}
                {/*        </ol>*/}
                {/*    </p>*/}
                {/*    <p><strong>If filling by paper:</strong><br/>*/}
                {/*        Visit <a href={"https://www.ird.govt.nz/donations"}>ird.govt.nz/donations</a> for more*/}
                {/*        information.*/}
                {/*    </p>*/}
                {/*    <div className={styles.border_bottom}/>*/}

                {/*    <h4>Already received your tax rebate?</h4>*/}
                {/*    <p>Donate online at <a href={"https://spendmysuper.org.nz/"}>spendmysuper.org.nz</a></p>*/}


                {/*</div>*/}

            </div>

        </div>
    );
}

export default TaxRebate;
