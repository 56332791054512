import React from "react";
import { connect } from "react-redux";
import styles from "./DonationTypeSelect.module.scss";
import Button from "../../common/widgets/button/Button";
import Routes from "../../../util/routes";
import withRouter from "../../../util/hooks/withRouter";
import { useFirebase } from "../../../context/firebase";

function DonationTypeSelect (props) {

    const handleRecurringPress = () => {
        window.dataLayer.push({"event" : "selected_recurring_donation" });

        let pathname = Routes.user.donationSetup;

        if ( window.location.search ) {
            pathname = `${pathname}${window.location.search}`;
        }

        if ( (props.subscription || {}).status === "ACTIVE" ) {
            props.navigate(pathname, {
                state: { editing: true }
            });
        } else if ( window.location.search ) {
            props.navigate(pathname);
        } else {
            props.navigate(pathname);
        }
    };

    const handleOneOffPress = () => {
        window.dataLayer.push({"event" : "selected_one_off_donation"});

        let pathname = Routes.user.donationSetup;

        if ( window.location.search ) {
            pathname = `${pathname}/${window.location.search}`;
        }

        props.navigate(pathname, {
            state: { oneOff: true }
        });
    };

    const handleBankTransferPress = () => {
        window.open("https://join.sharemysuper.org.nz/donation-form-automatic-payment", "_self")
    }

    return (
        <div className={styles.content}>
            <div className={styles.main_inner}>
                <h2>What type of donation would you like to make today?</h2>
                <div className={styles.card_wrapper}>
                    <div className={styles.card}>
                        <div>
                            <h3>{(props.subscription || {}).status === "ACTIVE" ? "Edit My Recurring Donation" : "Recurring Donation"}</h3>
                            <h4>What does this mean?</h4>
                            <p>A recurring donation is a donation that once set up, will be made in your
                                name periodically.
                                The amount, charities and the frequency can all be managed in your
                                account settings.
                            </p>
                        </div>
                        <div>
                            <p>Yes I'd like to make a recurring donation by:
                            </p>
                            {
                                (props.subscription || {}).status === "ACTIVE" ? (
                                    <Button
                                        onPress={handleRecurringPress}>Edit My Recurring Donation</Button>
                                ) : (
                                    <>
                                        <Button
                                            onPress={handleRecurringPress}>Credit card</Button>
                                        <p>
                                            Or
                                        </p>
                                        <Button
                                            onPress={handleBankTransferPress}>Bank Transfer</Button>
                                    </>
                                )
                            }
                        </div>
                    </div>
                    <div className={styles.card}>
                        <div>
                            <h3>One-off Donation</h3>
                            <h4>What does this mean?</h4>
                            <p>A one-off donation is a donation that is only made once. You can choose
                                the amount,
                                charities, and the donation date.
                            </p>
                        </div>
                        <div>
                            <p>Yes I'd like to make a one-off donation by:
                            </p>
                            <Button onPress={handleOneOffPress} outline>Credit card</Button>
                            <p>
                                Or
                            </p>
                            <Button onPress={handleBankTransferPress} outline>Bank Transfer</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(connect((state) => {
    return {
        user: state.user,
        subscription: state.subscription
    };
})(DonationTypeSelect));
