import values from "lodash/values";

function callFunc(func, ...params) {
    if (!!func && typeof func === "function") {
        func(...params);
    }
}

export default function wrapper(promise, onDispatchSuccess, onDispatchFailure) {
    return ({ onSuccess, onError, onSuccessOrError, ...params } = {}) => {
        return async (dispatch, getState) => {
            try {
                const data = await promise(...values(params));

                if (process.env.NODE_ENV === "development") {
                    console.log("API SUCCESS: ", data);
                }

                callFunc(
                    onDispatchSuccess,
                    dispatch,
                    data,
                    ...Object.values(params),
                    getState
                );
                callFunc(onSuccess, data, ...Object.values(params));
                callFunc(onSuccessOrError, data, ...Object.values(params));
            } catch (err) {
                console.log("API FAILURE: ", err);
                if (!!err.response && !!err.response.data) {
                    const error = err.response.data;

                    if (process.env.NODE_ENV === "development") {
                        console.log("API FAILURE: ", error);
                    }

                    callFunc(
                        onDispatchFailure,
                        dispatch,
                        error,
                        ...Object.values(params),
                        getState
                    );
                    callFunc(onError, error, ...Object.values(params));
                    callFunc(onSuccessOrError, error, ...Object.values(params));
                }
            }
        };
    };
}
