import classnames from "classnames";
import omit from "lodash/omit";
import PropTypes from "prop-types";
import React from "react";
import styles from "./TextInput.module.scss";

const TextInput = React.forwardRef((props, ref) => {
    let errorClass = {};
    if (props.form) {
        errorClass = { [styles.error]: props.form.errors[props.field.name] && props.form.touched[props.field.name] };
    }
    const className = classnames(styles.input,
        { [styles.disabled]: props.disabled },
        errorClass,
        props.className);
    const labelClassName = classnames(styles.label, props.labelClassName);

    const newProps = omit(props, [...Object.keys(TextInput.defaultProps), "children", "field"]);


    if (!!props.password) {
        newProps.type = "password";
    }

    const onTextChange = (e) => {
        let text = "";
        if (!!e.target) {
            text = e.target.value;
        }

        if (!!props.number) {
            text = text.replace(/\D+/g, "");
        }

        if (!!props.field) {
            props.form.setFieldValue(props.field.name, text);
        }

        if (!!props.onTextChange) {
            props.onTextChange(text);
        }
    };

    const formatText = (text) => {
        if (!!props.field) {
            text = props.field.value;
        }

        return text;
    };

    const text = props.text !== null ? formatText(props.text) : formatText(props.defaultValue);
    const Component = props.inputComponent ? props.inputComponent : "input";

    return (
        <div className={styles.input_wrapper}>
            {props.label && <span className={labelClassName}>{props.label}</span>}
            <div className={className}>
                <Component {...newProps}
                           {...(props.field || {})}
                           ref={ref}
                           className={props.inputClassName}
                           onChange={onTextChange}
                           defaultValue={props.defaultValue}
                           value={text}/>
                {props.children}
            </div>
            {props.form && props.form.errors[props.field.name] && props.form.touched[props.field.name] &&
            <div className={styles.error_text}>{props.form.errors[props.field.name]}</div>}
        </div>
    );
});

TextInput.propTypes = {
    className: PropTypes.string,
    labelClassName: PropTypes.string,
    inputClassName: PropTypes.string,
    label: PropTypes.string,
    inputComponent: PropTypes.string,
    disabled: PropTypes.bool,
    number: PropTypes.bool,
    password: PropTypes.bool,
    error: PropTypes.string,
    text: PropTypes.any,
    onTextChange: PropTypes.func
};

TextInput.defaultProps = {
    className: null,
    labelClassName: null,
    inputClassName: null,
    label: null,
    inputComponent: null,
    disabled: false,
    number: false,
    password: false,
    error: null,
    text: null,
    onTextChange: () => null
};

export default TextInput;
