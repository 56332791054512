import React from "react";
import AliceCarousel from "react-alice-carousel";
import styles from "./TestimonialsBlock.module.scss";
import { imageUrlFor, renderBlockText } from "../../../../util/util";
import ArrowLeft from "../../../../resources/images/svg/arrowLeft.svg";
import ArrowRight from "../../../../resources/images/svg/arrowRight2.svg";
import "react-alice-carousel/lib/alice-carousel.css";
import { VideoCard } from "../../widgets/videoCard/VideoCard";
import playButton from "../../../../resources/images/svg/play.svg"
import Button from "../../widgets/button/Button";
import arrow from "../../../../resources/images/png/arrow-green.png"
export default function TestimonialsBlock (props) {

    const testimonialBlock = props.testimonialBlock;

    const responsive = {
        899: { items: 1 },
        699: { items: 1 },
    };

    if ( !props.testimonialBlock ) {
        return null;
    }
    return (
        <div className={styles.testimonial_container}>
            <div className={styles.header}>
                <h5>
                    {props.testimonialBlock.header}

                </h5>
                <h2>
                    {renderBlockText(props.testimonialBlock.subheading)}
                </h2>
                <img src={arrow}/>
            </div>
            <AliceCarousel
                items={!!testimonialBlock.testimonials && testimonialBlock.testimonials.map((testimonial) => {
                    return (
                        <div key={testimonial._key} className={styles.testimonial}>
                            <div className={styles.testimonial_image}>
                                <VideoCard thumbnail={imageUrlFor(testimonial.videoThumbnail)}
                                           video={testimonial.videoUrl}/>
                            </div>
                            <div className={styles.testimonial_content}>
                                <div>
                                    <h4>

                                        {renderBlockText(testimonial.content)}
                                    </h4>

                                    <div className={styles.testimonial_name}>
                                        - {testimonial.author}
                                    </div>
                                    <div className={styles.watch}>
                                        <img src={playButton} className={styles.play_button}/>
                                        <p>
                                            Watch the video
                                        </p>
                                    </div>
                                </div>


                                <Button onPress={() => props.navigate("/donate")} text={"Become a donor"} darkgreen/>

                            </div>

                        </div>
                    );
                })}
                responsive={responsive}
                autoPlayInterval={5000}
                fadeOutAnimation
                mouseTrackingEnabled
                stopAutoPlayOnHover={false}
                disableAutoPlayOnAction
                infinite
                renderPrevButton={() => (
                    <div className={styles.arrow_left}>
                        <img src={ArrowLeft}
                             alt={"Prev"}/>
                    </div>
                )}
                renderNextButton={() => (
                    <div className={styles.arrow_right}>
                        <img src={ArrowRight}
                             alt={"Next"}/>
                    </div>
                )}
            />
        </div>
    );
}
