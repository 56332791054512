import axios from "axios";
import qs from "qs";
import { getAuth, getIdToken } from "firebase/auth";
import { getApp } from "firebase/app";

let axiosInstance = null;

export default function instance() {
    if (!axiosInstance) {
        axiosInstance = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            paramsSerializer: params => {
                return qs.stringify(params);
            }
        });

        axiosInstance.interceptors.request.use(
            async config => {
                const app = getApp();
                if (app) {
                    const auth = getAuth(app);
                    const token = auth && auth.currentUser ? await getIdToken(auth.currentUser) : null;

                    if (token !== null) {
                        config.headers.Authorization = token;
                    }
                }

                return config;
            },
            err => {
                return Promise.reject(err);
            }
        );
    }

    return axiosInstance;
}
